import { Component, Input, OnInit } from '@angular/core';
import { AssetDocument } from 'src/app/shared/data/data.generated';
import { ApiService } from 'src/app/shared/services/api.service';
import { ImagesService } from 'src/app/shared/services/images.service';

@Component({
    selector: 'asset-documentation',
    templateUrl: './asset-documentation.component.html',
    styleUrls: ['./asset-documentation.component.scss']
})
export class AssetDocumentationComponent implements OnInit {

    constructor(private images: ImagesService, private api: ApiService) { }

    @Input() public assetId: number = -1;
    @Input() public items: AssetDocument[] = [];
    @Input() public isDrawing: boolean = false;
    @Input() public isOmni: boolean = false;

    public pdfImage: string = this.images.Icons.FormatPdf;
    public labelValueColumns: string[] = ['label', 'download'];

    ngOnInit(): void {
    }

    downloadAssetDrawingsAndDoc(record: AssetDocument) {
        let filePath = '';
        // Different filepaths for OMNI urls.
        if (this.isOmni) {
            // checking if it is a standard document.
            if (record.Id === 0) {
                filePath = 'Standard Omni Docs for all 20002/' + record.FileName;
            } else if (this.isDrawing) {
                filePath = record.AssetId + '/Dwgs/' + record.FileName;
            } else {
                filePath = record.AssetId + '/Docs/' + record.FileName;
            }
        } else {
            filePath = this.isDrawing
                ? record.AssetId + '/AutoCAD Dwgs/' + record.FileName
                : record.AssetId + '/' + record.FileName;
        }
        this.api.Assets.DownloadAssetDrawingsAndDocs(record, filePath);
    }

    getFileType(fileName: string) {
        return fileName.split('.')[1]
    }

}

