<div class="title-div">
  <div class="orders-title">
    <span class="heading-left">Order Status Report</span>
  </div>
</div>
<br />

<dx-popup
  [width]="1000"
  [height]="600"
  [showTitle]="true"
  [dragEnabled]="false"
  title="Orders Status Notes"
  [hideOnOutsideClick]="true"
  [(visible)]="showNotes"
>
  <div *dxTemplate="let data of 'title'" class="dx-popup-title">
    Order Status Notes
  </div>
  <table>
    <tr>
      <mat-checkbox
        color="primary"
        [(ngModel)]="shippingShortage"
        (click)="UpdateOrders(id, shippingShortage)"
        class="shippingShortage"
        >Shipping Shortage</mat-checkbox
      >
    </tr>
    <tr>
      <hrt-comment
        [commentType]="commentType"
        [comments]="comments"
        (commentAdded)="addComment($event, id)"
      ></hrt-comment>
    </tr>
  </table>
</dx-popup>

<hrt-filter-search
  [tableId]="tableId"
  [pageName]="'OrderStatus'"
  [searchTextPlaceholder]="searchPlaceholder"
  [searchModelField]="orderStatusTable.View"
  [hasFilter]="true"
  (searchVal)="searchChange($event)"
  (filters)="applyFilters($event)"
>
  <button class="hrt-export-btn" (click)="exportTable()" *ngIf="!noResults">
    <span class="csv-text">CSV</span>
    <mat-icon>download</mat-icon>
  </button>
  <div *ngFor="let item of items" class="sales-order">
    <h2 class="sales-rep-name">
      {{ formatRep(item.SalesRep) }}
    </h2>
    <table
      mat-table
      [dataSource]="item.Projects"
      multiTemplateDataRows
      class="table-layout"
    >
      <ng-container matColumnDef="Project Name">
        <th mat-header-cell *matHeaderCellDef style="width: 200px">
          Project Number
        </th>
        <td mat-cell *matCellDef="let element">
          <div style="font-weight: bold; float: left; padding-top: 10px">
            {{ element.FirstItem.Project }}
          </div>
          <button
            (click)="checkIfProjectSiteExists(element.FirstItem.Project)"
            title="View Site Details"
            style="float: right; margin-right: 10px"
            mat-icon-button
          >
            <mat-icon>exit_to_app</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="End User">
        <th mat-header-cell *matHeaderCellDef style="width: auto">End User</th>
        <td mat-cell *matCellDef="let element">
          {{ element.FirstItem.EndUser }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Address">
        <th mat-header-cell *matHeaderCellDef style="width: 250px">Address</th>
        <td mat-cell *matCellDef="let element">
          {{ element.FirstItem.City }}, {{ element.FirstItem.State }},
          {{ element.FirstItem.Country }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Contract Val">
        <th mat-header-cell *matHeaderCellDef style="width: 150px">
          Contract Value
        </th>
        <td mat-cell *matCellDef="let element">
          {{ formatCurrency(element.FirstItem.ContractValue) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Customer">
        <th mat-header-cell *matHeaderCellDef style="width: auto">Customer</th>
        <td mat-cell *matCellDef="let element">
          {{ element.FirstItem.Customer }}
        </td>
      </ng-container>

      <ng-container matColumnDef="PO Number">
        <th mat-header-cell *matHeaderCellDef style="width: 150px">
          PO Number
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.FirstItem.CustomerPo }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Payment Terms">
        <th mat-header-cell *matHeaderCellDef style="width: 150px">
          Payment Terms
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.FirstItem.PaymentTermsID }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Notes">
        <th mat-header-cell *matHeaderCellDef style="width: 50px">Notes</th>
        <td mat-cell *matCellDef="let element">
          <button
            title="View Notes"
            (click)="
              onNotesClick(
                element.FirstItem.Id,
                element.FirstItem.ShippingShortage,
                element.ProjectNotes
              )
            "
            mat-icon-button
          >
            <mat-icon>event_note</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Expandable row -->

      <ng-container matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="displayedColumns.length"
        >
          <div
            class="element-detail"
            [@detailExpand]="
              element == expandedElement ? 'expanded' : 'collapsed'
            "
          >
            <div>
              <table
                style="padding: 10px 20px 20px 10px"
                class="expanded-table"
              >
                <tr class="row-style">
                  <td cols="2" style="font-weight: bold; width: 200px">
                    Item Description
                  </td>
                  <td class="bold">Order Date</td>
                  <td class="bold">Requested Ship Date</td>
                  <td class="bold">Ack Ship Date</td>
                  <td class="bold">Current Ship Date</td>
                  <td class="bold">Approval Drawing</td>
                  <td class="bold">Sales Manager</td>
                  <td class="bold">Project Manager</td>
                  <td class="bold">Project Manager(Alt)</td>
                  <td class="bold">Planner</td>
                  <td class="bold">Tracking Info</td>
                </tr>
                <tr
                  *ngFor="let lineItem of element.LineItems"
                  class="row-styling-data"
                >
                  <td class="caption" cols="2">
                    {{ lineItem.ItemDescription }}
                  </td>
                  <td class="caption">{{ lineItem.TurnInDate }}</td>
                  <td class="caption">{{ lineItem.RequestedShipDate }}</td>
                  <td class="caption">
                    {{ lineItem.AcknowledgedShipDate }}
                  </td>
                  <td class="caption">
                    <span
                      [style]="
                        dateDiffStyle(
                          lineItem.CurrentShipDate,
                          lineItem.AcknowledgedShipDate
                        )
                      "
                    >
                      {{ formatDate(lineItem.CurrentShipDate) }}
                    </span>
                  </td>
                  <td class="caption">
                    {{ lineItem.ApprovalDrawingDate }}
                  </td>
                  <!-- <td class="caption"><a href="mailto:{{formatToEmail(lineItem.SalesManager)}}">{{ lineItem.SalesManager }}</a></td>
                  <td class="caption"><a href="mailto:{{formatToEmail(lineItem.ProjectManager)}}">{{ lineItem.ProjectManager }}</a></td>
                  <td class="caption"><a href="mailto:{{formatToEmail(lineItem.ProjectManager2)}}">{{ lineItem.ProjectManager2 }}</a></td>
                  <td class="caption"><a href="mailto:{{formatToEmail(lineItem.Planner)}}">{{ lineItem.Planner }}</a></td> -->
                  <td class="caption">{{ lineItem.SalesManager }}</td>
                  <td class="caption">{{ lineItem.ProjectManager }}</td>
                  <td class="caption">{{ lineItem.ProjectManager2 }}</td>
                  <td class="caption">{{ lineItem.Planner }}</td>
                  <td>
                    <span
                      [innerHTML]="
                        getShipmentLink(lineItem.ShipmentInformation)
                      "
                    ></span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: displayedColumns"
        class="element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="detail-row"
      ></tr>
    </table>
  </div>
  <div class="no-results" *ngIf="noResults && !loader.isLoading">
    No results found
  </div>
</hrt-filter-search>
