<hrt-filter-search
  [tableId]="tableId"
  [searchTextPlaceholder]="searchPlaceholder"
  [searchModelField]="dashboardTable.View"
  [hasFilter]="hasFilter"
  [presetFilters]="presetFilters"
  (searchVal)="searchChange($event)"
  (filters)="applyFilters($event)"
>
  <div class="user-map">
    <ng-content></ng-content>
  </div>
  <button class="export-btn" (click)="exportTable()">
    <span class="csv-text">CSV</span>
    <mat-icon>download</mat-icon>
  </button>
  <table
    mat-table
    [dataSource]="dashboardTable.Result.Data"
    matSort
    matSortDisableClear
    [matSortActive]="dashboardTable.View.SortColumn"
    (matSortChange)="dashboardTable.RefreshData($event)"
  >
    <ng-container matColumnDef="Icon">
      <th mat-header-cell *matHeaderCellDef class="TableLargeIconColumn"></th>
      <td mat-cell *matCellDef="let row" (click)="handleRowClicked(row)">
        <img
          [src]="getCustomTypeIcon(row.ProductTypeId)"
          [style.max-height.px]="32"
          [style.max-width.px]="getCustomTypeIconWidth(row.ProductTypeId)"
        />
      </td>
    </ng-container>
    <ng-container matColumnDef="AssetTypeId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
      <td mat-cell *matCellDef="let row" (click)="handleRowClicked(row)">
        {{ getCustomTypeText(row.ProductTypeId) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="SiteName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let row" (click)="handleRowClicked(row)">
        {{ row.Name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="SiteAddress">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
      <td mat-cell *matCellDef="let row" (click)="handleRowClicked(row)">
        {{ row.Address }}
      </td>
    </ng-container>
    <ng-container matColumnDef="City">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>City</th>
      <td mat-cell *matCellDef="let row" (click)="handleRowClicked(row)">
        {{ row.City }}
      </td>
    </ng-container>
    <ng-container matColumnDef="State">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
      <td mat-cell *matCellDef="let row" (click)="handleRowClicked(row)">
        {{ row.State }}
      </td>
    </ng-container>
    <ng-container matColumnDef="SiteZip">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Zip</th>
      <td mat-cell *matCellDef="let row" (click)="handleRowClicked(row)">
        {{ row.Zip }}
      </td>
    </ng-container>
    <ng-container matColumnDef="SiteAccountNumbers">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Account Numbers</th>
      <td mat-cell *matCellDef="let row" (click)="handleRowClicked(row)">
        {{ row.AxAccountNumbers }}
      </td>
    </ng-container>
    <ng-container matColumnDef="SiteProjectNumbers">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Project Numbers</th>
      <td mat-cell *matCellDef="let row" (click)="handleRowClicked(row)">
        {{ row.ProjectNumbers }}
      </td>
    </ng-container>
    <ng-container matColumnDef="GeaReferenceName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Reference Name</th>
      <td mat-cell *matCellDef="let row" (click)="handleRowClicked(row)">
        {{ row.GeaReferenceName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Location">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>
      <td mat-cell *matCellDef="let row" (click)="handleRowClicked(row)">
        {{ row.Location }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Manufacturer">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Manufacturer</th>
      <td mat-cell *matCellDef="let row" (click)="handleRowClicked(row)">
        {{ row.Manufacturer }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Model">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Model</th>
      <td mat-cell *matCellDef="let row" (click)="handleRowClicked(row)">
        {{ row.Model }}
      </td>
    </ng-container>
    <ng-container matColumnDef="AlternativeModel">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Alt Model</th>
      <td mat-cell *matCellDef="let row" (click)="handleRowClicked(row)">
        {{ row.AlternativeModel }}
      </td>
    </ng-container>
    <ng-container matColumnDef="ServiceDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
      <td mat-cell *matCellDef="let row" (click)="handleRowClicked(row)">
        {{ dashboardService.formatDate(row.Date) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="ServiceType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
      <td mat-cell *matCellDef="let row" (click)="handleRowClicked(row)">
        {{ dashboardService.getTypeDescription(row.TypeId) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="SerialNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Serial Number</th>
      <td mat-cell *matCellDef="let row" (click)="handleRowClicked(row)">
        {{ row.SerialNumber }}
      </td>
    </ng-container>
    <ng-container matColumnDef="SalesOrderNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Project Number</th>
      <td mat-cell *matCellDef="let row" (click)="handleRowClicked(row)">
        {{ row.SalesOrderNumber }}
      </td>
    </ng-container>
    <ng-container matColumnDef="ConditionId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Condition ID</th>
      <td mat-cell *matCellDef="let row" (click)="handleRowClicked(row)">
        <img
          [src]="getConditionImage(row.ConditionId)"
          height="24"
          width="24"
        />
      </td>
    </ng-container>
    <ng-container matColumnDef="ReportLocation">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Report Location</th>
      <td mat-cell *matCellDef="let row">
        <img
          *ngIf="row.HasReport || row.HasStorageReport"
          [src]="pdfImage"
          height="24"
          width="24"
          style="cursor: pointer"
          (click)="openReports(row)"
        />
      </td>
    </ng-container>
    <ng-container matColumnDef="VisitNotes">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Notes</th>
      <td mat-cell *matCellDef="let row">
        <img
          [src]="infoImage"
          height="24"
          width="24"
          style="cursor: pointer"
          (click)="showNotes(row, $event)"
        />
      </td>
    </ng-container>
    <ng-container matColumnDef="Annunciation">
      <th mat-header-cell *matHeaderCellDef>Annunciation Status</th>
      <td
        mat-cell
        *matCellDef="let row"
        (click)="openRealTimeForAsset(row.Id, row.ConnectionId)"
      >
        <mat-spinner
          [diameter]="20"
          *ngIf="
            !websocket.isSocketConnected ||
            (row.ConnectionId &&
              !getAnnunImage(getAnnunStatusValue(row.ConnectionId)))
          "
          style="margin-left: 30px"
        ></mat-spinner>
        <div
          class="annun-status"
          *ngIf="websocket.isSocketConnected && row.ConnectionId"
        >
          <img
            [style.display]="row.ConnectionId ? 'block' : 'none'"
            [src]="getAnnunImage(getAnnunStatusValue(row.ConnectionId))"
            class="shutdown-status-img"
          />
          <span *ngIf="row.ConnectionId">{{
            getAnnunText(getAnnunStatusValue(row.ConnectionId))
          }}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="MotorStatus">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Motor Status</th>
      <td
        mat-cell
        *matCellDef="let row"
        (click)="openRealTimeForAsset(row.Id, row.ConnectionId)"
      >
        <mat-spinner
          [diameter]="20"
          *ngIf="row.ConnectionId && !getMotorStatus(row.ConnectionId)"
          style="margin-left: 30px"
        ></mat-spinner>
        <div class="annun-status">
          <img
            [style.display]="row.ConnectionId ? 'block' : 'none'"
            [src]="getMotorStatus(row.ConnectionId)"
            class="shutdown-status-img"
          />
          <span *ngIf="getMotorStatus(row.ConnectionId)">{{
            motorStatusText
          }}</span>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td
        class="mat-cell"
        style="text-align: center"
        [attr.colspan]="displayedColumns.length"
      >
        No records found
      </td>
    </tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      style="cursor: pointer"
    ></tr>
  </table>
  <mat-paginator
    [length]="dashboardTable.Result.Count"
    [pageSizeOptions]="dashboardTable.View.PageSizeOptions"
    [pageSize]="dashboardTable.View.PageSize"
    [pageIndex]="dashboardTable.View.Page"
    (page)="dashboardTable.RefreshData($event)"
  >
    >
  </mat-paginator>
</hrt-filter-search>
