import { Component, Input } from '@angular/core';
import { Chart } from 'chart.js';
import { ChartModule } from 'primeng/chart';

@Component({
  selector: 'gea-line-chart',
  standalone: true,
  imports: [ChartModule],
  templateUrl: './line-chart.component.html',
  styleUrl: './line-chart.component.scss',
})
export class LineChartComponent {
  @Input() data: any;
  @Input() chartOptions: any;

  private lineChart: Chart;

  constructor() {}

  ngOnInit() {
    // if (this.data) {
    //   this.createLineChart();
    // } 
  }

  // createLineChart() {
  //   const ctx = document.getElementById('lineChart') as HTMLCanvasElement;
  //   this.lineChart = new Chart(ctx, {
  //     type: 'line',
  //     data: {
  //       labels: this.convertTimeLabels(this.data.timestamps), // Your time labels (e.g., timestamps)
  //       datasets: [
  //         {
  //           label: 'My Time Series Data',
  //           data: this.data.datapoints, // Your data points
  //           borderColor: 'blue',
  //           fill: false,
  //         },
  //       ],
  //     },
  //     options: {
  //       scales: {
  //         x: {
  //           ticks: {
  //             maxTicksLimit: 24
  //           } 
  //         }
  //     },
  //   }});
  // }

  // convertTimeLabels(timestamps: string[]) {
  //   let convertedTimestamps = timestamps.map((time) => 
  //     new Date(time).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})
  //   );
  //   return convertedTimestamps;
  // }

}
