import { ProductSetup, ProductTab } from "../product-setup";

let setup: ProductSetup = new ProductSetup();

setup.Title = "Oil Separator Calculation";
setup.Icon = "oilSeparatorAlt_vibrantBlue.svg";
setup.Image = "oilSeparatorAlt_vibrantBlue.png";

setup.CanCalculate = false;

setup.Tabs = [
    new ProductTab("General", "general")
];

export { setup };