import { Component, OnInit } from '@angular/core';
import { Document } from 'src/app/shared/data/data.generated';
import { ApiService } from 'src/app/shared/services/api.service';
import { Table, TableView } from 'src/app/shared/services/api/table';
import { BehaviorSubject, Subject, debounceTime } from 'rxjs';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-document-library',
  templateUrl: './document-library.component.html',
  styleUrls: ['./document-library.component.scss'],
})
export class DocumentLibraryComponent implements OnInit {
  constructor(
    private api: ApiService,
    private loader: LoaderService,
    private router: Router
  ) {}

  public displayedColumns: string[] = [
    'Title',
    'Company',
    'Language',
    'Type',
    'Size',
    'Download',
  ];
  public table: Table<Document> = new Table<Document>(this.loader);
  public selectedDoc: any | undefined = undefined;
  public downloadDoc: any | undefined = undefined;
  public downloading: boolean = false;
  private apiUrl = '/api/documents/';
  private documentId = 'document_id_here'; // Replace with the actual ID of the document you want to download

  public companyName: string = '';
  public popup: boolean = false;
  public debug: string = '';
  public imageUrl: string = '..assetsiconsdownload.svg';

  ngOnInit(): void {
    this.table.GetApi = (listOptions: TableView) =>
      this.api.Documents.GetDocuments(listOptions);
    this.table.RefreshData();
    this.table.inputSubject.pipe(debounceTime(500)).subscribe((value) => {
      this.table.SearchUpdated();
    });
  }

  openPopUp(doc: Document) {
    this.router.navigate(['document-details', { id: doc.Id }]);
    this.selectedDoc = doc;
    this.debug = JSON.stringify(this.selectedDoc);
    this.popup = true;
  }

  searchChange(searchText: string) {
    if (searchText.trim() !== this.table.inputSubject.getValue()) {
      this.table.inputSubject.next(searchText);
    }
  }

  downloadDocument(doc: Document) {
    if (this.downloading) return;
    this.downloading = true;
    this.api.Documents.DownloadDocument(doc, (err: string) => {
      this.downloading = false;
    });
  }

  getCompanyName(num: string) {
    switch (Number.parseInt(num)) {
      case -1:
        this.companyName = 'All';
        break;
      case 0:
        this.companyName = 'GEA York ';
        break;
      case 1:
        this.companyName = 'GEA Vancouver';
        break;
      case 2:
        this.companyName = 'GEA Vancouver (Aerofreeze)';
        break;
      case 3:
        this.companyName = 'GEA France';
        break;
      case 4:
        this.companyName = 'GEA Berlin';
        break;
      case 5:
        this.companyName = "GEA 's-Hertogenbosch";
        break;
      case 6:
        this.companyName = 'Bock';
        break;
      case 7:
        this.companyName = 'GEA Suzhou';
        break;
      case 8:
        this.companyName = 'GEA EMEA Skids';
        break;
      case 9:
        this.companyName = 'GEA Cape Town';
        break;
      case 10:
        this.companyName = 'GEA EMEA Skids (Service Sales)';
        break;
      case 11:
        this.companyName = "GEA 's-Hertogenbosch (Service Sales)";
        break;
      default:
        this.companyName = 'None';
        break;
    }

    return this.companyName;
  }
}
