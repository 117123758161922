import { Component, OnInit } from '@angular/core';
import { TabData } from 'src/app/shared/components/hrt-tab/hrt-tab.component';
import { AssetAllComponent } from './asset-all/asset-all.component';
import { AssetOmniComponent } from './asset-omni/asset-omni.component';
import { AssetScrewComponent } from './asset-screw/asset-screw.component';
import { AssetPistonComponent } from './asset-piston/asset-piston.component';
import { Router } from '@angular/router';
import { Asset } from 'src/app/shared/data/data.generated';
import {
  GetCustomTypeIcon,
  GetCustomTypeIconWidth,
  GetCustomTypeText,
} from 'src/app/shared/data/product-types';
import { ApiService } from 'src/app/shared/services/api.service';
import { Table, TableView } from 'src/app/shared/services/api/table';
import { environment } from 'src/environments';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { WebsocketService } from 'src/app/shared/services/websocket.service';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss'],
})
export class AssetsComponent implements OnInit {

  assetTabs: TabData[] = [
    {
      tabName: 'All',
      tabComponent: AssetAllComponent,
      isTabVisible : true,
    },
    {
      tabName: 'Screw',
      tabComponent: AssetScrewComponent,
      isTabVisible : true,
    },
    {
      tabName: 'Piston',
      tabComponent: AssetPistonComponent,
      isTabVisible : true,
    },
    {
      tabName: 'Omni',
      tabComponent: AssetOmniComponent,
      isTabVisible : true,
    },
  ];
  public isEnvironment: string | null = environment.name;

  socketInterval: any;

  constructor(
    private readonly api: ApiService,
    private router: Router,
    private loader: LoaderService,
    private websocketService: WebsocketService
  ) {}

  public displayedColumns: string[] = [
    'Icon',
    'Type',
    'ReferenceName',
    'Manufacturer',
    'Model',
    'AlternativeModel',
    'SerialNumber',
    'ProjectNumber',
  ];
  public table: Table<Asset> = new Table<Asset>(this.loader);
  public getCustomTypeText = GetCustomTypeText;
  public getCustomTypeIcon = GetCustomTypeIcon;
  public getCustomTypeIconWidth = GetCustomTypeIconWidth;

  ngOnInit(): void {
    this.pingWebSocket();
    this.table.GetApi = (listOptions: TableView) =>
      this.api.Assets.GetAssets(listOptions);
    this.table.RefreshData();
  }

  openDetails(id: number) {
    this.router.navigate(['/asset-details', { id: id }]);
  }

  pingWebSocket() {
    const pkList: string = this.websocketService.connectedBuckets.join(',');
    const messageStr = JSON.stringify({
      deviceList: pkList,
    });
    this.socketInterval = setInterval(() => {
      if (this.websocketService.isSocketConnected) {
        this.websocketService.send(messageStr);
        // console.log('Message sent:', pkList);
      } else {
        console.log('Socket not connected yet');
      }
    }, 10000);
  }

  ngOnDestroy() {
    clearInterval(this.socketInterval);
  }
}
