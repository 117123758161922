import { Component, OnInit } from '@angular/core';
import { ImagesService } from 'src/app/shared/services/images.service';

@Component({
    selector: 'asset-analysis',
    templateUrl: './asset-analysis.component.html',
    styleUrls: ['./asset-analysis.component.scss']
})
export class AssetAnalysisComponent implements OnInit {

    constructor(private images: ImagesService) { }

    analysisStage: number = 1;
    percentAnalysis: number = 0;

    public warningImage: string = this.images.Icons.Warning;

    ngOnInit(): void {
    }

    analyze() {
        this.analysisStage = 2;
        setInterval(() => {
            if (this.percentAnalysis < 100) {
                this.percentAnalysis += Math.random() * 6;
                if (this.percentAnalysis >= 100) {
                    this.analysisStage = 3;
                }
            }
        }, 100);
    }

}
