import { Component, OnInit } from '@angular/core';
import { Company } from 'src/app/shared/data/data.generated';
import { ApiService } from 'src/app/shared/services/api.service';
import { Table, TableView } from 'src/app/shared/services/api/table';
import { LoaderService } from 'src/app/shared/services/loader.service';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {

  constructor(private readonly api: ApiService, private loader: LoaderService) { }

  public displayedColumns: string[] = ['Name', 'Actions'];
  public table: Table<Company> = new Table<Company>(this.loader);

  ngOnInit(): void {
    this.table.GetApi = (listOptions: TableView) => this.api.Companies.GetCompanies(listOptions);
    this.table.RefreshData();
  }

}
