import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { History } from 'src/app/shared/data/data.generated';
import { ApiService } from 'src/app/shared/services/api.service';
import { Table, TableView } from 'src/app/shared/services/api/table';

@Component({
    selector: 'asset-history',
    templateUrl: './asset-history.component.html',
    styleUrls: ['./asset-history.component.scss']
})
export class AssetHistoryComponent implements OnInit, OnChanges {

    constructor(private api: ApiService) { }

    @Input() public assetId: number = -1;

    public displayedColumns: string[] = ['Time', 'Description'];
    public table: Table<History> = new Table<History>();

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['assetId'].firstChange) {
            this.table.GetApi = (listOptions: TableView) => this.api.Assets.GetAssetHistory(listOptions, this.assetId);
            this.table.RefreshData();
        }
    }

    formatDate(dateStr: string) {
        return new Date(dateStr).toLocaleDateString('en-US', { timeZone: 'UTC' });
    }

}
