import { CommonModule } from '@angular/common';
import {
  NgModule,
  Component,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'hrt-progress-indicator-svg',
  templateUrl: './progress-indicator-svg.component.html',
  styleUrls: ['./progress-indicator-svg.component.scss'],
})
export class ProgressIndicatorSvgComponent {

  isActive = false;
  percentage: number;

  @Input() set progressPercent(percent: number) {
    this.percentage = percent;
    this.setProgress(percent);
  }
  @Input() count: number = 0;
  @Input() statusText: string = '';
  @Input() statusTextColor: string = '';
  @Input() circleColor: string = '';
  @Input() progressColor: string = '';
  @Input() className: string = '';
  @Output() svgClicked = new EventEmitter<string>();

  ngAfterViewInit() {
    if (this.percentage) {
      this.setProgress(this.percentage);
    }
  }

  setProgress(percent: number) {
    const svg = document.querySelector('.' + this.className + '-mySvg');
    const path = document.querySelector('.' + this.className + '-myPath');
    const radius = 45;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference * (1 - percent / 100);

    // Calculate start/end points and large arc flag for the path's 'd' attribute
    let startX = 60;
    let startY = 60 - radius;
    let endX = 60 + radius * Math.sin((2 * Math.PI * percent) / 100);
    let endY = 60 - radius * Math.cos((2 * Math.PI * percent) / 100);
    const largeArcFlag = percent > 50 ? 1 : 0;

    if (percent == 100) {
      startX += 0.01;
      // endY += 0.1;
    }


    if (path) {
      path.setAttribute(
        'd',
        `M ${startX} ${startY} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}`
      );
    }
  }

  iconClickedFunc() {
    this.isActive = !this.isActive;
    this.svgClicked.emit(this.statusText);
  }
}

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [ProgressIndicatorSvgComponent],
  exports: [ProgressIndicatorSvgComponent],
})
export class ProgressIndicatorSvgModule {}
