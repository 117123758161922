import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { Asset } from '../../shared/data/data.generated';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/shared/services/config.service';

@Component({
  templateUrl: 'home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  currentFeedPage = 0;
  configPage: string ="Homepage";
  uiLabels: any;
  configDataReceived = false;
  feedInterval: any;

  constructor(private readonly api: ApiService, private router: Router, public config: ConfigService) {}

  productSlides: ProductSlides[];

  appInfos: AppInfo[];

  ngOnInit(): void {
    this.config.configData.subscribe(data => {
      if (data) {
        this.uiLabels = this.config.globalConfigData['Homepage'];
        this.populateLabels();
        this.configDataReceived = true;
        this.feedInterval = setInterval(() => {
          if (this.uiLabels) {
            this.nextFeed();
          }
        }, 5000); 
      }
    });
    // auto scrolling feed every 5 seconds
    
  }

  populateLabels() {
    this.productSlides = [
      {
        title: this.uiLabels['vxhpHeader'],
        productImgSrc: '../../../assets/home/GEA_V-XHP_oben_blau.png',
        text: this.uiLabels['vxhp'],
        altText: 'V HXP',
        classes: 'vxp',
        link: 'https://www.gea.com/en/products/compressors/reciprocating-industrial-compressors/gea-v-xhp/',
      },
      {
        title: this.uiLabels['bluAstrumHeader'],
        productImgSrc: '../../../assets/home/gea_bluastrum_chiller.png',
        text: this.uiLabels['bluAstrum'],
        altText: 'Blu Astrum',
        classes: 'blu-astrum',
        link: 'https://www.gea.com/en/products/chillers/bluastrum-compact-screw-chiller/',
      },
      {
        title: this.uiLabels['blu-redFusionHeader'],
        productImgSrc: '../../../assets/home/BluRedFusion.PNG',
        text: this.uiLabels['blu-redFusion'],
        altText: 'Blu-Red Fusion',
        classes: 'blu-red-fusion',
        link: 'https://www.gea.com/en/products/chillers/gea-blu-red-fusion/',
      },
      {
        title: this.uiLabels['omniRetrofitHeader'],
        productImgSrc: '../../../assets/home/gea-omni-control-panel.png',
        text: this.uiLabels['omniRetrofitPanel'],
        altText: 'Omni',
        classes: 'omni',
        link: 'https://www.gea.com/en/products/compressors/omni-retrofit-panel/',
      },
    ];
    this.appInfos = [
      {
        title:  this.uiLabels['documentsHeader'],
        description: this.uiLabels['documents'],
        appImgSrc: '../../../assets/home/16px_book_white.svg',
        altText: 'Document Library',
        classes: 'mat-custom-icon',
        routerLink: '/document-library',
      },
      {
        title: this.uiLabels['rtSelectHeader'],
        description: this.uiLabels['rtSelect'],
        appImgSrc: '../../../assets/home/GEA_Globe_Europe_Africa_sRGB.svg',
        altText: 'RTSelect Application',
        externalLink: 'https://www.gea.com/en/campaigns/rtselect/',
      },
      {
        title: this.uiLabels['assetsHeader'],
        description: this.uiLabels['assets'],
        appImgSrc:
          '../../../assets/home/16px_playlist_white.svg',
        classes: 'mat-custom-icon',
        altText: 'Assets',
        routerLink: '/assets',
      },
      {
        title: this.uiLabels['blu-redCareHeader'],
        description: this.uiLabels['blu-redCare'],
        appImgSrc: '../../../assets/dashboard/BluRedCareWhite.png',
        altText: 'Blu-Red Care Application',
        externalLink: 'https://www.gea.com/en/products/controls/insightpartner-blu-red-care/',
      },
    ];
  }

  prevFeed() {
    if (this.currentFeedPage > 0) {
      this.currentFeedPage -= 1;
      const el = document.getElementById('link-' + this.currentFeedPage);
      el?.click();
    } else {
      this.currentFeedPage = this.productSlides.length - 1;
      this.clickLink(this.currentFeedPage);
    }
  }

  nextFeed(event?: MouseEvent) {
    event?.preventDefault();
    if (this.currentFeedPage < this.productSlides.length - 1) {
      this.currentFeedPage += 1;
      this.clickLink(this.currentFeedPage);
    } else {
      this.currentFeedPage = 0;
      this.clickLink(this.currentFeedPage);
    }
  }

  clickLink(id: number) {
    const el = document.getElementById('link-' + id);
    el?.click();
  }


  redirectToProduct(redirectUrl: string) {
    this.openInNewTab(redirectUrl);
  }

  navigateToApp(app: AppInfo) {
    if (app.routerLink) {
      this.router.navigate([app.routerLink]);
    }
    if (app.externalLink) {
      this.openInNewTab(app.externalLink);
    }
  }

  openInNewTab(url: string) {
    window.open(url, '_blank')?.focus();
  }

  ngOnDestroy() {
    if (this.feedInterval) {
      clearInterval(this.feedInterval);
    }
  }
}

interface ProductSlides {
  title?: string,
  productImgSrc: string,
  text?: string,
  altText: string,
  link: string,
  classes?: string,
}
interface AppInfo {
  title?: string;
  description?: string;
  appImgSrc: string;
  altText?: string,
  classes?: string;
  routerLink?: string;
  externalLink?: string;
}
