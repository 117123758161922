import { Component, Input } from '@angular/core';
import { FeedbackService } from 'src/app/shared/services/feedback.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { environment } from 'src/environments';

@Component({
  selector: 'app-feedback-table-wrapper',
  templateUrl: './feedback-table-wrapper.component.html',
  styleUrl: './feedback-table-wrapper.component.scss',
})
export class FeedbackTableWrapperComponent {
  constructor(
    public feedbackService: FeedbackService,
    private utilityService: UtilityService
  ) {}

  @Input() data: any;
  tableId: string;
  public userRole: any;
  public isEnvironment : string | null = environment.name;
  showRedirectMessage : boolean = false;
  
  ngOnInit() {
    if(this.isEnvironment=='test'){
      this.showRedirectMessage = true;
    }
    this.userRole = sessionStorage.getItem('RoleId');
    this.tableId = this.data?.tableId;
    this.utilityService.setActiveTab(this.data.tabNo, 'feedbackActiveIndex');
  }

  redirectToProd(): void {
    window.open("https://hrtconnect.apps.gea.com/#/feedback", '_blank')
  }
}
