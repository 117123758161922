import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ImagesService } from 'src/app/shared/services/images.service';

@Component({
    selector: 'asset-historical-data',
    templateUrl: './asset-historical-data.component.html',
    styleUrls: ['./asset-historical-data.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class AssetHistoricalDataComponent implements OnInit {

    constructor(private api: ApiService, private images: ImagesService) { }

    @Input() public assetId: number = -1;

    public displayedColumns: string[] = ["Time", "SuctionPressure", "DischargePressure", "CapacityPercentage", "ShutdownStatus", "WarningStatus"];
    public displayedColumnsWithExpand = [...this.displayedColumns, "expand"];
    public expandedElement: HistoricalData | undefined = undefined;

    public dummyData: HistoricalData[] =
        [
            {
                "Time": "4/11/2018 2:54:21 PM",
                "SuctionPressure": "2.73 barA",
                "DischargePressure": "12.26 barA",
                "OilPressure": "14.52 barA",
                "SuctionTemperature": "-7.7 °C",
                "DischargeTemperature": "44.0 °C",
                "OilTemperature": "57.0 °C",
                "MotorCurrent": "138.2 A",
                "SaturatedSuctionTemperature": "-11.6 °C",
                "SaturatedDischargeTemperature": "31.6 °C",
                "SuctionSuperheat": "3.9 K",
                "AccumulatedRunTimeHours": 26,
                "KWTotalUsage": 2072.2,
                "CapacityPercentage": 14,
                "MotorSpeed": "3600 rpm",
                "ShutdownStatus": "On",
                "WarningStatus": "Off"
            },
            {
                "Time": "4/11/2018 2:53:21 PM",
                "SuctionPressure": "2.76 barA",
                "DischargePressure": "10.36 barA",
                "OilPressure": "12.57 barA",
                "SuctionTemperature": "-7.7 °C",
                "DischargeTemperature": "70.5 °C",
                "OilTemperature": "59.0 °C",
                "MotorCurrent": "122.3 A",
                "SaturatedSuctionTemperature": "-11.3 °C",
                "SaturatedDischargeTemperature": "26.0 °C",
                "SuctionSuperheat": "3.6 K",
                "AccumulatedRunTimeHours": 26,
                "KWTotalUsage": 2071.1,
                "CapacityPercentage": 11,
                "MotorSpeed": "3600 rpm",
                "ShutdownStatus": "On",
                "WarningStatus": "Off"
            },
            {
                "Time": "4/11/2018 2:52:21 PM",
                "SuctionPressure": "2.75 barA",
                "DischargePressure": "8.77 barA",
                "OilPressure": "11.11 barA",
                "SuctionTemperature": "-7.7 °C",
                "DischargeTemperature": "61.8 °C",
                "OilTemperature": "53.1 °C",
                "MotorCurrent": "106.0 A",
                "SaturatedSuctionTemperature": "-11.4 °C",
                "SaturatedDischargeTemperature": "20.7 °C",
                "SuctionSuperheat": "3.7 K",
                "AccumulatedRunTimeHours": 26,
                "KWTotalUsage": 2070.1,
                "CapacityPercentage": 7,
                "MotorSpeed": "3600 rpm",
                "ShutdownStatus": "On",
                "WarningStatus": "On"
            },
            {
                "Time": "4/11/2018 2:51:21 PM",
                "SuctionPressure": "2.76 barA",
                "DischargePressure": "13.66 barA",
                "OilPressure": "15.62 barA",
                "SuctionTemperature": "-7.7 °C",
                "DischargeTemperature": "49.4 °C",
                "OilTemperature": "60.8 °C",
                "MotorCurrent": "150.4 A",
                "SaturatedSuctionTemperature": "-11.3 °C",
                "SaturatedDischargeTemperature": "35.4 °C",
                "SuctionSuperheat": "3.6 K",
                "AccumulatedRunTimeHours": 26,
                "KWTotalUsage": 2068.9,
                "CapacityPercentage": 16,
                "MotorSpeed": "3600 rpm",
                "ShutdownStatus": "On",
                "WarningStatus": "Off"
            },
            {
                "Time": "4/11/2018 2:50:21 PM",
                "SuctionPressure": "2.75 barA",
                "DischargePressure": "11.14 barA",
                "OilPressure": "13.16 barA",
                "SuctionTemperature": "-7.7 °C",
                "DischargeTemperature": "73.3 °C",
                "OilTemperature": "57.6 °C",
                "MotorCurrent": "127.0 A",
                "SaturatedSuctionTemperature": "-11.4 °C",
                "SaturatedDischargeTemperature": "28.4 °C",
                "SuctionSuperheat": "3.7 K",
                "AccumulatedRunTimeHours": 26,
                "KWTotalUsage": 2067.7,
                "CapacityPercentage": 12,
                "MotorSpeed": "3600 rpm",
                "ShutdownStatus": "On",
                "WarningStatus": "Off"
            },
            {
                "Time": "4/11/2018 2:49:21 PM",
                "SuctionPressure": "2.74 barA",
                "DischargePressure": "10.01 barA",
                "OilPressure": "12.38 barA",
                "SuctionTemperature": "-7.6 °C",
                "DischargeTemperature": "46.6 °C",
                "OilTemperature": "58.9 °C",
                "MotorCurrent": "119.3 A",
                "SaturatedSuctionTemperature": "-11.5 °C",
                "SaturatedDischargeTemperature": "24.9 °C",
                "SuctionSuperheat": "3.9 K",
                "AccumulatedRunTimeHours": 26,
                "KWTotalUsage": 2066.7,
                "CapacityPercentage": 9,
                "MotorSpeed": "3600 rpm",
                "ShutdownStatus": "On",
                "WarningStatus": "Off"
            },
            {
                "Time": "4/11/2018 2:48:21 PM",
                "SuctionPressure": "2.78 barA",
                "DischargePressure": "10.05 barA",
                "OilPressure": "12.02 barA",
                "SuctionTemperature": "-7.7 °C",
                "DischargeTemperature": "68.7 °C",
                "OilTemperature": "62.4 °C",
                "MotorCurrent": "120.7 A",
                "SaturatedSuctionTemperature": "-11.1 °C",
                "SaturatedDischargeTemperature": "25.0 °C",
                "SuctionSuperheat": "3.4 K",
                "AccumulatedRunTimeHours": 26,
                "KWTotalUsage": 2065.6,
                "CapacityPercentage": 10,
                "MotorSpeed": "3600 rpm",
                "ShutdownStatus": "On",
                "WarningStatus": "Off"
            },
            {
                "Time": "4/11/2018 2:47:21 PM",
                "SuctionPressure": "2.74 barA",
                "DischargePressure": "12.86 barA",
                "OilPressure": "14.77 barA",
                "SuctionTemperature": "-7.5 °C",
                "DischargeTemperature": "61.5 °C",
                "OilTemperature": "54.0 °C",
                "MotorCurrent": "138.9 A",
                "SaturatedSuctionTemperature": "-11.5 °C",
                "SaturatedDischargeTemperature": "33.3 °C",
                "SuctionSuperheat": "4.0 K",
                "AccumulatedRunTimeHours": 26,
                "KWTotalUsage": 2064.5,
                "CapacityPercentage": 14,
                "MotorSpeed": "3600 rpm",
                "ShutdownStatus": "On",
                "WarningStatus": "Off"
            },
            {
                "Time": "4/11/2018 2:46:21 PM",
                "SuctionPressure": "2.77 barA",
                "DischargePressure": "12.24 barA",
                "OilPressure": "14.38 barA",
                "SuctionTemperature": "-7.5 °C",
                "DischargeTemperature": "51.8 °C",
                "OilTemperature": "61.1 °C",
                "MotorCurrent": "137.2 A",
                "SaturatedSuctionTemperature": "-11.2 °C",
                "SaturatedDischargeTemperature": "31.6 °C",
                "SuctionSuperheat": "3.7 K",
                "AccumulatedRunTimeHours": 26,
                "KWTotalUsage": 2063.2,
                "CapacityPercentage": 13,
                "MotorSpeed": "3600 rpm",
                "ShutdownStatus": "On",
                "WarningStatus": "Off"
            },
            {
                "Time": "4/11/2018 2:45:21 PM",
                "SuctionPressure": "2.77 barA",
                "DischargePressure": "9.99 barA",
                "OilPressure": "12.16 barA",
                "SuctionTemperature": "-7.5 °C",
                "DischargeTemperature": "72.5 °C",
                "OilTemperature": "57.6 °C",
                "MotorCurrent": "116.8 A",
                "SaturatedSuctionTemperature": "-11.2 °C",
                "SaturatedDischargeTemperature": "24.8 °C",
                "SuctionSuperheat": "3.7 K",
                "AccumulatedRunTimeHours": 26,
                "KWTotalUsage": 2062,
                "CapacityPercentage": 8,
                "MotorSpeed": "3600 rpm",
                "ShutdownStatus": "On",
                "WarningStatus": "Off"
            },
            {
                "Time": "4/11/2018 2:45:15 PM",
                "SuctionPressure": "2.76 barA",
                "DischargePressure": "9.79 barA",
                "OilPressure": "12.02 barA",
                "SuctionTemperature": "-7.5 °C",
                "DischargeTemperature": "70.6 °C",
                "OilTemperature": "57.0 °C",
                "MotorCurrent": "119.1 A",
                "SaturatedSuctionTemperature": "-11.3 °C",
                "SaturatedDischargeTemperature": "24.2 °C",
                "SuctionSuperheat": "3.8 K",
                "AccumulatedRunTimeHours": 26,
                "KWTotalUsage": 2061.9,
                "CapacityPercentage": 8,
                "MotorSpeed": "3600 rpm",
                "ShutdownStatus": "On",
                "WarningStatus": "Off"
            },
            {
                "Time": "4/11/2018 2:44:21 PM",
                "SuctionPressure": "2.76 barA",
                "DischargePressure": "9.08 barA",
                "OilPressure": "11.51 barA",
                "SuctionTemperature": "-7.5 °C",
                "DischargeTemperature": "47.4 °C",
                "OilTemperature": "58.1 °C",
                "MotorCurrent": "109.7 A",
                "SaturatedSuctionTemperature": "-11.3 °C",
                "SaturatedDischargeTemperature": "21.8 °C",
                "SuctionSuperheat": "3.8 K",
                "AccumulatedRunTimeHours": 26,
                "KWTotalUsage": 2061,
                "CapacityPercentage": 6,
                "MotorSpeed": "3600 rpm",
                "ShutdownStatus": "Off",
                "WarningStatus": "Off"
            },
            {
                "Time": "4/11/2018 2:43:21 PM",
                "SuctionPressure": "2.79 barA",
                "DischargePressure": "11.22 barA",
                "OilPressure": "13.08 barA",
                "SuctionTemperature": "-7.5 °C",
                "DischargeTemperature": "69.5 °C",
                "OilTemperature": "61.9 °C",
                "MotorCurrent": "129.4 A",
                "SaturatedSuctionTemperature": "-11.1 °C",
                "SaturatedDischargeTemperature": "28.7 °C",
                "SuctionSuperheat": "3.6 K",
                "AccumulatedRunTimeHours": 26,
                "KWTotalUsage": 2060,
                "CapacityPercentage": 12,
                "MotorSpeed": "3600 rpm",
                "ShutdownStatus": "Off",
                "WarningStatus": "Off"
            },
            {
                "Time": "4/11/2018 2:42:21 PM",
                "SuctionPressure": "2.75 barA",
                "DischargePressure": "12.60 barA",
                "OilPressure": "14.58 barA",
                "SuctionTemperature": "-7.4 °C",
                "DischargeTemperature": "60.0 °C",
                "OilTemperature": "53.5 °C",
                "MotorCurrent": "139.9 A",
                "SaturatedSuctionTemperature": "-11.4 °C",
                "SaturatedDischargeTemperature": "32.6 °C",
                "SuctionSuperheat": "4.0 K",
                "AccumulatedRunTimeHours": 26,
                "KWTotalUsage": 2058.8,
                "CapacityPercentage": 13,
                "MotorSpeed": "3600 rpm",
                "ShutdownStatus": "Off",
                "WarningStatus": "Off"
            },
            {
                "Time": "4/11/2018 2:41:21 PM",
                "SuctionPressure": "2.78 barA",
                "DischargePressure": "11.60 barA",
                "OilPressure": "13.82 barA",
                "SuctionTemperature": "-7.2 °C",
                "DischargeTemperature": "51.9 °C",
                "OilTemperature": "60.2 °C",
                "MotorCurrent": "129.8 A",
                "SaturatedSuctionTemperature": "-11.1 °C",
                "SaturatedDischargeTemperature": "29.8 °C",
                "SuctionSuperheat": "3.9 K",
                "AccumulatedRunTimeHours": 26,
                "KWTotalUsage": 2057.5,
                "CapacityPercentage": 11,
                "MotorSpeed": "3600 rpm",
                "ShutdownStatus": "Off",
                "WarningStatus": "Off"
            },
            {
                "Time": "4/11/2018 2:40:21 PM",
                "SuctionPressure": "2.79 barA",
                "DischargePressure": "9.39 barA",
                "OilPressure": "11.66 barA",
                "SuctionTemperature": "-7.2 °C",
                "DischargeTemperature": "72.6 °C",
                "OilTemperature": "56.7 °C",
                "MotorCurrent": "112.9 A",
                "SaturatedSuctionTemperature": "-11.1 °C",
                "SaturatedDischargeTemperature": "22.8 °C",
                "SuctionSuperheat": "3.9 K",
                "AccumulatedRunTimeHours": 26,
                "KWTotalUsage": 2056.4,
                "CapacityPercentage": 6,
                "MotorSpeed": "3600 rpm",
                "ShutdownStatus": "Off",
                "WarningStatus": "Off"
            },
            {
                "Time": "4/11/2018 2:40:01 PM",
                "SuctionPressure": "2.77 barA",
                "DischargePressure": "8.93 barA",
                "OilPressure": "11.34 barA",
                "SuctionTemperature": "-7.1 °C",
                "DischargeTemperature": "67.9 °C",
                "OilTemperature": "54.4 °C",
                "MotorCurrent": "109.9 A",
                "SaturatedSuctionTemperature": "-11.2 °C",
                "SaturatedDischargeTemperature": "21.3 °C",
                "SuctionSuperheat": "4.1 K",
                "AccumulatedRunTimeHours": 26,
                "KWTotalUsage": 2056.1,
                "CapacityPercentage": 4,
                "MotorSpeed": "3600 rpm",
                "ShutdownStatus": "Off",
                "WarningStatus": "Off"
            },
            {
                "Time": "4/11/2018 2:39:43 PM",
                "SuctionPressure": "2.76 barA",
                "DischargePressure": "8.68 barA",
                "OilPressure": "11.15 barA",
                "SuctionTemperature": "-7.0 °C",
                "DischargeTemperature": "62.4 °C",
                "OilTemperature": "53.2 °C",
                "MotorCurrent": "108.3 A",
                "SaturatedSuctionTemperature": "-11.3 °C",
                "SaturatedDischargeTemperature": "20.4 °C",
                "SuctionSuperheat": "4.3 K",
                "AccumulatedRunTimeHours": 26,
                "KWTotalUsage": 2055.8,
                "CapacityPercentage": 4,
                "MotorSpeed": "3600 rpm",
                "ShutdownStatus": "On",
                "WarningStatus": "Off"
            },
            {
                "Time": "4/11/2018 2:39:21 PM",
                "SuctionPressure": "2.78 barA",
                "DischargePressure": "8.48 barA",
                "OilPressure": "11.00 barA",
                "SuctionTemperature": "-6.9 °C",
                "DischargeTemperature": "54.0 °C",
                "OilTemperature": "53.8 °C",
                "MotorCurrent": "105.3 A",
                "SaturatedSuctionTemperature": "-11.1 °C",
                "SaturatedDischargeTemperature": "19.6 °C",
                "SuctionSuperheat": "4.2 K",
                "AccumulatedRunTimeHours": 26,
                "KWTotalUsage": 2055.5,
                "CapacityPercentage": 3,
                "MotorSpeed": "3600 rpm",
                "ShutdownStatus": "On",
                "WarningStatus": "Off"
            },
            {
                "Time": "4/11/2018 2:38:21 PM",
                "SuctionPressure": "3.24 barA",
                "DischargePressure": "12.96 barA",
                "OilPressure": "14.92 barA",
                "SuctionTemperature": "-6.9 °C",
                "DischargeTemperature": "57.6 °C",
                "OilTemperature": "61.9 °C",
                "MotorCurrent": "141.8 A",
                "SaturatedSuctionTemperature": "-7.4 °C",
                "SaturatedDischargeTemperature": "33.5 °C",
                "SuctionSuperheat": "0.5 K",
                "AccumulatedRunTimeHours": 26,
                "KWTotalUsage": 2054.5,
                "CapacityPercentage": 14,
                "MotorSpeed": "3600 rpm",
                "ShutdownStatus": "On",
                "WarningStatus": "Off"
            },
            {
                "Time": "4/11/2018 2:37:21 PM",
                "SuctionPressure": "3.02 barA",
                "DischargePressure": "11.75 barA",
                "OilPressure": "13.81 barA",
                "SuctionTemperature": "-7.6 °C",
                "DischargeTemperature": "67.6 °C",
                "OilTemperature": "54.7 °C",
                "MotorCurrent": "130.7 A",
                "SaturatedSuctionTemperature": "-9.1 °C",
                "SaturatedDischargeTemperature": "30.2 °C",
                "SuctionSuperheat": "1.5 K",
                "AccumulatedRunTimeHours": 26,
                "KWTotalUsage": 2053.2,
                "CapacityPercentage": 11,
                "MotorSpeed": "3600 rpm",
                "ShutdownStatus": "On",
                "WarningStatus": "Off"
            },
            {
                "Time": "4/11/2018 2:36:21 PM",
                "SuctionPressure": "3.03 barA",
                "DischargePressure": "10.84 barA",
                "OilPressure": "13.24 barA",
                "SuctionTemperature": "-8.2 °C",
                "DischargeTemperature": "44.9 °C",
                "OilTemperature": "58.8 °C",
                "MotorCurrent": "123.8 A",
                "SaturatedSuctionTemperature": "-9.0 °C",
                "SaturatedDischargeTemperature": "27.5 °C",
                "SuctionSuperheat": "0.8 K",
                "AccumulatedRunTimeHours": 26,
                "KWTotalUsage": 2052.1,
                "CapacityPercentage": 9,
                "MotorSpeed": "3600 rpm",
                "ShutdownStatus": "On",
                "WarningStatus": "Off"
            },
            {
                "Time": "4/11/2018 2:35:21 PM",
                "SuctionPressure": "2.88 barA",
                "DischargePressure": "9.18 barA",
                "OilPressure": "11.34 barA",
                "SuctionTemperature": "-8.7 °C",
                "DischargeTemperature": "72.0 °C",
                "OilTemperature": "59.2 °C",
                "MotorCurrent": "111.7 A",
                "SaturatedSuctionTemperature": "-10.3 °C",
                "SaturatedDischargeTemperature": "22.1 °C",
                "SuctionSuperheat": "1.6 K",
                "AccumulatedRunTimeHours": 26,
                "KWTotalUsage": 2051,
                "CapacityPercentage": 4,
                "MotorSpeed": "3600 rpm",
                "ShutdownStatus": "On",
                "WarningStatus": "Off"
            },
            {
                "Time": "4/11/2018 2:34:21 PM",
                "SuctionPressure": "2.69 barA",
                "DischargePressure": "12.87 barA",
                "OilPressure": "14.91 barA",
                "SuctionTemperature": "-8.6 °C",
                "DischargeTemperature": "56.8 °C",
                "OilTemperature": "53.4 °C",
                "MotorCurrent": "148.7 A",
                "SaturatedSuctionTemperature": "-11.9 °C",
                "SaturatedDischargeTemperature": "33.3 °C",
                "SuctionSuperheat": "3.3 K",
                "AccumulatedRunTimeHours": 26,
                "KWTotalUsage": 2049.9,
                "CapacityPercentage": 24,
                "MotorSpeed": "3600 rpm",
                "ShutdownStatus": "On",
                "WarningStatus": "Off"
            }
        ];

    ngOnInit(): void {
        //this.api.Assets.GetAssetHistoricalData(this.assetId)
        //  .subscribe(x => {
        //    this.data = x;
        //  })
    }

    getStatusImage(status: string) {
        if (status === "On") {
            return this.images.Icons.Check;
        }
        if (status === "Off") {
            return this.images.Icons.Warning;
        }
        return "";
    }

}

class HistoricalData {
    public Time: string = "";
    public SuctionPressure: string = "";
    public DischargePressure: string = "";
    public OilPressure: string = "";
    public SuctionTemperature: string = "";
    public DischargeTemperature: string = "";
    public OilTemperature: string = "";
    public MotorCurrent: string = "";
    public SaturatedSuctionTemperature: string = "";
    public SaturatedDischargeTemperature: string = "";
    public SuctionSuperheat: string = "";
    public AccumulatedRunTimeHours: number = 0;
    public KWTotalUsage: number = 0;
    public CapacityPercentage: number = 0;
    public MotorSpeed: string = "";
    public ShutdownStatus: string = "";
    public WarningStatus: string = "";
}