export enum FeedbackPriority {
    Na = 0,
    Low = 1,
    Medium = 2,
    High = 3,
    Urgent = 4
};

export const FeedbackPriorities = [
    { Id: FeedbackPriority.Na, Name: "N/A" },
    { Id: FeedbackPriority.Low, Name: "Low" },
    { Id: FeedbackPriority.Medium, Name: "Medium" },
    { Id: FeedbackPriority.High, Name: "High" },
    { Id: FeedbackPriority.Urgent, Name: "Urgent" }
];

export function GetFeedbackPriorityText(id: number) {
    let textData = FeedbackPriorities.find(x => <number>x.Id === id);
    if (textData) {
        return textData.Name;
    }
    else {
        return "Unknown";
    }
}