import {Injectable} from '@angular/core'; 
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments';

@Injectable({
  providedIn: 'root'
})

export class SmartLinkService {
  constructor(
    private title: Title,
    private meta: Meta,
  ) { }


  updateTitle(id: number, siteTitle: string, siteName: string) {
    let trimmedTitle = siteTitle;
    if (siteTitle.length > 30) {
      trimmedTitle = siteTitle.substring(0, 30) + '...';
    }
    const formattedTitle = `${id}: ${trimmedTitle} - ${siteName}`;
    this.updateMetaData(formattedTitle);
  }


  updateMetaData(title: string){
    let siteName = this.getSiteNameByEnvironment();
    const titleWithSiteName = `${title} - ${siteName}`;
    this.title.setTitle(titleWithSiteName);
  }

  
  getSiteNameByEnvironment(): string{
    switch(environment.name){
      case 'local':
        return 'HRT Connect(Local)'
      case 'dev':
        return 'HRT Connect(DEV)'
      case 'test':
        return 'HRT Connect(TEST)'
      case 'uat':
        return 'HRT Connect(UAT)'
      default:
        return 'HRT Connect'
    }
  }


}