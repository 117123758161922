export enum FeedbackType {
    Feedback = 0,
    FeatureRequest = 1,
    ProblemBug = 2
};

export const FeedbackTypes = [
    { Id: FeedbackType.Feedback, Name: "Feedback" },
    { Id: FeedbackType.FeatureRequest, Name: "Feature Request" },
    { Id: FeedbackType.ProblemBug, Name: "Problem / Bug" }
];

export function GetFeedbackTypeText(id: number) {
    let textData = FeedbackTypes.find(x => <number>x.Id === id);
    if (textData) {
        return textData.Name;
    }
    else {
        return "Unknown";
    }
}