import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'hrt-loader',
  templateUrl: './hrt-loader.component.html',
  styleUrl: './hrt-loader.component.scss'
})
export class HrtLoaderComponent {

  showLoader: boolean;

  @Input() set setLoader(val: boolean) {
    this.showLoader = val;
  };

  @Input() loadingMessage: string;
}
