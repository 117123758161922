import { Observable } from 'rxjs';
import { ApiService } from "../api.service";
import { TableResult, TableView } from './table';
import { User, UserApplicationFeature, UserApplicationFeaturesWithRoleId, UserNotification, UserView } from '../../data/data.generated';

export class Users {
  constructor(private _api: ApiService) {
  }

  public Login(email: string, password: string): Observable<{ success: boolean, token: string, error: string, type: number }> {
    return this._api.Get<any>("Login",
      {
        Email: email,
        Password: password
      });
  }

  public ChangePassword(email: string, password: string, newPassword: string): Observable<string> {
    return this._api.Get<string>("ChangePassword",
      {
        Email: email,
        Password: password,
        NewPassword: newPassword
      });
  }

  public AdminChangePassword(email: string, password: string, newPassword: string): Observable<string> {
    return this._api.Get<string>("AdminChangePassword",
      {
        Email: email,
        Password: password,
        NewPassword: newPassword
      });
  }

  public GetUsers(listOptions: TableView): Observable<TableResult<User>> {
    return this._api.Get<TableResult<User>>("GetUsers",
      {
        ...listOptions
      });
  }

  public GetUserDetails(id: number): Observable<UserView> {
    return this._api.Get<UserView>("GetUserDetails",
      {
        Id: id
      });
  }

  public GetUserApplicationFeatures(id: number, email: string): Observable<UserApplicationFeaturesWithRoleId> {
    return this._api.Get<UserApplicationFeaturesWithRoleId>("GetUserApplicationFeatures",
      {
        Id: id,
        Email: email
      });
  }

  public GetUserNotifications(id: number): Observable<UserNotification[]> {
    return this._api.Get<UserNotification[]>("GetUserNotifications",
      {
        Id: id
      });
  }

  public UpdateUserCustomerNumbers(email: string, customerNumbers: string): Observable<UserApplicationFeaturesWithRoleId> {
    return this._api.Get<UserApplicationFeaturesWithRoleId>("UpdateUserCustomerNumbers",
      {
        Email: email,
        CustomerNumbers: customerNumbers
      });
  }

  public CreateUser(user: User, permissions: any): Observable<string> {
    return this._api.Get<string>("CreateUser",
      {
        Object: JSON.stringify(user),
        Permissions: JSON.stringify(permissions)
      });
  }

  public UpdateUser(user: User, permissions: any, notifications: any): Observable<string> {
    return this._api.Get<string>("UpdateUser",
      {
        Object: JSON.stringify(user),
        Permissions: JSON.stringify(permissions),
        Notifications: JSON.stringify(notifications)
      });
  }

  public DeleteUser(id: number): Observable<string> {
    return this._api.Get<string>("DeleteUser",
      {
        Id: id
      });
  }
}
