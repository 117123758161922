import { Component, NgModule, Input, Output, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyTooltipModule as MatToolTipModule } from '@angular/material/legacy-tooltip';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'src/app/material.module';
import { CommentView, FeedbackCommentView } from 'src/app/shared/data/data.generated';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'gea-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
})
export class CommentsComponent {

  @Input() comments: CommentView[] = [];
  @Input() commentType: string;
  @Output() commentAdded = new EventEmitter<string>();

  public newComment: string;

  constructor(private sanitizer: DomSanitizer, private api: ApiService) {}

  ngOnInit() {
  }

  getUserInitials(username: string) {
    const firstName = username.split(' ')[0];
    const lastName = username.split(' ')[1];

    return firstName.charAt(0) + lastName.charAt(0);
  }

  sanitizeComment(comment: string) {
    if (!comment) return;
    return this.sanitizer.bypassSecurityTrustHtml(comment);
  }

  addComment() {
    if (this.newComment == '') return;
    this.commentAdded.emit(this.newComment);
    this.newComment = '';
  }

  formatDate(dateStr: string): string {
    if (!dateStr) return '';
    const utcDateString = dateStr.endsWith('Z') ? dateStr : dateStr + 'Z';
    const date = new Date(utcDateString);
    return date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'    });
  }
}

@NgModule({
  imports: [
    RouterModule,
    MaterialModule,
    FormsModule,
    BrowserModule,
    MatToolTipModule,
  ],
  exports: [CommentsComponent],
  declarations: [CommentsComponent],
})
export class CommentsModule {}
