import { Component, OnInit } from '@angular/core';
import { Table, TableView } from '../../shared/services/api/table';
import { User } from '../../shared/data/data.generated';
import { ApiService } from '../../shared/services/api.service';
import { Router } from '@angular/router';
import { ApplicationFeature } from 'src/app/shared/data/application-feature';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { IconColor } from '@gea/digital-ui-lib';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

    constructor(private readonly api: ApiService, private router: Router, private loader: LoaderService) { }

    public canEdit: boolean = false;
    public displayedColumns: string[] = ['Name', 'Email', 'City', 'State', 'Country', 'OrderStatusFilters'];
    public table: Table<User> = new Table<User>(this.loader);

    ngOnInit(): void {
        this.table.GetApi = (listOptions: TableView) => this.api.Users.GetUsers(listOptions);
        this.table.RefreshData();
        this.canEdit = this.api.CanEditFeature(ApplicationFeature.Users);
        if (this.canEdit) this.displayedColumns.push("Actions");
    }

    new() {
        this.router.navigate(['/user-editor', { isNew: true }]);
    }

    edit(user: User) {
        this.router.navigate(['/user-editor', { isNew: false, id: user.Id }]);
    }

    delete(user: User) {
        if (confirm("Are you sure you want to delete this user?")) {
            this.api.Users.DeleteUser(user.Id)
                .subscribe(() => {
                    this.reload();
                 });
        }
    }

    reload() {
        this.table.RefreshData();
    }
}
