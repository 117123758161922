import { Observable } from 'rxjs';
import { ApiService } from "../api.service";
import { Feedback, FeedbackAttachment, FeedbackView, FeedbackViewResult, Item, OrderStatusItem } from '../../data/data.generated';
import { TableResult, TableView } from './table';

export class FeedbackApi {
    constructor(private _api: ApiService) {
    }

    public GetFeedbacks(listOptions: TableView): Observable<TableResult<FeedbackView>> {
        return this._api.Post("GetFeedbacks", listOptions);
    }

    public GetFeedbackView(id: number): Observable<FeedbackViewResult> {
        return this._api.Get<FeedbackViewResult>("GetFeedbackView", { Id: id });
    }

    public CreateFeedback(feedback: Feedback, files: File[]) {
        return this._api.Post("CreateFeedback", 
            {
                Object: JSON.stringify(feedback)
            }, files);
    }

    public UpdateFeedback(feedback: Feedback, files: File[]) {
        return this._api.Post("UpdateFeedback",
            {
                Object: JSON.stringify(feedback)
            }, files);
    }

    public DownloadFeedbackAttachment(attachment: FeedbackAttachment, finished: (err: string) => void) {

        let mimeType = 'application/octet-stream'; // Default to binary stream
        if (attachment.FileName.toLowerCase().endsWith('pdf')) {
        mimeType = 'application/pdf';
        } else if (attachment.FileName.toLowerCase().endsWith('png')) {
        mimeType = 'image/png';
        } else if (attachment.FileName.toLowerCase().endsWith('jpeg')) {
        mimeType = 'image/jpeg';
        } else if (attachment.FileName.toLowerCase().endsWith('jpg')) {
        mimeType = 'image/jpg';
        }

        this._api.Download("DownloadFeedbackAttachment",
            {
                Id: attachment.Id
            },
            attachment.FileName,
            mimeType,
            finished);
    }

    public DeleteFeedbackAttachment(attachmentId: number) {
        return this._api.Get<string>("DeleteFeedbackAttachment", {
            AttachmentId: attachmentId
        });
    }

    public UploadFeedbackAttachments(id: number, files: File[]) {
        return this._api.Post("UploadFeedbackAttachments",
            {
                Id: id
            }, files);
    }

    public AddFeedbackComment(id: number, text: string, commentType: string) {
        return this._api.Post("AddFeedbackComment",
            {
                Id: id,
                Text: text,
                CommentType : commentType
            });
    }
}