import { Observable } from "rxjs";
import { ApiService } from "../api.service";

export class Filters {
    constructor(private _api: ApiService) {
    }

    public GetAutocompleteList(searchText: string = '', field: string, pageName: string): Observable<any> {
        return this._api.Get<any>("GetAutocompleteList",
            {
                SearchText: searchText,
                SearchField: field,
                PageName: pageName
            });
    }

    public GetMultipleAutoLists(searchText: string = '', field: string, pageName: string): Observable<any> {
        return this._api.Get<any>("GetMultipleAutoLists",
            {
                SearchText: searchText,
                SearchField: field,
                PageName: pageName
            });
    }

}
