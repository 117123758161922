<form #feedbackForm="ngForm" name="feedbackForm" (ngSubmit)="submit()">
  <div class="parent-container">
    <div class="feedback-container">
      <div class="header-container">
        <div class="header-title-container">
          <img
            class="goBack-button"
            src="../assets/layout/arrow_back.svg"
            (click)="goBack()"
          />
          <mat-icon
            class="feedback-icon"
            [style]="getIconStyle(feedback.TypeId)"
            >{{ getIcon(feedback.TypeId) }}
          </mat-icon>
          <!-- <span class="header-title">{{ feedback.Title }}</span> -->
          <mat-form-field
            *ngIf="!previewMode"
            class="input-div"
            floatLabel="never"
          >
            <input
              matInput
              (input)="checkMaxLength($event, titleMaxLength, 'title')"
              [disabled]="previewMode"
              [(ngModel)]="feedback.Title"
              name="titleField"
              [placeholder]="feedback.Title === '' ? 'Enter a title...' : ''"
              #titleField="ngModel"
              required
            />
            <mat-hint
              *ngIf="titleField.invalid && titleField.touched"
              style="font-size: 10px; color: red"
              >Title is required.</mat-hint
            >
            <mat-hint
              *ngIf="isTitleMaxLengthExceeded"
              style="font-size: 10px; color: red"
              >Title cannot exceed 350 characters.</mat-hint
            >
          </mat-form-field>
          <div *ngIf="previewMode" class="input-div">{{ feedback.Title }}</div>
        </div>
        
        <div *ngIf="linkCopied" class="copy-text">
          <img
            style="vertical-align: middle"
            width="16"
            height="15"
            src="../../../assets/icons/Check_Circle.svg"
          />
          Link Copied
        </div>

        <div class="actions">
          <mat-icon *ngIf="previewMode" title="Copy URL" class="gea-icon" (click)="copyLink()"
            ><geaui-icon-v2
              file="16px_network-connection"
              [color]="'black'"
              [colorProp]="'stroke'"
            ></geaui-icon-v2
          ></mat-icon>

          <mat-icon
            *ngIf="previewMode"
            title="Bookmark"
            class="gea-icon"
            (click)="toggleBookmark()"
          >
            <geaui-icon-v2
              file="16px_bookmark"
              [color]="'black'"
              [colorProp]="isBookmarked ? 'fill' : 'stroke'"
            ></geaui-icon-v2
          ></mat-icon>

          <button
            *ngIf="canEdit && previewMode"
            mat-raised-button
            class="Button geaui-button-primary"
            style="margin-left: 20px"
            [disabled]="userType != '7000' && isEnvironment == 'test'"
            (click)="
              !(userType != '7000' && isEnvironment == 'test')
                ? togglePreviewMode()
                : false
            "
          >
            Edit
          </button>
        </div>
        <div class="update-actions" *ngIf="!previewMode">
          <button
            mat-raised-button
            *ngIf="canEdit || mode != 'edit'"
            class="Button geaui-button-primary"
            [disabled]="!feedbackForm.valid"
          >
            {{ mode == "edit" ? "Save" : "Add" }}
          </button>
          <button
            mat-raised-button
            (click)="cancel()"
            class="Button geaui-button-secondary"
          >
            Cancel
          </button>
        </div>
      </div>
      <div class="details-container">
        <h2>Details</h2>
        <div class="field">
          <label>ID</label>
          <div class="val">{{ feedback.Id }}</div>
        </div>
        <div class="field">
          <label>Created By</label>
          <div class="val">
            {{ feedback.UserName }}
          </div>
          <div class="val">
            {{
              mode == "edit"
                ? formatDate(feedback.Created)
                : currentDate.toLocaleDateString()
            }}
          </div>
        </div>
        <div class="field">
          <label>Feedback Type</label>
          <div *ngIf="previewMode" class="val">
            {{ getNameById(feedbackTypeDropdown, feedback.TypeId) }}
          </div>
          <mat-form-field
            *ngIf="!previewMode"
            class="dropdown"
            floatLabel="never"
          >
            <mat-select
              [(ngModel)]="feedback.TypeId"
              name="feedbackType"
              #feedbackTypeField="ngModel"
              required
              placeholder="Select Type"
            >
              <mat-option
                *ngFor="let item of feedbackTypeDropdown"
                [value]="item.Id"
              >
                {{ item.Name }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="feedbackTypeField.invalid && feedbackTypeField.touched"
            >
              Feedback type is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <label>App / Product</label>
          <div *ngIf="previewMode" class="val">
            {{
              getAppIds(feedback.ApplicationId)
            }}
          </div>
          <mat-form-field
            *ngIf="!previewMode"
            class="dropdown"
            floatLabel="never"
          >
            <mat-select
              [ngModel]="feedback.ApplicationIds"
              (ngModelChange)="setApplicationString($event)"
              name="applicationType"
              #applicationTypeField="ngModel"
              required
              multiple
              placeholder="Select App / Product"
              panelClass="custom-mat-select"
            >
              @for (group of applicationDropdown; track group) {
              <mat-optgroup [label]="group.name">
                @for (dropdownItem of group.dropdown; track dropdownItem) {
                <mat-option [value]="dropdownItem.Id">{{
                  dropdownItem.Name
                }}</mat-option>
                }
              </mat-optgroup>
              }
              <!-- <mat-option
                *ngFor="let item of applicationDropdown"
                [value]="item.Id"
              >
                {{ item.Name }}
              </mat-option> -->
            </mat-select>
            <mat-error
              *ngIf="
                applicationTypeField.invalid && applicationTypeField.touched
              "
            >
              App / Product is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field" *ngIf="feedback.ApplicationIds.includes(3)">
          <label>Omni Version</label>
          <div *ngIf="previewMode" class="val">{{ feedback.Version }}</div>
          <mat-form-field
            *ngIf="!previewMode"
            class="dropdown"
            floatLabel="never"
          >
            <mat-select
              [(ngModel)]="feedback.Version"
              name="OmniType"
              #omniTypeField="ngModel"
              required
              placeholder="Select Omni Version"
            >
              <mat-option *ngFor="let item of omniTypeDropdown" [value]="item">
                {{ item }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="omniTypeField.invalid && omniTypeField.touched">
              Omni Version is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <label>Company</label>
          <div *ngIf="previewMode" class="val">
            {{ feedback.CompanyNames }}
          </div>
          <mat-form-field
            *ngIf="!previewMode"
            class="dropdown"
            floatLabel="never"
          >
            <mat-select
              multiple
              [(ngModel)]="feedback.CompanyId"
              name="companyField"
              #companyField="ngModel"
              required
              placeholder="Select Company"
            >
              <mat-option
                *ngFor="let item of companyDropdown"
                [value]="item.Id"
              >
                {{ item.Name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="companyField.invalid && companyField.touched">
              Company is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <label>Priority</label>
          <div *ngIf="previewMode" class="val">
            {{ getNameById(priorityDropdown, feedback.PriorityId) }}
          </div>
          <mat-form-field
            *ngIf="!previewMode"
            class="dropdown"
            floatLabel="never"
          >
            <mat-select
              [(ngModel)]="feedback.PriorityId"
              name="priorityField"
              #priorityField="ngModel"
              required
              placeholder="Select Priority"
            >
              <mat-option
                *ngFor="let item of priorityDropdown"
                [value]="item.Id"
              >
                {{ item.Name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="priorityField.invalid && priorityField.touched">
              Priority is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <label>Status</label>
          <div *ngIf="previewMode" class="val">
            {{ getNameById(statusDropdown, feedback.StatusId) }}
          </div>
          <mat-form-field *ngIf="!previewMode" class="dropdown">
            <mat-select
              [(ngModel)]="feedback.StatusId"
              name="statusField"
              #statusField="ngModel"
              required
              [disabled]="disableFeature"
            >
              <mat-option *ngFor="let item of statusDropdown" [value]="item.Id">
                {{ item.Name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="field">
          <label for="jira-issue-id"
            >Jira Issue Id
            <a
              href="https://geagroup.atlassian.net/jira/software/c/projects/HRTC/issues/HRTC-{{
                feedback.Source
              }}"
              target="_blank"
              class="icon-button"
            >
              <span class="material.icons" title="View in Jira">
                <i class="material-icons">exit_to_app</i>
              </span>
            </a>
          </label>
          <div *ngIf="previewMode" class="val">{{ feedback.Source }}</div>
          <mat-form-field *ngIf="!previewMode" class="dropdown">
            <input
              matInput
              [(ngModel)]="feedback.Source"
              name="jiraTicketNumber"
              type="number"
              id="jira-issue-id"
              [disabled]="disableFeature"
            />
          </mat-form-field>
        </div>
        <div class="field" *ngIf="isInternal">
          <label>Internal Only</label>
          <div *ngIf="previewMode" class="val">
            {{ feedback.InternalOnly ? "Yes" : "No" }}
          </div>
          <mat-checkbox
            *ngIf="!previewMode"
            color="primary"
            [checked]="feedback.InternalOnly"
            (change)="feedback.InternalOnly = $event.checked"
          ></mat-checkbox>
        </div>
        <div class="field" *ngIf="isInternal">
          <label>PM Discussion</label>
          <div *ngIf="previewMode" class="val">
            {{ feedback.PmDiscussion ? "Yes" : "No" }}
          </div>
          <mat-checkbox
            *ngIf="!previewMode"
            color="primary"
            [checked]="feedback.PmDiscussion"
            (change)="feedback.PmDiscussion = $event.checked"
          ></mat-checkbox>
        </div>
        <div class="field" *ngIf="mode == 'edit'">
          <label>Modified By</label>
          <div class="val">
            {{ feedback.LastModifiedUserName }}
          </div>
          <div class="val">
            {{ formatDate(feedback.LastModified) }}
          </div>
        </div>
      </div>
      <div class="description-container">
        <h2>Description</h2>
        <!-- HTML Editor -->
        <textarea
          matInput
          required
          [disabled]="previewMode"
          class="description"
          placeholder="Enter a description..."
          name="description"
          [(ngModel)]="feedback.Text"
          #descriptionField="ngModel"
          (input)="checkMaxLength($event, descriptionMaxLength, 'description')"
        ></textarea>
      </div>
      <div class="errormessage-container" *ngIf="descriptionField.touched">
        <span *ngIf="descriptionField.invalid && descriptionField.touched"
          >Description is required.</span
        >
        <span *ngIf="isDescriptionMaxLengthExceeded"
          >Description cannot exceed 3500 characters.</span
        >
      </div>
      <div class="comments-container" *ngIf="mode == 'edit'; else addFeedback">
        <div class="comm-title">
          <h2>Comments</h2>
          <span class="count-bubble" [title]="comments.length">{{
            comments.length
          }}</span>
        </div>
        <div class="comment-content">
          <hrt-comment
            [comments]="comments"
            [commentType]="commentType"
            (commentAdded)="addComment($event)"
          ></hrt-comment>
        </div>
      </div>
      <ng-template #addFeedback>
        <div class="add-content">
          <!-- <button mat-raised-button class="Button geaui-button-primary">
          Add Feedback
        </button> -->
        </div>
      </ng-template>
      <div
        class="attachments-container"
        hrtDndFile
        (fileDropped)="onFileDropped($event)"
        [attr.data-message]="files.length > 0 ? 'Drop file to upload' : ''"
      >
        <div class="attach-title">
          <div class="title-content">
            <h2>Attachments</h2>
            <span class="count-bubble" [title]="files.length">{{
              files.length
            }}</span>
            <div class="download-msg" *ngIf="downloading">
              [Downloading...
              <mat-spinner diameter="20"></mat-spinner>]
            </div>
          </div>
          <div class="add-new-attach">
            <mat-icon
              (click)="fileInput.click()"
              [matTooltip]="'Upload File'"
              [matTooltipPosition]="'left'"
              >attach_file</mat-icon
            >
            <input
              #fileInput
              style="display: none"
              type="file"
              multiple
              accept="application/"
              class="FileDownload"
              (change)="onFileSelected($event)"
            />
          </div>
        </div>
        <div class="attachment-list">
          @if (files.length > 0) {
          <div *ngFor="let file of files; let i = index" class="attachment">
            <div class="attachment-info">
              <a
                class="uploaded-filename"
                [ngClass]="!file.id ? 'not-downloadable' : ''"
                (click)="downloadAttachment(file)"
                ><b>{{ file.name }}</b>
              </a>
              <span class="file-size">{{ formatFileSize(file.size) }}</span>
              <mat-icon
                *ngIf="canEdit"
                class="delete-file"
                (click)="deleteFile(i, file)"
                >delete</mat-icon
              >
            </div>
            <div class="file-created-date" *ngIf="file.id">
              Attached by: {{ file.userName }} on {{ formatDate(file.created) }}
            </div>
            <div *ngIf="!file.id && previewMode" class="file-created-date">
              Uploading attachment, please wait...
            </div>
          </div>
          } @else {
          <div class="upload-msg">
            <geaui-icon-v2
              file="16px_upload"
              [color]="'blue'"
              [colorProp]="'stroke'"
            ></geaui-icon-v2>
            <span>Drag & Drop Files here</span>
          </div>
          }
        </div>
      </div>
    </div>
  </div>
</form>
