import { Component } from '@angular/core';
import { TabData } from 'src/app/shared/components/hrt-tab/hrt-tab.component';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { FeedbackTableWrapperComponent } from './feedback-table-wrapper/feedback-table-wrapper.component';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent {

  selectedTabIndex: number;

  constructor(
    private utilityService: UtilityService
  ) {}

  feedbackTabs: TabData[] = [
    {
      tabName: 'All',
      tabComponent: FeedbackTableWrapperComponent,
      isTabVisible : true,
      data: {
        tableId: 'feedbackAllTable',
        tabNo: 0
      }
    },
    {
      tabName: 'My Items',
      tabComponent: FeedbackTableWrapperComponent,
      isTabVisible : true,
      data: {
        tableId: 'feedbackMyTable',
        tabNo: 1
      }
    },
    {
      tabName: 'GEA Omni',
      tabComponent: FeedbackTableWrapperComponent,
      isTabVisible : true,
      data: {
        tableId: 'feedbackOmniTable',
        tabNo: 2
      }
    },
    {
      tabName: 'RTSelect',
      tabComponent: FeedbackTableWrapperComponent,
      isTabVisible : true,
      data: {
        tableId: 'feedbackRTSelectTable',
        tabNo: 3
      }
    },
    {
      tabName: 'HRT Connect',
      tabComponent: FeedbackTableWrapperComponent,
      isTabVisible : true,
      data: {
        tableId: 'feedbackHRTConnectTable',
        tabNo: 4
      }
    },
    {
      tabName: 'Blu-Red Care',
      tabComponent: FeedbackTableWrapperComponent,
      isTabVisible : true,
      data: {
        tableId: 'feedbackBluRedCareTable',
        tabNo: 5
      }
    },
  ];

  ngOnInit() {
    this.selectedTabIndex = this.utilityService.getActiveTabIndex('feedbackActiveIndex');
  }
}
