<div class="dropdown-btn-container">
    <button
      class="dropdown-toggle"
      type="button"
      id="dropdownMenuButton"
      aria-expanded="false"
      (click)="toggleDropdown();"
    >
      {{dropdownLabel}}
      <mat-icon class="down-icon" [class.clicked]="showDropdown">arrow_drop_down</mat-icon>
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton" *ngIf="showDropdown" >
        <li *ngFor="let dropdown of dropdownOptions; let i = index" (click)="emitOption(dropdown)"><a>{{dropdown.label}}</a></li>
    </ul>
  </div>