import { Component, Input } from '@angular/core';
import { debounceTime } from 'rxjs';
import { Application, GetApplicationTypeText, GetAppList } from 'src/app/shared/data/application';
import { GetCompanyText } from 'src/app/shared/data/companies';
import { Feedback, FeedbackView } from 'src/app/shared/data/data.generated';
import { GetFeedbackPriorityText } from 'src/app/shared/data/feedback-priority';
import { FeedbackProduct, GetProductList, GetProductText } from 'src/app/shared/data/feedback-product';
import { GetFeedbackStatusText } from 'src/app/shared/data/feedback-status';
import { GetFeedbackTypeText } from 'src/app/shared/data/feedback-type';
import { GetFilterConfigKey, GetFilterConfigType } from 'src/app/shared/data/filterConfiguration';
import { ApiService } from 'src/app/shared/services/api.service';
import { Table, TableView } from 'src/app/shared/services/api/table';
import { FeedbackService } from 'src/app/shared/services/feedback.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'hrt-feedback-table',
  templateUrl: './feedback-table.component.html',
  styleUrl: './feedback-table.component.scss'
})
export class FeedbackTableComponent {

  public displayedColumns: string[] = [
    'ID',
    'Type',
    'Title',
    'Details',
    'CompanyName',
    'ApplicationName',
    'PriorityId',
    'StatusId',
    'UserName',
    'Created',
    'LastModified',
    'Notifications',
  ];


  listOptions: TableView = new TableView();
  feedbackItems: FeedbackView[] = [];
  feedbackCount: number = 0;
  feedbackTable: Table<Feedback> = new Table<Feedback>(this.loader);
  searchPlaceholder = 'Search Feedback';

  getApplicationText = GetApplicationTypeText;
  getCompanyText = GetCompanyText;
  getFeedbackPriorityText = GetFeedbackPriorityText;
  getFeedbackStatusText = GetFeedbackStatusText;
  getFeedbackTypeText = GetFeedbackTypeText;
  getProductText = GetProductText
  getProductListText = GetProductList;
  getAppList = GetAppList;

  tableId: string;

  @Input() set id(id: string) {
    this.tableId = id;
  }
  
  constructor(
    public feedbackService: FeedbackService,
    private loader: LoaderService,
    private api: ApiService,
    private utilityService: UtilityService
) {}

  ngOnInit(): void {
    this.feedbackTable.View.SortColumn = 'LastModified';
    this.feedbackTable.View.SortDesc = 'true';
    this.applyInitialProductFilter();
    this.feedbackTable.GetApi = (listOptions: TableView) =>
      this.api.Feedback.GetFeedbacks(listOptions);
    // this.feedbackTable.RefreshData();
    this.feedbackTable.inputSubject
      .pipe(debounceTime(500))
      .subscribe((value) => {
        this.feedbackTable.SearchUpdated();
      });
  }

  getAppIds(ids:string) {
    const apps = GetAppList(ids);
    const products = GetProductList(ids);
    return apps.concat(products).join(', ');
  }

  applyInitialProductFilter() {
     switch(this.tableId) {
        case 'feedbackMyTable':
          const userName = this.utilityService.getFromSession('FullName') || '';
          const userId = this.utilityService.getFromSession('UserId') || '';
          this.feedbackTable.addToFilter(GetFilterConfigKey('UserId'), [{key: userId, label: userName}], GetFilterConfigType('UserId'));
          break;
        case 'feedbackOmniTable':
          this.feedbackTable.addToFilter(GetFilterConfigKey('ApplicationId'), [{key: FeedbackProduct.GEAOmni, label: 'Omni'}], GetFilterConfigType('ApplicationId'));
          break;
        case 'feedbackHRTConnectTable':
          this.feedbackTable.addToFilter(GetFilterConfigKey('ApplicationId'), [{key: Application.HRTConnect, label: 'HRTConnect'}], GetFilterConfigType('ApplicationId'));
          break;
        case 'feedbackRTSelectTable':
          this.feedbackTable.addToFilter(GetFilterConfigKey('ApplicationId'), [{key: Application.RTSelect, label: 'RTSelect'}], GetFilterConfigType('ApplicationId'));
          break;
        case 'feedbackBluRedCareTable':
          this.feedbackTable.addToFilter(GetFilterConfigKey('ApplicationId'), [{key: Application.BluRedCare, label: 'Blu-Red Care'}], GetFilterConfigType('ApplicationId'));
          break;
     }
  }

  searchChange(searchText: string) {
    if (searchText.trim() !== this.feedbackTable.inputSubject.getValue() || searchText === '') {
      this.feedbackTable.inputSubject.next(searchText);
    }
  }

  applyFilters(filterEvent: any) {
    this.feedbackTable.View.Filters = {};
    this.applyInitialProductFilter();
    Object.keys(filterEvent).map((key: string) => {
      const filterKey = GetFilterConfigKey(key);
      const filterType = GetFilterConfigType(key);
      const values = filterEvent[key];
      if (values.length <= 0) return;
      // Convert from Type - selectedFilters to Table Filters
      this.feedbackTable.addToFilter(filterKey, values, filterType);
    });
    this.feedbackTable.SearchUpdated();
  }

}
