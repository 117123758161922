<div style="margin: 20px">
  <span style="font-size: 20px; font-weight: bold; vertical-align: top"
    >Inspection, Testing, Maintenance Records</span
  >
  <button
    *ngIf="isInternal"
    mat-raised-button
    class="geaui-button-primary"
    (click)="edit()"
    style="float: right"
  >
    Add Record
  </button>
</div>
<div style="margin: 20px" *ngIf="isEnvironment != 'local'">
  <table
    mat-table
    [dataSource]="table.Result.Data"
    matSort
    matSortDisableClear
    [matSortActive]="table.View.SortColumn"
    (matSortChange)="table.RefreshData($event)"
  >
    <ng-container matColumnDef="Date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
      <td mat-cell *matCellDef="let row">{{ formatDate(row.Date) }}</td>
    </ng-container>
    <ng-container matColumnDef="Type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
      <td mat-cell *matCellDef="let row">
        {{ getTypeDescription(row.TypeId) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Condition">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Condition</th>
      <td mat-cell *matCellDef="let row">
        <img
          [src]="getConditionImage(row.ConditionId)"
          height="24"
          width="24"
        />
      </td>
    </ng-container>
    <ng-container matColumnDef="C1">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>C1</th>
      <td mat-cell *matCellDef="let row">
        <img [src]="getConditionImage(row.C1Id)" height="24" width="24" />
      </td>
    </ng-container>
    <ng-container matColumnDef="C2">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>C2</th>
      <td mat-cell *matCellDef="let row">
        <img [src]="getConditionImage(row.C2Id)" height="24" width="24" />
      </td>
    </ng-container>
    <ng-container matColumnDef="C3">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>C3</th>
      <td mat-cell *matCellDef="let row">
        <img [src]="getConditionImage(row.C3Id)" height="24" width="24" />
      </td>
    </ng-container>
    <ng-container matColumnDef="C4">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>C4</th>
      <td mat-cell *matCellDef="let row">
        <img [src]="getConditionImage(row.C4Id)" height="24" width="24" />
      </td>
    </ng-container>
    <ng-container matColumnDef="M1">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>M1</th>
      <td mat-cell *matCellDef="let row">
        <img [src]="getConditionImage(row.M1Id)" height="24" width="24" />
      </td>
    </ng-container>
    <ng-container matColumnDef="M2">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>M2</th>
      <td mat-cell *matCellDef="let row">
        <img [src]="getConditionImage(row.M2Id)" height="24" width="24" />
      </td>
    </ng-container>
    <ng-container matColumnDef="Technician">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Technician</th>
      <td mat-cell *matCellDef="let row">{{ row.Technician }}</td>
    </ng-container>
    <ng-container matColumnDef="Notes">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Notes</th>
      <td mat-cell *matCellDef="let row">
        <img
          [src]="infoImage"
          height="24"
          width="24"
          style="cursor: pointer"
          (click)="showNotes(row)"
        />
      </td>
    </ng-container>
    <ng-container matColumnDef="Report">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Report</th>
      <td mat-cell *matCellDef="let row">
        <a
          *ngIf="row.HasReport"
          [href]="
            'https://fesportal.azurewebsites.net/RepairReports/' +
            fixUrl(row.ReportLocation)
          "
          target="_blank"
        >
          <img
            [src]="pdfImage"
            height="24"
            width="24"
            style="cursor: pointer"
            [matTooltip]="row.ReportLocation"
          />
        </a>
        <img
          *ngIf="row.HasStorageReport"
          [src]="pdfImage"
          height="24"
          width="24"
          style="cursor: pointer"
          (click)="downloadStorageReport(row)"
        />
      </td>
    </ng-container>
    <ng-container matColumnDef="Options">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
      <td mat-cell *matCellDef="let row">
        <img
          [src]="editImage"
          height="24"
          width="24"
          style="cursor: pointer"
          (click)="edit(row)"
        />
        <img
          [src]="deleteImage"
          height="24"
          width="24"
          style="cursor: pointer"
          (click)="delete(row)"
        />
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    [length]="table.Result.Count"
    [pageSizeOptions]="table.View.PageSizeOptions"
    [pageSize]="table.View.PageSize"
    [pageIndex]="table.View.Page"
    (page)="table.RefreshData($event)"
  >
  </mat-paginator>
  <dx-popup
    [width]="660"
    minHeight="100"
    height="auto"
    [showTitle]="false"
    [dragEnabled]="false"
    [hideOnOutsideClick]="true"
    [(visible)]="notesDialog"
  >
    <div *dxTemplate="let data of 'content'">
      <mat-card>
        <mat-card-title>
          Visit Notes for {{ formatDate(notesItem.Date) }} -
          {{ notesItem.Technician }}
        </mat-card-title>
        <mat-card-content>
          {{ notesItem.VisitNotes ? notesItem.VisitNotes : "N/A" }}
        </mat-card-content>
        <mat-card-title>
          Service Notes for {{ getTypeDescription(notesItem.TypeId) }}
        </mat-card-title>
        <mat-card-content>
          {{ notesItem.ServiceNotes ? notesItem.ServiceNotes : "N/A" }}
        </mat-card-content>
      </mat-card>
    </div>
  </dx-popup>
</div>
<hrt-table
  *ngIf="isEnvironment == 'local'"
  [tableId]="tableId"
  [columnDefinitions]="columnDefinitions"
  [tableData]="tableData"
  [totalCount]="totalCount"
  (searchText)="updateSearchTerm($event)"
></hrt-table>
