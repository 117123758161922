<table>
    <tr>
        <td>Type:</td>
        <td>
            <mat-form-field appearance="fill" style="width: 500px">
                <mat-select [(ngModel)]="selectedType">
                    <mat-option *ngFor="let type of availableTypes" [value]="type.value">
                        {{type.text}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </td>
    </tr>
    <tr *ngIf="selectedType === withControlPanel">
        <td>
            Control Panel:
        </td>
        <td>
            <mat-form-field appearance="fill" style="width: 500px">
                <mat-select [(ngModel)]="selectedControlPanel">
                    <mat-option *ngFor="let type of availableControlPanels" [value]="type.value">
                        {{type.text}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </td>
    </tr>
    <tr>
        <td>
            Mounting Kit:
        </td>
        <td>
            <mat-form-field appearance="fill" style="width: 500px">
                <mat-select [(ngModel)]="selectedMountingKit" disabled="true">
                    <mat-option *ngFor="let type of availableMountingKits" [value]="type.value">
                        {{type.text}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </td>
    </tr>
    <tr>
        <td>
            Compressor:
        </td>
        <td style="height: 60px;">
            <span style="display: inline-block; width: 500px; margin-right: 10px;">
                <img src="../assets/product-icons/screw_vibrantBlue.svg" style="width: 45px; margin-right: 10px;">
                <span style="display: inline-block; word-wrap: break-word;  max-width: 350px;">
                    {{ selectedCompressorDescription }}
                </span>
                <button mat-raised-button color="primary" (click)="selectCompressor()" style="float:right;">Select</button>
            </span>
            <asset-finder [Show]="showAssetFinder" [AssetType]="101" (SelectedAsset)="compressorSelected($event)"></asset-finder>
        </td>
    </tr>
</table>