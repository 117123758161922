<div class="asset-analysis-container">
    <mat-card class="analysis-card">
      <mat-card-title>
        Blu-Red Care
        <img
          src="../assets/dashboard/BluRedCareWhite.png"
          class="custom-svg blu-red-icon"
        />
      </mat-card-title>
      <mat-card-subtitle class="subtitle-text" *ngIf="analysisStage === 1">
        Utilize GEA's expertise to enable faster troubleshooting, help proactively
        prevent problems and boost availability and safety of your equipment.
      </mat-card-subtitle>
      <!-- <button mat-raised-button *ngIf="analysisStage === 1" class="analyze-btn" color="secondary" (click)="analyze()">
            <mat-icon>search</mat-icon>
            <span class="analyze-text">Analyze</span>
        </button> -->
      <!-- <mat-card-subtitle *ngIf="analysisStage === 2">
            Analyzing, please wait...
        </mat-card-subtitle>
        <mat-progress-bar *ngIf="analysisStage === 2" [value]="percentAnalysis" style="height: 30px;"></mat-progress-bar>
        <mat-card-subtitle *ngIf="analysisStage === 3">
            Analysis complete.
        </mat-card-subtitle>
        <mat-card-subtitle *ngIf="analysisStage === 3">
            <span style="vertical-align: middle;">
                <img [src]="warningImage" height="32" width="32" />
            </span>
            <span style="vertical-align: middle;">
                Oil Filter needs replaced ....
            </span>
        </mat-card-subtitle>
        <mat-card-subtitle *ngIf="analysisStage === 3">
            <span style="vertical-align: middle; margin-left: 20px;">
                <mat-icon>build</mat-icon>
            </span>
            <span style="vertical-align: middle;">
                Part Number: 010-000540-000
            </span>
            <span style="vertical-align: middle; margin-left: 10px;">
                <button mat-raised-button color="primary">
                    <mat-icon>add_shopping_cart</mat-icon>
                    Order Now...
                </button>
            </span>
        </mat-card-subtitle> -->
    </mat-card>

</div>
