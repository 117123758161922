import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  globalConfigData: ConfigData;
  configData = new BehaviorSubject<any>(undefined);

  constructor() { }

  addToConfig(data: ConfigData) {
    Object.keys(data).map(key => {
      if (!this.globalConfigData[key]) {
        this.globalConfigData[key] = data[key];
      }
    })
  }

  updateConfig(newData: ConfigData) {
    this.globalConfigData = newData;
    this.configData.next(this.globalConfigData);
  }
}

interface ConfigData {
  [key: string]: any;
}
