<!-- https://storybook.internal.apps.gea.com/?path=/docs/components-basic-components-input--docs -->
<!-- 
<table>
    <tr>
        <td colspan="4" class="gea-font-heading-24">
            Input Parameters
        </td>
    </tr>
    <tr>
        <td class="DataColumn">
            <geaui-select-v2
                [label]="'Manufacturer'"
                [id]="'manufacturer'"
                [options]="[{value: 1, name: 'Value1'}, {value: 2, name: 'Value 2'}, {value: 3, name: 'Value 3'}]"
                (selectChanged)="test($event)"
                ></geaui-select-v2>
        </td>
        <td></td>
        <td class="DataColumn">
            Calculation Mode
            <geaui-radio-group-v2
                [flexDirection]="'row'"
                [options]="[{value: 1, name: 'Auto'}, {value: 2, name: 'Manual'}]">
            </geaui-radio-group-v2>
        </td>
        <td></td>
    </tr>
    <tr>
        <td class="DataColumn">
            <geaui-select-v2
                [label]="'Vessel Design'"
                [id]="'vesselDesign'"
                [options]="[{value: 1, name: 'Value1'}, {value: 2, name: 'Value 2'}, {value: 3, name: 'Value 3'}]"
                (selectChanged)="test($event)"
                ></geaui-select-v2>
        </td>
        <td></td>
        <td class="DataColumn">
            Compressor Application
            <geaui-radio-group-v2
                [flexDirection]="'row'"
                [options]="[{value: 1, name: 'High Stage'}, {value: 2, name: 'Booster'}]">
            </geaui-radio-group-v2>
        </td>
        <td></td>
    </tr>
    <tr>
        <td class="DataColumn">
            <geaui-select-v2
                [label]="'Refrigerant'"
                [id]="'refrigerant'"
                [options]="[{value: 1, name: 'Value1'}, {value: 2, name: 'Value 2'}, {value: 3, name: 'Value 3'}]"
                (selectChanged)="test($event)"
                ></geaui-select-v2>
        </td>
        <td></td>
        <td class="DataColumn">
            <geaui-select-v2
                [label]="'Oil Type'"
                [id]="'oilType'"
                [options]="[{value: 1, name: 'Value1'}, {value: 2, name: 'Value 2'}, {value: 3, name: 'Value 3'}]"
                (selectChanged)="test($event)"
                ></geaui-select-v2>
        </td>
        <td></td>
    </tr>
    <tr>
        <td colspan="2" class="gea-font-heading-24">
            Design Condensing Temp
        </td>
        <td colspan="2" class="gea-font-heading-24">
            Minimum Condensing Temp
        </td>
    </tr>
    <tr>
        <td class="DataColumn">
            <geaui-input-v2
                [label]="'Condensing Temp'"
                [id]="'designCondensingTemp'"
                [required]="true"
                type="number"
                (valueChanged)="test($event)"
            ></geaui-input-v2>
        </td>
        <td>°F</td>
        <td class="DataColumn">
            <geaui-input-v2
                [label]="'Condensing Temp'"
                [id]="'minCondensingTemp'"
                [required]="true"
                type="number"
                (valueChanged)="test($event)"
            ></geaui-input-v2>
        </td>
        <td>°F</td>
    </tr>
    <tr>
        <td class="DataColumn">
            <geaui-input-v2
                [label]="'Discharge Temp'"
                [id]="'designDischargeTemp'"
                [required]="true"
                type="number"
                (valueChanged)="test($event)"
            ></geaui-input-v2>
        </td>
        <td>°F</td>
        <td class="DataColumn">
            <geaui-input-v2
                [label]="'Discharge Temp'"
                [id]="'minDischargeTemp'"
                [required]="true"
                type="number"
                (valueChanged)="test($event)"
            ></geaui-input-v2>
        </td>
        <td>°F</td>
    </tr>
    <tr>
        <td class="DataColumn">
            <geaui-input-v2
                [label]="'Discharge Pressure'"
                [id]="'designDischargePressure'"
                [required]="true"
                type="number"
                (valueChanged)="test($event)"
            ></geaui-input-v2>
        </td>
        <td>psia</td>
        <td class="DataColumn">
            <geaui-input-v2
                [label]="'Discharge Pressure'"
                [id]="'minDischargePressure'"
                [required]="true"
                type="number"
                (valueChanged)="test($event)"
            ></geaui-input-v2>
        </td>
        <td>psia</td>
    </tr>
    <tr>
        <td class="DataColumn">
            <geaui-input-v2
                [label]="'Discharge Mass Flow'"
                [id]="'designDischargeMassFlow'"
                [required]="true"
                type="number"
                (valueChanged)="test($event)"
            ></geaui-input-v2>
        </td>
        <td>lbs / min</td>
        <td class="DataColumn">
            <geaui-input-v2
                [label]="'Discharge Mass Flow'"
                [id]="'minDischargeMassFlow'"
                [required]="true"
                type="number"
                (valueChanged)="test($event)"
            ></geaui-input-v2>
        </td>
        <td>lbs / min</td>
    </tr>
    <tr>
        <td colspan="4" class="gea-font-heading-24">
            Results
        </td>
    </tr>
    <tr>
        <td colspan="4" class="gea-font-heading-24">
            <mat-form-field appearance="fill" color="warn">
                <mat-label>Fill</mat-label>
                <input matInput />
            </mat-form-field>
        </td>
    </tr>
</table> -->
<table>
    <tr>
        <td colspan="4" class="gea-font-heading-24">
            Input Parameters
        </td>
    </tr>
    <tr>
        <td class="LabelColumn">Manufacturer</td>
        <td class="DataColumn">
            <mat-form-field class="DataControl">
                <mat-select [(ngModel)]="manufacturer">
                </mat-select>
            </mat-form-field>
        </td>
        <td></td>

        <td class="LabelColumn">Calculation Mode</td>
        <td class="DataColumn">
            <mat-radio-group class="DataControl">
                <mat-radio-button [value]="true">Auto</mat-radio-button>
                <mat-radio-button [value]="false">Manual</mat-radio-button>
            </mat-radio-group>
        </td>
        <td></td>
    </tr>
    <tr>
        <td class="LabelColumn">Vessel Design</td>
        <td class="DataColumn">
            <mat-form-field class="DataControl">
                <mat-select>
                </mat-select>
            </mat-form-field>
        </td>
        <td></td>
        
        <td class="LabelColumn">Compressor Application</td>
        <td class="DataColumn">
            <mat-radio-group class="DataControl">
                <mat-radio-button [value]="true">High Stage</mat-radio-button>
                <mat-radio-button [value]="false">Booster</mat-radio-button>
            </mat-radio-group>
        </td>
        <td></td>
    </tr>
    <tr>
        <td class="LabelColumn">Refrigerant</td>
        <td class="DataColumn">
            <mat-form-field class="DataControl">
                <mat-select>
                </mat-select>
            </mat-form-field>
        </td>
        <td></td>

        <td class="LabelColumn">Oil Type</td>
        <td class="DataColumn">
            <mat-form-field class="DataControl">
                <mat-select>
                </mat-select>
            </mat-form-field>
        </td>
        <td></td>
    </tr>

    <tr>
        <td colspan="3" class="gea-font-heading-24">
            Design Condensing Temp
        </td>
        <td colspan="3" class="gea-font-heading-24">
            Minimum Condensing Temp
        </td>
    </tr>
    <tr>
        <td class="LabelColumn">Condensing Temp</td>
        <td class="DataColumn">
            <mat-form-field class="DataControl">
                <input matInput [(ngModel)]="designCondensingTemp" />
            </mat-form-field>
        </td>
        <td>°F</td>

        <td class="LabelColumn">Condensing Temp</td>
        <td class="DataColumn">
            <mat-form-field class="DataControl">
                <input matInput [(ngModel)]="minCondensingTemp" />
            </mat-form-field>
        </td>
        <td>°F</td>
    </tr>
    <tr>
        <td class="LabelColumn">Discharge Temp</td>
        <td class="DataColumn">
            <mat-form-field class="DataControl">
                <input matInput [(ngModel)]="designDischargeTemp" />
            </mat-form-field>
        </td>
        <td>°F</td>

        <td class="LabelColumn">Discharge Temp</td>
        <td class="DataColumn">
            <mat-form-field class="DataControl">
                <input matInput [(ngModel)]="minDischargeTemp" />
            </mat-form-field>
        </td>
        <td>°F</td>
    </tr>
    <tr>
        <td class="LabelColumn">Discharge Pressure</td>
        <td class="DataColumn">
            <mat-form-field class="DataControl">
                <input matInput [(ngModel)]="designDischargePressure" />
            </mat-form-field>
        </td>
        <td>psia</td>

        <td class="LabelColumn">Discharge Pressure</td>
        <td class="DataColumn">
            <mat-form-field class="DataControl">
                <input matInput [(ngModel)]="minDischargePressure" />
            </mat-form-field>
        </td>
        <td>psia</td>
    </tr>
    <tr>
        <td class="LabelColumn">Discharge Mass Flow</td>
        <td class="DataColumn">
            <mat-form-field class="DataControl">
                <input matInput [(ngModel)]="designDischargeMassFlow" />
            </mat-form-field>
        </td>
        <td>lbs / min</td>

        <td class="LabelColumn">Discharge Mass Flow</td>
        <td class="DataColumn">
            <mat-form-field class="DataControl">
                <input matInput [(ngModel)]="minDischargeMassFlow" />
            </mat-form-field>
        </td>
        <td>lbs / min</td>
    </tr>

    <tr>
        <td colspan="4" class="gea-font-heading-24">
            Results
        </td>
    </tr>
    <tr>
        <td colspan="4" class="gea-font-heading-24">
            output table here
        </td>
    </tr>
</table>
