import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { setup as intelliSocSetup } from 'src/app/products/intellisoc-retrofit/setup';
import { setup as oilSeparatorSetup } from 'src/app/products/oil-separator-calc/setup';
import { ProductSetup } from 'src/app/products/product-setup';
import { ProductType } from 'src/app/shared/data/product-types';

@Component({
    selector: 'app-product-editor',
    templateUrl: './product-editor.component.html',
    styleUrls: ['./product-editor.component.scss']
})
export class ProductEditorComponent implements OnInit {

    constructor(private route: ActivatedRoute) { 

    }

    public currentProductType: ProductType = ProductType.Generic;
    public setup: ProductSetup = new ProductSetup();

    public showInfo: boolean = false;
    public showAssetBank: boolean = false;
    public showWarnings: boolean = false;
    public showErrors: boolean = false;
    public showReports: boolean = false;
    public showTco: boolean = false;
    public showPricing: boolean = false;

    public ProductType = ProductType;

    ngOnInit(): void {
        this.route.params.subscribe((p: any) => {
            this.currentProductType = <ProductType>p['productType'];
            switch (this.currentProductType)
            {
                case ProductType.IntelliSocRetro:
                    this.setup = intelliSocSetup;
                    break;
                case ProductType.OilSeparator:
                    this.setup = oilSeparatorSetup
                    break;
            }
        });
    }

}