import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConversionService {
  tempUnitTypes = {
    Farenheit: 'F',
    Celsius: 'C',
    Kelvin: 'K',
  };
  pressureUnitTypes = {
    bar: 'bar',
    barA: 'barA',
    barG: 'barG',
    Psi: 'Psi',
    Psia: 'Psia',
    kpa: 'kpa',
    KgCm2a: 'KgCm2a',
    KgCm2: 'KgCm2',
  };

  constructor() {}

  celsiusToFarenheit(tempCelsius: number) {
    return (tempCelsius * 9.0) / 5.0 + 32;
  }

  farenheitToCelsius(tempFarenheit: number) {
    return ((tempFarenheit - 32) * 5.0) / 9.0;
  }

  convertTemperature(value: number, fromUnit: string, toUnit: string) {
    let result = -999; // Default value for invalid conversions

    switch (fromUnit) {
      case this.tempUnitTypes.Farenheit:
        switch (toUnit) {
          case this.tempUnitTypes.Celsius:
            result = (value - 32) / 1.8;
            break;
          case this.tempUnitTypes.Kelvin:
            result = (value - 32) / 1.8 + 273.15;
            break;
          default:
            break;
        }
        break;
      case this.tempUnitTypes.Celsius:
        switch (toUnit) {
          case this.tempUnitTypes.Farenheit:
            result = value * 1.8 + 32;
            break;
          case this.tempUnitTypes.Kelvin:
            result = value + 273.15;
            break;
          default:
            break;
        }
        break;
      case this.tempUnitTypes.Kelvin:
        switch (toUnit) {
          case this.tempUnitTypes.Farenheit:
            result = (value - 273.15) * 1.8 + 32;
            break;
          case this.tempUnitTypes.Celsius:
            result = value - 273.15;
            break;
          case 'Rankine':
            result = value / 1.8;
            break;
          default:
            break;
        }
        break;
      case 'Rankine':
        switch (toUnit) {
          case this.tempUnitTypes.Kelvin:
            result = value * 1.8;
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
    return result;
  }

  convertPressure(value: number, fromUnit: string, toUnit: string) {
    switch (fromUnit) {
      case this.pressureUnitTypes.barA:
      case this.pressureUnitTypes.bar:
        switch (toUnit) {
          case this.pressureUnitTypes.Psi:
            return value * 14.5037738;

          case this.pressureUnitTypes.kpa:
            return value * 100;

          case this.pressureUnitTypes.KgCm2a:
          case this.pressureUnitTypes.KgCm2:
            return value * 1.019716;

          case this.pressureUnitTypes.Psia:
            return value * 14.50377;

          case this.pressureUnitTypes.barA:
          case this.pressureUnitTypes.bar:
            return value;

          case this.pressureUnitTypes.barG:
            return value - 1;

          default:
            return -999;
        }
      default:
        return -999;
    }
  }
}
