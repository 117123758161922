import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { HrtBookmarkComponent } from 'src/app/shared/components/hrt-bookmark/hrt-bookmark.component';
import { ApiService } from 'src/app/shared/services/api.service';
import { LoaderService } from 'src/app/shared/services/loader.service';

@Component({
  selector: 'gea-document-details',
  templateUrl: './document-details.component.html',
  styleUrl: './document-details.component.scss',
})
export class DocumentDetailsComponent {
  @Input() selectedDoc: any;
  @Output() exitDetails = new EventEmitter();

  id: number;
  downloading = false;
  public url: any;
  public bookmarkName: any;
  public bookmarkType: string = 'Document';
  public isBookmark: boolean;
  public link: any;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private loader: LoaderService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.route.params.subscribe((p: any) => {
      this.id = p['id'];
      this.loader.showLoadingIcon();
      this.api.Documents.GetDocumentDetails(this.id).subscribe({
        next: (x) => {
          this.selectedDoc = x;
          this.loader.hideLoadingIcon();
        },
        error: (error) => {
          console.log(error);
          this.loader.hideLoadingIcon();
        },
      });
      const userBookmarks = JSON.parse(
        sessionStorage.getItem('UserBookmarks') ?? ''
      );
      for (let i = 0; i < userBookmarks.length; i++) {
        if (
          userBookmarks[i].ForeignKey == this.id &&
          userBookmarks[i].BookmarkType == this.bookmarkType
        ) {
          this.isBookmark = !this.isBookmark;
        }
      }
    });
  }

  toggleBookmark(isBookmarked: boolean){
    if(!isBookmarked){
      this.url = window.location.href;
      this.bookmarkName = this.selectedDoc?.Title ;
      const dialogRef = this.dialog.open(HrtBookmarkComponent, {
        data: { url: this.url, bookmarkName: this.bookmarkName },
      });

      dialogRef.afterClosed().subscribe((result) => {
        this.bookmarkName = result;
        if(result) {
          this.api.Bookmark.AddBookmark(this.id, this.bookmarkType,this.url, this.bookmarkName).subscribe({
            next: (x) => {
              this.isBookmark = !this.isBookmark;
          },
          });
          let existingBookmarks = JSON.parse(sessionStorage.getItem('UserBookmarks') || '')
          existingBookmarks.push({ForeignKey: this.id, BookmarkType: this.bookmarkType})
          sessionStorage.setItem('UserBookmarks',JSON.stringify(existingBookmarks))
        }
      });
    } else {
      this.api.Bookmark.DeleteBookmark(this.id, this.bookmarkType).subscribe({
        next: (x) => {
          this.isBookmark = !this.isBookmark;
      },
      });
      let existingBookmarks = JSON.parse(sessionStorage.getItem('UserBookmarks') || '');
      existingBookmarks = existingBookmarks.filter((bookmark: { ForeignKey: number; BookmarkType: string; }) => !(bookmark.ForeignKey === this.id && bookmark.BookmarkType ===this.bookmarkType))
      sessionStorage.setItem('UserBookmarks',JSON.stringify(existingBookmarks));
    }
  }

  downloadDocument(doc: Document) {
    if (this.downloading) return;
    this.downloading = true;
    this.api.Documents.DownloadDocument(this.selectedDoc, (err: string) => {
      this.downloading = false;
      if (err) {
        //this.snackBar.open(err, undefined, {
        //    duration: 5000,
        //});
      }
    });
  }
}
