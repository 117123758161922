import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ImagesService } from 'src/app/shared/services/images.service';

@Component({
  selector: 'asset-annunciation-data',
  templateUrl: './asset-annunciation-data.component.html',
  styleUrls: ['./asset-annunciation-data.component.scss']
})
export class AssetAnnunciationDataComponent implements OnInit, AfterViewInit  {

  constructor(private images: ImagesService) { 
    this.dataSource = new MatTableDataSource(this.dummyData);
  }

  @Input() public assetId: number = -1;

  public displayedColumns: string[] = ["Type", "Description", "Parameter", "Action", "Clear"];
  
  public dataSource: MatTableDataSource<AnnunciationData>;
  public dummyData: AnnunciationData[] = [
    {
      "Type": "Warning",
      "Description": "Surge Drum High Level Warning",
      "Time": "4/11/2018 2:45:15 PM",
      "Parameter": "",
      "Action": "Cleared",
      "Clear": "Manual"
    },
    {
      "Type": "Warning",
      "Description": "Flash Receiver Low Level Warning",
      "Time": "4/11/2018 2:45:15 PM",
      "Parameter": "",
      "Action": "Cleared",
      "Clear": "Manual"
    },
    {
      "Type": "Warning",
      "Description": "Flash Receiver Low Level Warning",
      "Time": "4/11/2018 2:45:15 PM",
      "Parameter": "",
      "Action": "Issued",
      "Clear": ""
    },
    {
      "Type": "Shutdown",
      "Description": "Surge Drum High Level Shutdown",
      "Time": "4/11/2018 2:45:15 PM",
      "Parameter": "",
      "Action": "Cleared",
      "Clear": "Manual"
    },
    {
      "Type": "Shutdown",
      "Description": "Surge Drum High Level Shutdown",
      "Time": "4/11/2018 2:40:01 PM",
      "Parameter": "",
      "Action": "Issued",
      "Clear": ""
    },
    {
      "Type": "Warning",
      "Description": "Surge Drum High Level Warning",
      "Time": "4/11/2018 2:39:43 PM",
      "Parameter": "",
      "Action": "Issued",
      "Clear": ""
    },
    {
      "Type": "Warning",
      "Description": "Flash Receiver Low Level Warning",
      "Time": "4/11/2018 2:18:18 PM",
      "Parameter": "",
      "Action": "Issued",
      "Clear": ""
    },
    {
      "Type": "Warning",
      "Description": "Flash Receiver Low Level Warning",
      "Time": "4/11/2018 2:18:17 PM",
      "Parameter": "",
      "Action": "Cleared",
      "Clear": "Auto"
    },
    {
      "Type": "Warning",
      "Description": "Flash Receiver Low Level Warning",
      "Time": "4/11/2018 1:25:50 PM",
      "Parameter": "",
      "Action": "Issued",
      "Clear": ""
    },
    {
      "Type": "Warning",
      "Description": "Flash Receiver High Level Float Warning",
      "Time": "4/11/2018 1:16:27 PM",
      "Parameter": "",
      "Action": "Cleared",
      "Clear": "Manual"
    },
    {
      "Type": "Warning",
      "Description": "Flash Receiver High Level Float Warning",
      "Time": "4/11/2018 12:50:07 PM",
      "Parameter": "",
      "Action": "Issued",
      "Clear": ""
    },
    {
      "Type": "Warning",
      "Description": "Flash Receiver High Level Float Warning",
      "Time": "4/11/2018 12:47:40 PM",
      "Parameter": "",
      "Action": "Cleared",
      "Clear": "Auto"
    },
    {
      "Type": "Warning",
      "Description": "Surge Drum High Level Warning",
      "Time": "4/11/2018 12:24:18 PM",
      "Parameter": "",
      "Action": "Cleared",
      "Clear": "Manual"
    },
    {
      "Type": "Shutdown",
      "Description": "Surge Drum High Level Shutdown",
      "Time": "4/11/2018 12:24:18 PM",
      "Parameter": "",
      "Action": "Cleared",
      "Clear": "Manual"
    },
    {
      "Type": "Warning",
      "Description": "Low Suction Pressure",
      "Time": "4/11/2018 12:23:51 PM",
      "Parameter": "1.31 barA",
      "Action": "Cleared",
      "Clear": "Auto"
    },
    {
      "Type": "Warning",
      "Description": "Low Suction Pressure",
      "Time": "4/11/2018 12:23:45 PM",
      "Parameter": "1.31 barA",
      "Action": "Issued",
      "Clear": ""
    },
    {
      "Type": "Warning",
      "Description": "Low Suction Pressure",
      "Time": "4/11/2018 12:21:35 PM",
      "Parameter": "1.31 barA",
      "Action": "Cleared",
      "Clear": "Auto"
    },
    {
      "Type": "Warning",
      "Description": "Low Suction Pressure",
      "Time": "4/11/2018 12:21:32 PM",
      "Parameter": "1.31 barA",
      "Action": "Issued",
      "Clear": ""
    },
    {
      "Type": "Warning",
      "Description": "Low Suction Pressure",
      "Time": "4/11/2018 12:18:41 PM",
      "Parameter": "1.31 barA",
      "Action": "Cleared",
      "Clear": "Auto"
    },
    {
      "Type": "Warning",
      "Description": "Low Suction Pressure",
      "Time": "4/11/2018 12:18:35 PM",
      "Parameter": "1.31 barA",
      "Action": "Issued",
      "Clear": ""
    },
    {
      "Type": "Warning",
      "Description": "Flash Receiver High Level Float Warning",
      "Time": "4/11/2018 11:20:30 AM",
      "Parameter": "",
      "Action": "Issued",
      "Clear": ""
    },
    {
      "Type": "Warning",
      "Description": "Flash Receiver Low Level Warning",
      "Time": "4/11/2018 11:07:48 AM",
      "Parameter": "",
      "Action": "Cleared",
      "Clear": "Auto"
    },
    {
      "Type": "Shutdown",
      "Description": "Surge Drum High Level Shutdown",
      "Time": "4/11/2018 10:51:44 AM",
      "Parameter": "",
      "Action": "Issued",
      "Clear": ""
    },
    {
      "Type": "Warning",
      "Description": "Surge Drum High Level Warning",
      "Time": "4/11/2018 10:35:28 AM",
      "Parameter": "",
      "Action": "Issued",
      "Clear": ""
    }
  ];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  ngOnInit(): void { 
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getTypeIcon(type: string) {
    if (type === "Warning") return this.images.Icons.Warning;
    if (type === "Shutdown") return this.images.Icons.Error;
    return this.images.Icons.NotAvailable;
  }
}

class AnnunciationData {
  public Type: string = "";
  public Description: string = "";
  public Time: string = "";
  public Parameter: string = "";
  public Action: string = "";
  public Clear: string = "";
}