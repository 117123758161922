import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { response } from 'express';
import { Applications } from 'src/app/shared/data/application';
import {
  CanEditByDefault,
  CanViewByDefault,
  FeatureHasEdit,
  Features,
} from 'src/app/shared/data/application-feature';
import { Companies } from 'src/app/shared/data/companies';
import { User, UserNotification, UserView } from 'src/app/shared/data/data.generated';
import { UserNotificationTypes } from 'src/app/shared/data/user-notification-type';
import { ApiService } from 'src/app/shared/services/api.service';
import { LoaderService } from 'src/app/shared/services/loader.service';

@Component({
  selector: 'user-editor',
  templateUrl: './user-editor.component.html',
  styleUrls: ['./user-editor.component.scss'],
})
export class UserEditorComponent implements OnInit {
  constructor(
    private readonly api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private loader: LoaderService
  ) {}

  public isNew: boolean = true;
  public id: number = 0;
  public user: UserView = new UserView();
  public isSaving: boolean = false;

  public permissions: any[] = [];
  public notifications: UserNotification[] = [];

  public userNotificationTypes = UserNotificationTypes;
  public companies = Companies;
  public applications = Applications;

  public FeatureHasEdit = FeatureHasEdit;

  ngOnInit(): void {
    this.route.params.subscribe((p: any) => {
      this.isNew = p['isNew'].toLowerCase() == 'true';
      this.id = Number.parseFloat(p['id']);
      if (!this.isNew) {
        this.loader.showLoadingIcon();
        this.api.Users.GetUserDetails(this.id).subscribe((user) => {
          this.user = user;
          this.api.Users.GetUserNotifications(this.id).subscribe(x => {
            this.notifications = x;
          });
          this.api.Users.GetUserApplicationFeatures(this.id, '').subscribe(
            (appFeatures) => {
              for (let appFeature of appFeatures.Features) {
                this.permissions
                  .filter((x) => x.Id == appFeature.FeatureId)
                  .forEach((x) => {
                    x.CanView = appFeature.CanView;
                    x.CanEdit = appFeature.CanEdit;
                  });
              }
              this.loader.hideLoadingIcon();
            }
          );
        });
      }
    });

    this.permissions = Features.map((x) => {
      return { Id: x.Id, Name: x.Name, CanView: false, CanEdit: false };
    });
    this.setDefaultPermissions();
  }

  setDefaultPermissions() {
    for (let permission of this.permissions) {
      permission.CanView = CanViewByDefault(permission.Id, this.user.RoleId);
      permission.CanEdit = CanEditByDefault(permission.Id, this.user.RoleId);
    }
  }

  viewChanged(permission: any) {
    if (!permission.CanView) permission.CanEdit = false;
  }

  editChanged(permission: any) {
    if (permission.CanEdit) permission.CanView = true;
  }

  addNotification() {
    let notification = new UserNotification();
    notification.UserId = this.id;
    this.notifications.push(notification);
  }

  deleteNotification(notification: UserNotification) {
    this.notifications = this.notifications.filter(item => item !== notification);
  }

  save() {
    if (this.isNew) {
      this.isSaving = true;
      this.api.Users.CreateUser(this.user, this.permissions).subscribe((res: any) => {
        this.isSaving = false;
        if( res.message === "User with this email already exists") {
          alert(res.message);
        } else {
          this.router.navigate(['/users']);
        }
        
      });
    } else {
      this.isSaving = true;
      this.api.Users.UpdateUser(this.user, this.permissions, this.notifications).subscribe(() => {
        if (this.user.Email.toLowerCase() == this.api.Email.toLowerCase()) {
          this.api.Users.GetUserApplicationFeatures(
            0,
            this.user.Email
          ).subscribe((x) => {
            sessionStorage.setItem('AppFeatures', JSON.stringify(x));
            this.api.AppFeatures = x.Features;
            this.isSaving = false;
            this.router.navigate(['/users']);
          });
        } else {
          this.isSaving = false;
          this.router.navigate(['/users']);
        }
      });
    }
  }

  cancel() {
    this.router.navigate(['/users', {}]);
  }
}
