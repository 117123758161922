import { ImagesService } from "../services/images.service";

export enum ServiceCondition {
    Unknown = 0,
    Okay = 1,
    Warning = 2,
    Serious = 3
}

export const ServiceConditions = [
    { Id: ServiceCondition.Unknown, Name: "Unknown" },
    { Id: ServiceCondition.Okay, Name: "Okay" },
    { Id: ServiceCondition.Warning, Name: "Warning" },
    { Id: ServiceCondition.Serious, Name: "Serious" },
];

export function GetConditionImage(condition: ServiceCondition, images: ImagesService) {
    if (condition === ServiceCondition.Okay) return images.Icons.Check;
    if (condition === ServiceCondition.Warning) return images.Icons.Warning;
    if (condition === ServiceCondition.Serious) return images.Icons.Error;
    return images.Icons.NotAvailable;
}