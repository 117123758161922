import { ColumnDefinition } from '@gea/digital-ui-lib';
import { FeedbackType } from './feedback-type';
import { FeedbackPriority } from './feedback-priority';
import { FeedbackStatus } from './feedback-status';
import { Company } from './companies';
import { GetFilterDropdown, Products } from './feedback-product';

export interface Dropdown {
  key: string | number | boolean;
  label: string;
}

export class StoredFilter {}

export class StoredSingleFilter {
  [key: string]: Dropdown;
}

export class StoredMultipleFilter {
  [key: string]: Dropdown[];
}

export interface FilterMetadata {
  [key: string]: FilterOptions;
}

export class SubFilterMetadata {
  [key: string]: string[];
}

export interface AppliedFilters {
  configName: string,
  key: string,
  name: string,
  value: string
}[] || [];

export interface SelectedFilters {
  [filterConfigName: string]: Dropdown[]
}

export type FilterRenderConfiguration = FilterOptions & {
  configName: string;
};

export interface BaseFilterOptions {
  key: string,
  name: string,
  options?: Dropdown[]
  internalOnly?: boolean;
}

export interface SelectFilterOptions extends BaseFilterOptions {
  type: 'select' | 'multiSelect';
}

export interface AutoCompleteFilterOptions extends BaseFilterOptions {
  type: 'autoSelect' | 'autoMultiSelect';
  placeholder: string;
  prefetch: boolean;
}

export interface DateRangeFilterOptions extends BaseFilterOptions {
  type: 'dateRange',
  placeholder: string;
  dateFormat: string;
}

export type FilterOptions = SelectFilterOptions | AutoCompleteFilterOptions | DateRangeFilterOptions;

export type HRTColumnDefinition = ColumnDefinition & {
  hasFilter?: boolean;
};


export const FilterConfig: FilterMetadata = {
  AssetTypeId: {
    key: 'AssetTypeId',
    type: 'select',
    name: 'Asset Type',
    options: [
      { key: 'screwPackage', label: 'Screw Package' },
      { key: 'bareScrew', label: 'Bare Screw' },
      { key: 'piston', label: 'Bare Piston' },
      { key: 'omni', label: 'Omni' },
    ],
  },
  FeedbackType: {
    key: 'TypeId',
    type: 'multiSelect',
    name: 'Type',
    options: [
      { key: FeedbackType.Feedback, label: 'Feedback' },
      { key: FeedbackType.FeatureRequest, label: 'Feature Request' },
      { key: FeedbackType.ProblemBug, label: 'Problem / Bug' },
    ],
  },
  FeedbackPriority: {
    key: 'PriorityId',
    type: 'multiSelect',
    name: 'Priority',
    options: [
      { key: FeedbackPriority.Na, label: 'N/A' },
      { key: FeedbackPriority.Low, label: 'Low' },
      { key: FeedbackPriority.Medium, label: 'Medium' },
      { key: FeedbackPriority.High, label: 'High' },
      { key: FeedbackPriority.Urgent, label: 'Urgent' },
    ],
  },
  FeedbackStatus: {
    key: 'StatusId',
    type: 'multiSelect',
    name: 'Status',
    options: [
      { key: FeedbackStatus.New, label: 'New' },
      { key: FeedbackStatus.InReview, label: 'In Review' },
      { key: FeedbackStatus.Approved, label: 'Approved' },
      { key: FeedbackStatus.InDevelopment, label: 'In Development' },
      { key: FeedbackStatus.ReadyForTest, label: 'Ready For Test' },
      { key: FeedbackStatus.Done, label: 'Done' },
      { key: FeedbackStatus.Rejected, label: 'Rejected' },
      { key: FeedbackStatus.Deleted, label: 'Deleted' },
    ],
  },
  Company: {
    key: 'CompanyIds',
    type: 'multiSelect',
    name: 'Company',
    options: [
      { key: Company.None, label: 'None' },
      { key: Company.York, label: 'GEA York' },
      { key: Company.Berlin, label: 'GEA Berlin' },
      { key: Company.DenBosch, label: "GEA 's-Hertogenbosch" },
      { key: Company.EmeaSkids, label: 'GEA EMEA Skids' },
      {
        key: Company.EmeaSkidsServiceSales,
        label: 'GEA EMEA Skids (Service Sales)',
      },
      {
        key: Company.DenBoschServiceSales,
        label: "GEA 's-Hertogenbosch (Service Sales)",
      },
    ],
  },
  InternalOnly: {
    key: 'InternalOnly',
    type: 'select',
    name: 'Internal Only',
    internalOnly: true,
    options: [
      { key: false, label: 'No' },
      { key: true, label: 'Yes' }
    ],
  },
  PmDiscussion: {
    key: 'PmDiscussion',
    type: 'select',
    name: 'PM Discussion',
    internalOnly: true,
    options: [
      { key: false, label: 'No' },
      { key: true, label: 'Yes' }
    ],
  },
  Manufacturer: {
    key: 'Manufacturer',
    type: 'multiSelect',
    name: 'Manufacturer',
    options: [
      { key: 'grasso', label: 'Grasso' },
      { key: 'vilter', label: 'Vilter' },
      { key: 'fes', label: 'FES' },
      { key: 'york', label: 'GEA York' },
    ],
  },
  Model: {
    key: 'Model',
    type: 'select',
    name: 'Model',
    options: [
      { key: 'xa', label: 'XA' },
      { key: 'sp1', label: 'SP1 Retrofit' },
      { key: 'ks28mnb', label: 'KS28MNB' },
    ],
  },
  RepOffice: {
    key: 'SalesRep',
    type: 'autoMultiSelect',
    placeholder: 'Search Rep',
    name: 'Rep Name',
    prefetch: true
  },
  SalesManager: {
    key: 'SalesManager',
    type: 'autoMultiSelect',
    placeholder: 'Search Sales Manager',
    name: 'Sales Manager',
    prefetch: true
  },
  ProjectManager: {
    key: 'ProjectManager',
    type: 'autoMultiSelect',
    placeholder: 'Search Project Manager',
    name: 'Project Manager',
    prefetch: true
  },
  Planner: {
    key: 'Planner',
    type: 'autoMultiSelect',
    placeholder: 'Search Planner',
    name: 'Planner',
    prefetch: true
  },
  TurnInDate: {
    key: 'TurnInDate',
    type: 'dateRange',
    placeholder: 'Enter date range',
    name: 'Turn-in Date',
    dateFormat: 'MM/DD/YYYY',
  },
  ShipDate: {
    key: 'CurrentShipDate',
    type: 'dateRange',
    placeholder: 'Enter date range',
    name: 'Ship Date',
    dateFormat: 'MM/DD/YYYY'
  },
  ApplicationId: {
    key: 'ApplicationId',
    type: 'select',
    name: 'Application Name',
  },
  ProductId: {
    key: 'ApplicationId',
    type: 'multiSelect',
    name: 'Product',
    options: GetFilterDropdown(Products)
  },
  FeedbackUser: {
    key: 'UserName',
    type: 'select',
    name: 'Feedback UserName'
  },
  UserId: {
    key: 'UserId',
    type: 'select',
    name: 'Feedback UserId'
  },
  Country: {
    key: 'Country',
    type: 'select',
    name: 'Country',
  },
  Condition: {
    key: 'Condition',
    type: 'select',
    name: 'Condition',
  },
  State: {
    key: 'State',
    type: 'select',
    name: 'State',
  },
};

export function GetFilterConfigKey(config: string) {
  return FilterConfig[config]?.key;
}

export function GetFilterConfigName(config: string) {
  return FilterConfig[config]?.name;
}

export function GetFilterConfigType(config: string) {
  return FilterConfig[config]?.type;
}
export const SubFilterConfig: SubFilterMetadata = {
  assetTable: ['AssetTypeId', 'Manufacturer', 'Model'],
  feedbackAllTable: ['FeedbackType', 'Company', 'ProductId', 'FeedbackPriority', 'FeedbackStatus', 'InternalOnly', 'PmDiscussion'],
  feedbackHRTConnectTable: [
    'FeedbackType',
    'Company',
    'FeedbackPriority',
    'FeedbackStatus',
    'InternalOnly',
    'PmDiscussion'
  ],
  feedbackMyTable: ['FeedbackType', 'Company', 'ProductId', 'FeedbackPriority', 'FeedbackStatus', 'InternalOnly', 'PmDiscussion'],
  feedbackOmniTable: ['FeedbackType', 'Company', 'ProductId', 'FeedbackPriority', 'FeedbackStatus', 'InternalOnly', 'PmDiscussion'],
  feedbackRTSelectTable: ['FeedbackType', 'Company', 'ProductId', 'FeedbackPriority', 'FeedbackStatus', 'InternalOnly', 'PmDiscussion'],
  feedbackBluRedCareTable: ['FeedbackType', 'Company', 'ProductId', 'FeedbackPriority', 'FeedbackStatus', 'InternalOnly', 'PmDiscussion'],
  orderStatus: ['RepOffice', 'SalesManager', 'ProjectManager', 'Planner', 'TurnInDate', 'ShipDate']
};
