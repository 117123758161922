export enum FeedbackStatus {
        New = 0,
        InReview = 1,
        Approved = 2,
        InDevelopment = 3,
        ReadyForTest = 4,
        Done = 5,
        Rejected = 6,
        Deleted = 7,
};

export const FeedbackStatuses = [
    { Id: FeedbackStatus.New, Name: "New" },
    { Id: FeedbackStatus.InReview, Name: "In Review" },
    { Id: FeedbackStatus.Approved, Name: "Approved" },
    { Id: FeedbackStatus.InDevelopment, Name: "In Development" },
    { Id: FeedbackStatus.ReadyForTest, Name: "Ready For Test" },
    { Id: FeedbackStatus.Done, Name: "Done" },
    { Id: FeedbackStatus.Rejected, Name: "Rejected" },
    { Id: FeedbackStatus.Deleted, Name: "Deleted" }
];

export function GetFeedbackStatusText(id: number) {
    let textData = FeedbackStatuses.find(x => <number>x.Id === id);
    if (textData) {
        return textData.Name;
    }
    else {
        return "Unknown";
    }
}