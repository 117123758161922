<div class="title-div">
  <div *ngIf="showRedirectMessage" class="redirect-message">
    <p class="message">To add/edit feedback please click this link -> </p>
    <button class="geaui-button-secondary" (click)="redirectToProd()">Go to PROD</button>
  </div>
  
  <div class="add-btn-container">
    <button
      mat-raised-button
      class="geaui-button-primary AddButton"
      [disabled]="(userRole !='7000') && (isEnvironment == 'test')"
      (click)="!((userRole !='7000') && (isEnvironment == 'test')) ? feedbackService.addNewFeedback(): false"
    >
      Add Feedback
    </button>
  </div>
</div>
  <br />
  
  <hrt-feedback-table [id]="tableId"></hrt-feedback-table>
  