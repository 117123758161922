<div class="home-container" *ngIf="configDataReceived">
  <div class="news-feed-wrapper">
    <div class="news-feed">

      <div
        *ngFor="let product of productSlides; let i = index"
        [id]="'feed-' + i"
        class="feed"
      >
      <div class="transparent-el"></div>
      <div class="actual-feed">
        <div class="product-info">
          <span class="product-name">{{ product.title }}</span>
          <div class="product-details">
            {{ product.text }}
          </div>
          <div class="read-more-btn">
            <geaui-button-v2
              [type]="'secondary'"
              class="btn-brdr-radius"
              [postIcon]="true"
              (click)="redirectToProduct(product.link)"
            >
              Read More
            </geaui-button-v2>
          </div>
        </div>
        <div class="product-image" [ngClass]="product.classes">
          <img src="{{ product.productImgSrc }}" alt="{{ product.altText }}" />
        </div>
      </div>
      </div>
    </div>
    <div class="gea-heading">
      <span>{{uiLabels.geaHeading}}</span>
      <span>|</span>
      <a (click)="openInNewTab('https://www.gea.com/en/')">GEA.com</a>
    </div>
    <div class="navigation-arrows">
      <div class="bubble" (click)="prevFeed()">
        <mat-icon>arrow_back</mat-icon>
      </div>
      <div class="bubble" (click)="nextFeed($event)">
        <mat-icon>arrow_forward</mat-icon>
      </div>
    </div>
    <div class="slider-nav">
      <span class="slider-count">{{currentFeedPage + 1}}</span>
      <a
        *ngFor="let link of productSlides; let i = index"
        [id]="'link-' + i"
        [ngClass]="i == currentFeedPage ? 'is-active' : ''"
        [href]="'#feed-' + i"
        (click)="currentFeedPage = i"
      ></a>
    </div>
  </div>
  <div class="more-apps">
    <h2>More from GEA Portal</h2>
    <div class="apps">
        <div class="app" *ngFor="let app of appInfos; let i = index" (click)="navigateToApp(app)">
            <div class="icon-wrapper">
                <img
                  *ngIf="app.appImgSrc != ''"  
                  class="icon"
                  [ngClass]="app.classes"
                  [src]="app.appImgSrc"
                  [alt]="app.altText"
                />
              </div>
              <div class="details">
                <div class="header">{{app.title}}</div>
                <div class="description">
                  {{app.description}}
                </div>
              </div>
        </div>
    </div>
  </div>
</div>
