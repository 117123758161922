<hrt-dashboard-table
  [id]="tableId"
  [dashboardTable]="assetTable"
  [presetFilters]="presetFilter"
  [displayedColumns]="displayedColumns"
  (rowClicked)="rowClicked($event)"
  (filterApplied)="handleSelectedFilters($event)"
  (exportClicked)="exportIotAssets()"
>
  <mat-icon
    *ngIf="this.showStateMap"
    (click)="backToWorld()"
    class="MapButton custom-svg"
    matTooltip="Back to World View"
    [matTooltipPosition]="'left'"
    >public</mat-icon
  >
  <div class="geo-chart-container">
    <geo-chart
      *ngIf="this.showWorldMap"
      [Data]="this.mapData"
      [Options]="this.worldOptions"
      (RegionClicked)="regionSelected($event)"
    ></geo-chart>
    <geo-chart
      *ngIf="this.showStateMap"
      [Data]="this.mapData"
      [Options]="this.stateOptions"
      (RegionClicked)="regionSelected($event)"
    ></geo-chart>
  </div>
</hrt-dashboard-table>
