<div class="hrt-title-container" [ngClass]="!hasTabs ? 'no-border' : ''">
  <div class="initial" #initialEl>
    <img
      *ngIf="showBackBtn"
      src="../../../../assets/layout/arrow_back.svg"
      (click)="goBack()"
      scope="col"
      style="cursor: pointer"
      width="25px"
    />
    <h1 class="title" [ngClass]="!hasTabs ? 'no-border' : ''">{{ title }}</h1>
  </div>
  <mat-tab-group #tabGroup *ngIf="hasTabs" [selectedIndex]="selectedIndex">
    <ng-container *ngFor="let tab of tabs; let i = index">
      <ng-container *ngIf="renderAllTabs">
        <mat-tab [label]="tab.tabName">
          <ng-container
            *ngComponentOutlet="tab.tabComponent; inputs: { data: tab.data }"
          ></ng-container>
        </mat-tab>
      </ng-container>
      <ng-container *ngIf="!renderAllTabs">
        <mat-tab *ngIf="tab.isTabVisible" [label]="tab.tabName">
          <ng-template matTabContent>
            <ng-container
              *ngComponentOutlet="tab.tabComponent; inputs: { data: tab.data }"
            ></ng-container>
          </ng-template>
        </mat-tab>
      </ng-container>
    </ng-container>
  </mat-tab-group>
  <span class="gea-icon">

    <mat-icon
    *ngIf="hasShareButton"
    title="Share"
    (click)="copyLink()"
    style="margin-right: 15px;"
    > <geaui-icon-v2
    file="16px_network-connection"
    [color]="'black'"
    [colorProp]="'stroke'"
    ></geaui-icon-v2></mat-icon>

    <mat-icon
    *ngIf="hasBookmark"
    title="Bookmark"
    (click)="toggleBookmark()"
    > <geaui-icon-v2
    file="16px_bookmark"
    [color]="'black'"
    [colorProp]="isBookmarked? 'fill' : 'stroke'"
    ></geaui-icon-v2></mat-icon>
  </span>
</div>
