<hrt-table
[tableId]="tableId"
[columnDefinitions]="columnDefinitions"
[defaultTableSettings]="defaultTableConfig"
[tableData]="tableData"
[totalCount]="totalCount"
(searchText)="updateSearchTerm($event)"
(tableSettings)="handleTableSettings($event)"
(rowClicked)="rowClicked($event)"
>
</hrt-table>