<hrt-table
  [tableId]="tableId"
  [columnDefinitions]="columnDefinitions"
  [defaultTableSettings]="defaultTableConfig"
  [presetFilters]="presetFilter"
  [tableData]="tableData"
  [totalCount]="totalCount"
  [hasExport]="true"
  (exportClicked)="exportIotAssets()"
  (searchText)="updateSearchTerm($event)"
  (rowClicked)="rowClicked($event)"
  (tableSettings)="handleTableSettings($event)"
  (filters)="handleSelectedFilters($event)"
>
</hrt-table>
