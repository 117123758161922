import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FilterTableSettings } from '@gea/digital-ui-lib';
import { tap } from 'rxjs';
import { GeoChartOptions } from 'src/app/shared/components/geo-chart/geo-chart.component';
import { TableIconComponent } from 'src/app/shared/components/table-icon/table-icon.component';
import { Asset } from 'src/app/shared/data/data.generated';
import { HRTColumnDefinition, SelectedFilters } from 'src/app/shared/data/filterConfiguration';
import { GetCustomTypeText } from 'src/app/shared/data/product-types';
import { tableDefinitions } from 'src/app/shared/data/table-definitions';
import { ApiService } from 'src/app/shared/services/api.service';
import {
  Table,
  TableResult,
  TableView,
} from 'src/app/shared/services/api/table';
import {
  DashboardQueryType,
  DashboardService,
  DashboardServiceCondition,
} from 'src/app/shared/services/dashboard.service';
import { HrtTableService } from 'src/app/shared/services/hrt-table.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { RegionDataService } from 'src/app/shared/services/region-data.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { WebsocketService } from 'src/app/shared/services/websocket.service';

@Component({
  selector: 'app-dashboard-iot-ad',
  templateUrl: './dashboard-iot-ad.component.html',
  styleUrls: ['./dashboard-iot-ad.component.scss'],
})
export class DashboardIotAdComponent {
  tableId: string = 'dashboardIotAd';
  totalCount: number = 0;
  columnDefinitions: HRTColumnDefinition[];
  tableData: any[];
  listOptions: TableView = new TableView();
  unsortableKeys = ['ProductTypeId', 'ProductTypeIdText'];

  countries: any[] = [];
  presetFilter: SelectedFilters = {
    Country: [],
  };
  defaultTableConfig: FilterTableSettings = {
    pageSize: 20,
    columns: {}
  };
  assetsType: DashboardQueryType = DashboardQueryType.Assets;
  selectedCountry: string = '';
  selectedState: string = '';
  selectedType: DashboardQueryType = DashboardQueryType.Assets;
  selectedCondition: DashboardServiceCondition = DashboardServiceCondition.All;
  selectedProductType: number = -1;
  fetchConnectedAssets: boolean = true;
  appliedType: DashboardQueryType = DashboardQueryType.Assets;

  showWorldMap: boolean = true;
  showStateMap: boolean = true;
  tableDataLoading: boolean = false;
  initialLoad: boolean = true;

  mapData: (string | number)[][] = [];
  mapDataLoading: boolean = false;
  worldOptions: GeoChartOptions = GeoChartOptions.WorldViewOptions;
  stateOptions: GeoChartOptions = GeoChartOptions.StateViewOptions;

  normalCount = 0;
  normalPercent = 0;

  warningCount = 0;
  warningPercent = 0;

  shutdownCount = 0;
  shutdownPercent = 0;

  getCustomTypeText = GetCustomTypeText;

  assetTable: Table<Asset> = new Table<Asset>(this.loader);

  constructor(
    private api: ApiService,
    private utilityService: UtilityService,
    private hrtTableService: HrtTableService,
    private websocketService: WebsocketService,
    private loader: LoaderService,
  ) {}

  @Input() data: any;

  ngOnInit(): void {
    this.websocketService.liveData.subscribe(data => {
    })

    this.applyPresetFilters();
    if (sessionStorage.getItem(this.tableId + '-listOptions')) {
      this.listOptions = JSON.parse(
        sessionStorage.getItem(this.tableId + '-listOptions') || ''
      );
    }
    this.assetTable.GetApi = (listOptions: TableView) => {
      return this.api.Assets.GetAssets(
        this.listOptions,
        undefined,
        this.selectedCountry,
        this.selectedState,
        this.selectedProductType,
        this.fetchConnectedAssets
      ).pipe(
        tap((assetData: TableResult<Asset>) => {
          this.tableData = this.modifyTableData(assetData.Data);
          this.totalCount = assetData.Count;
        })
      );
    };
    this.assetTable.Updated = () => (this.tableDataLoading = false);

    // For initial load, control doesn't go in tap unless subscribed.
    // this.assetTable.refreshDataForTable()?.subscribe();

    // this.assetTable.Updated = () => (this.tableDataLoading = false);
    this.assignMockColDefinitions();

    this.utilityService.setActiveTab(4, 'dashboardActiveIndex');
  }

  ngAfterViewInit() {
    this.initialLoad = false;
  }

  applyPresetFilters() {
    if (!sessionStorage.getItem('filter-chips-' + this.tableId)) return;
    this.presetFilter = JSON.parse(
      sessionStorage.getItem('filter-chips-' + this.tableId) || ''
    );
    
    this.selectedCountry = (this.presetFilter['Country'] && this.presetFilter['Country'].length > 0) ? this.presetFilter['Country'][0].key.toString() : '';
    this.selectedState = (this.presetFilter['State'] && this.presetFilter['State'].length > 0) ? this.presetFilter['State'][0].key.toString() : '';
  }

  assignMockColDefinitions() {
    let definitions = tableDefinitions.assetTableDef;

    this.columnDefinitions = this.hrtTableService.addTypeIconToColumn(
      definitions,
      ['ProductTypeId', 'OperatingStatus', 'MotorStatus'],
      TableIconComponent
    );
  }

  modifyTableData(assetData: any) {
    const modifiedAsset = assetData.map((asset: any) => {
      return {
        ...asset,
        ProductTypeIdText: this.getCustomTypeText(asset.ProductTypeId),
      };
    });
    return modifiedAsset;
  }

  handleTableSettings(tableSettings: FilterTableSettings) {
    if (this.initialLoad) return;

    const oldOptions = JSON.parse(JSON.stringify(this.listOptions));

    const tableColKeys: string[] = Object.keys(tableSettings.columns);
    let sortKey = '';
    let sortDes = 'False';
    if (tableColKeys.length > 0) {
      // sorting exists
      for (let key in tableSettings.columns) {
        let sortValue = tableSettings.columns[key].sort;
        if (sortValue === 'asc' || sortValue === 'desc') {
          // return if its an unsortable key.
          if (this.unsortableKeys.includes(key)) break;

          sortKey = key;
          sortDes = sortValue === 'desc' ? 'True' : 'False';
          this.assetTable.View.SortColumn = sortKey;
          this.assetTable.View.SortDesc = sortDes;

          this.listOptions.SortColumn = sortKey;
          this.listOptions.SortDesc = sortDes;
          break;
        }
      }
    }
    this.assetTable.View.Page = tableSettings.page;
    // this.assetTable.View.PageSize = tableSettings.pageSize;
    // update local list options.
    this.listOptions.Page = tableSettings.page;
    this.hrtTableService.saveListOptionsToSS(this.tableId, this.listOptions);
    // this.listOptions.PageSize = tableSettings.pageSize;

    // Object comparision as tableSettings sends multiple events.
    if (this.utilityService.deepEqual(oldOptions, this.listOptions)) return;
    this.assetTable.SearchUpdated();
  }

  handleSelectedFilters(selectedFilters: SelectedFilters) {
    // console.log('Selected filters in parent:', selectedFilters);
    if (selectedFilters) {
      if (!selectedFilters['Country']) {
        // this.backToWorld();
      }
      if (!selectedFilters['State']) {
        this.selectedState = '';
        this.presetFilter = {
          ...this.presetFilter,
          State: []
        };
        // this.updateMapAndTable();
      }
      sessionStorage.setItem(
        'filter-chips-' + this.tableId,
        JSON.stringify(selectedFilters)
      );
    }
  }
  updateSearchTerm(searchText: string) {
    // console.log(`SearchText is ${searchText}`);
    if (this.initialLoad) return;

    this.assetTable.View.Search = searchText;
    this.listOptions.Search = searchText;
    this.hrtTableService.saveListOptionsToSS(this.tableId, this.listOptions);
    this.assetTable.SearchUpdated();
  }

  rowClicked(rowData: any) {
    // console.log('Row click event', rowData);
    this.utilityService.openAsset(rowData.Id);
  }

  exportIotAssets() {
    const listOptions = this.listOptions;
    listOptions.Page = 0;
    listOptions.HasPaging = false;
    this.api.Assets.ExportAssets(listOptions,
      undefined,
      this.selectedCountry,
      this.selectedState,
      this.selectedProductType,
      this.fetchConnectedAssets,
      'IOT');
  }

  ngOnDestroy() {
    sessionStorage.setItem(
      this.tableId + '-listOptions',
      JSON.stringify(this.listOptions)
      );
    }
}
