import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DescriptionPopupComponent } from 'src/app/pages/feedback/description-popup/description-popup.component';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(
    private router: Router,
    public dialog: MatDialog
  ) { }

  addNewFeedback() {
    this.router.navigate(['/feedback-details', { mode: 'add' }]);
  }

  editFeedback(id: number) {
    this.router.navigate(['/feedback-details', { id: id, mode: 'edit' }]);
  }

  getIcon(type: number) {
    if (type === 0) return 'insert_comment';
    if (type === 1) return 'library_add';
    if (type === 2) return 'bug_report';
    return '';
  }

  getIconStyle(type: number) {
    if (type === 0) return 'color: rgb(0, 0, 150);';
    if (type === 1) return 'color: rgb(0, 150, 0);';
    if (type === 2) return 'color: rgb(200, 0, 0);';
    return '';
  }

  formatDate(dateStr: string) {
    return new Date(dateStr).toLocaleDateString('en-US', {
      year: '2-digit', // YY
      month: '2-digit', // MM
      day: '2-digit', // dd
    });
  }

  openModal(
    description: string,
    title: string,
    iconType: number,
    event: MouseEvent
  ) {
    const dialogRef = this.dialog.open(DescriptionPopupComponent, {
      width: '800px',
      height: '500px',
      data: {
        content: description,
        title: title,
        iconType: iconType,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  closeDialog() {
    this.dialog.closeAll();
  }
}

