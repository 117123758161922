import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoaderService } from '../loader.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { inject } from '@angular/core';
import { Dropdown } from '../../data/filterConfiguration';
import { UtilityService } from '../utility.service';

export class TableResult<T> {
  public Count: number = 0;
  public Data: T[] = [];
}

export class TableView {
  public Search: string = '';
  public SortColumn: string = '';
  public SortDesc: string = 'False';
  public PageSizeOptions: number[] = [10, 20, 50, 100, 500, 1000];
  public PageSize: number | undefined = 20;
  public Page: number | undefined = 0;
  public HasPaging: boolean = true;
  public Filters: Filter = {};
}

export class Filter {
  [key:string]: {
    type?: string,
    values: Dropdown[]
  }
}

export class Table<T> {
  constructor(private loader?: LoaderService) {}

  public Result: TableResult<T> = new TableResult<T>();
  public View: TableView = new TableView();
  public EditRow: T | undefined = undefined;
  public IsNew: boolean = false;
  public inputSubject = new BehaviorSubject<string>('');
  private snackbar = inject(MatSnackBar);
  private utilityService = inject(UtilityService);
  private oldRequest: any = {};
  private filterDelayed: any = undefined;


  public Updated: () => void = () => {};

  public GetApi:
    | ((listOptions: TableView) => Observable<TableResult<T>>)
    | undefined;
  // Update, New, Delete

  public UpdateResult(result: TableResult<T>) {
    this.Result = result;
    this.Updated();
  }

  public SearchUpdated() {
    clearTimeout(this.filterDelayed);
    this.filterDelayed = setTimeout(() => {
      this.RefreshData();
    }, 0);
    // this.refreshDataForTable()?.subscribe((val) => {});
  }

  addToFilter(filterKey: string, filterValue: Dropdown[], filterType?: string) {
    let currentFilter = this.View.Filters[filterKey];
    if (currentFilter) {
      // apend to values of current filter - never called.
      this.View.Filters[filterKey].type = filterType ?? undefined;
      this.View.Filters[filterKey].values = [
        ...currentFilter.values,
        ...filterValue
      ]
    } else {
      this.View.Filters[filterKey] = {
        type: filterType ?? undefined,
        values: filterValue
      };
    }
  }

  public RefreshData(event?: PageEvent | Sort) {
    var pageEvent = event as PageEvent;
    if (pageEvent) {
      this.View.Page = pageEvent.pageIndex;
      if (pageEvent.pageSize) this.View.PageSize = pageEvent.pageSize;
      if (this.View.Page === undefined) this.View.Page = 0;
    }
    var sort = event as Sort;
    if (sort && sort.active) {
      this.View.SortColumn = sort.active;
      this.View.SortDesc = sort.direction == 'desc' ? 'True' : 'False';
    }
    if (this.GetApi && !this.utilityService.deepEqual(this.oldRequest, this.View)) {
      this.loader?.showLoadingIcon();
      this.oldRequest = JSON.parse(JSON.stringify(this.View));
      this.GetApi(this.View).subscribe({
        next: (data: TableResult<T>) => {
          this.UpdateResult(data);
          this.loader?.hideLoadingIcon();
        },
        error: (err: any) => {
          this.snackbar.open(
            'Failed to fetch data. Error: ' + err.messageStr,
            'X',
            {
              duration: 3000,
              panelClass: ['notify-error'],
              verticalPosition: 'top',
            }
          );
          this.loader?.hideLoadingIcon();
        },
      });
    }
  }

  refreshDataForTable(): Observable<TableResult<T>> | undefined {
    if (this.GetApi) {
      return this.GetApi(this.View);
    }
    return undefined;
  }

}
