<!-- <dx-gantt scaleType="weeks" height="700">
    <dxo-tasks [dataSource]="tasks"></dxo-tasks>

    <dxi-column dataField="title" caption="Subject" [width]="300"></dxi-column>
    <dxi-column dataField="start" caption="Start Date" dataType="date"></dxi-column>
    <dxi-column dataField="end" caption="End Date" dataType="date"></dxi-column>

</dx-gantt> -->

<mat-tab-group selectedIndex="0">
    <!-- TypeScript -->
    <mat-tab label="TypeScript">
        Cached: <input [(ngModel)]="cached">
        <button (click)="cache()">Cache</button>
    </mat-tab>

    <!-- Angular -->
    <mat-tab label="Angular">
        
        <!-- Binding -->
        <input [value]="name"> <!-- One Way -->
        <input [(ngModel)]="name"> <!-- Two Way -->
        <button (click)="showAlert()">Event</button>  <!-- Event -->
        <br>

        <!-- Interpolation -->
        {{ "Interpolation: " + (5 + 6) }}
        <br>

        <!-- Pipes -->
        {{ "pipes" | uppercase }}
        <br>
        
        <!-- Directives -->
        <button mat-button>directive</button>
        <br>

        <!-- Template Reference Variable -->
        <div #reference>{{ reference.tagName }}</div>
        <br>

        <!-- * before directive turns into Embedded template (ng-template) -->

        <!-- ngIf -->
        <div *ngIf="isOnline">Online</div>
        <br>

        <!-- ngFor -->
        <span *ngFor="let product of products"> {{ product }} </span>
        <br>

        <!-- MASL Login Test -->
        <button mat-button (click)="login()">MASL Login Test</button>
        <button mat-button (click)="testToken()">Test Token</button>

    </mat-tab>

    <!-- Material -->
    <mat-tab label="Material">

        <!-- Theme -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Theme</mat-panel-title>
                <mat-panel-description>primary, accent, warn</mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <button mat-raised-button color="primary">Primary</button>
                <button mat-raised-button color="accent">Accent</button>
                <button mat-raised-button color="warn">Warn</button>
            </div>
        </mat-expansion-panel>

        <!-- Typography -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Typography</mat-panel-title>
                <mat-panel-description>mat-display-x, mat-headline, mat-title, mat-subheading-x</mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <div class="mat-display-4">class mat-display-4</div>
                <div class="mat-display-3">class mat-display-3</div>
                <div class="mat-display-2">class mat-display-2</div>
                <div class="mat-display-1">class mat-display-1</div>

                <div class="mat-headline">class mat-headline</div> <!-- h1 -->
                <div class="mat-title">class mat-title</div> <!-- h2 -->
                <div class="mat-subheading-2">class mat-subheading-2</div> <!-- h3 -->
                <div class="mat-subheading-1">class mat-subheading-1</div> <!-- h4 -->
                
                <div class="mat-body-1">class mat-body-1</div>
                <div class="mat-body-2">class mat-body-2</div>
                <div class="mat-caption">class caption</div>

                <div class="mat-typography">
                    <h1>h1</h1>
                    <h2>h2</h2>
                    <h3>h3</h3>
                    <h4>h4</h4>
                </div>
            </div>
        </mat-expansion-panel>

        <!-- Button -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Button</mat-panel-title>
                <mat-panel-description>mat-button, mat-raised-button, mat-flat-button, mat-stroked-button</mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <button mat-button>mat-button</button>
                <button mat-raised-button>mat-raised-button</button>
                <button mat-flat-button>mat-flat-button</button>
                <button mat-stroked-button>mat-stroked-button</button>
                <br>
                
                <button mat-icon-button>mat-icon-button</button>
                <button mat-fab>mat-fab</button>
                <button mat-mini-fab>mat-mini-fab</button>
                <br>

                <button mat-button disableRipple>disableRipple</button>
                <button mat-button disabled>disabled</button>
            </div>
        </mat-expansion-panel>

        <!-- Check / Radio / Slide -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Check / Radio / Slide</mat-panel-title>
                <mat-panel-description>mat-checkbox, mat-radio-group, mat-radio-button, mat-slide-toggle</mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <mat-checkbox [(ngModel)]="isOnline">Is Online</mat-checkbox>
                <mat-radio-group [(ngModel)]="isOnline">
                    <mat-radio-button [value]="true">Online</mat-radio-button>
                    <mat-radio-button [value]="false">Offline</mat-radio-button>
                </mat-radio-group>
                <mat-slide-toggle [(ngModel)]="isOnline">
                    Is Online
                </mat-slide-toggle>
            </div>
        </mat-expansion-panel>

        <!-- Button Toggle -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Button Toggle</mat-panel-title>
                <mat-panel-description>mat-button-toggle, mat-button-toggle-group</mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <mat-button-toggle #toggleButton checked>mat-button-toggle</mat-button-toggle>
                {{ "checked : " + toggleButton.checked }}
                <br>
                <mat-button-toggle-group multiple #toggleGroup="matButtonToggleGroup">
                    <mat-button-toggle value="test1">Test 1</mat-button-toggle>
                    <mat-button-toggle value="test2">Test 2</mat-button-toggle>
                    <mat-button-toggle value="test3">Test 3</mat-button-toggle>
                </mat-button-toggle-group>
                {{ "value : " + toggleGroup.value }}
            </div>
        </mat-expansion-panel>

        <!-- Icon -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Icon</mat-panel-title>
                <mat-panel-description>mat-icon</mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <a href="https://fonts.google.com/icons?selected=Material+Icons" target="_blank">
                    https://fonts.google.com/icons?selected=Material+Icons
                </a>
                <br>
                <mat-icon>home</mat-icon>
                <mat-icon color="primary">email</mat-icon>
                <mat-icon color="accent">business</mat-icon>
                <mat-icon color="warn">zoom_out</mat-icon>
            </div>
        </mat-expansion-panel>

        <!-- Badge -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Badge</mat-panel-title>
                <mat-panel-description>matBadge</mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <span matBadge="2" matBadgePosition="below before" style="margin: 20px;">Notifications</span>
                <span matBadge="2" matBadgePosition="above after" style="margin: 20px;">Notifications</span>
                <span matBadge="2" matBadgeSize="small" style="margin: 20px;">Notifications</span>
                <span matBadge="2" matBadgeSize="large" style="margin: 20px;">Notifications</span>
                <span matBadge="2" matBadgeColor="warn" style="margin: 20px;">Notifications</span>
                <span matBadge="2" matBadgeOverlap="false" style="margin: 20px;">Notifications</span>
                <span [matBadge]="products.length" matBadgeOverlap="false" style="margin: 20px;">Products</span>
                <mat-button-toggle #toggleBadge>Toggle Badge</mat-button-toggle>
                <span matBadge="2" [matBadgeHidden]="!toggleBadge.checked" style="margin: 20px;">Notifications</span>
            </div>
        </mat-expansion-panel>

        <!-- Progress Spinner / Bar -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Progress Spinner / Bar</mat-panel-title>
                <mat-panel-description>mat-slider, mat-progress-spinner, mat-progress-bar</mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                Value ({{ progressSlider.value }}):
                <mat-slider #progressSlider min="0" max="100" value="40"></mat-slider>
                <mat-progress-spinner [value]="progressSlider.value"></mat-progress-spinner>
                <mat-button-toggle #toggleSpinner>Toggle Spinner</mat-button-toggle>
                <mat-spinner *ngIf="!toggleSpinner.checked" color="accent"></mat-spinner>
                <mat-progress-bar [value]="progressSlider.value"></mat-progress-bar>
            </div>
        </mat-expansion-panel>

        <!-- Toolbar -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Toolbar</mat-panel-title>
                <mat-panel-description>mat-toolbar</mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <mat-toolbar color="primary" class="NavBar">
                    <div>Site Name</div>
                    <div>
                        <span class="NavBarLink">Home</span>
                        <span class="NavBarLink">Contacts</span>
                        <span class="NavBarLink">About</span>
                    </div>
                    <div>User</div>
                </mat-toolbar>
            </div>
        </mat-expansion-panel>

        <!-- Sidenav -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Sidenav</mat-panel-title>
                <mat-panel-description>mat-sidenav-container, mat-sidenav, mat-sidenav-content</mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <mat-button-toggle #toggleSideNav>
                    <mat-icon>view_headline</mat-icon>
                </mat-button-toggle>
                <mat-sidenav-container style="height: 100px;">
                    <mat-sidenav mode="over" [opened]="toggleSideNav.checked" style="width: 150px;">
                        Nav
                    </mat-sidenav>
                    <mat-sidenav-content>
                        Over
                    </mat-sidenav-content>
                </mat-sidenav-container>
                <mat-sidenav-container style="height: 100px;">
                    <mat-sidenav mode="push" [opened]="toggleSideNav.checked" style="width: 150px;">
                        Nav
                    </mat-sidenav>
                    <mat-sidenav-content>
                        Push
                    </mat-sidenav-content>
                </mat-sidenav-container>
                <mat-sidenav-container style="height: 100px;">
                    <mat-sidenav mode="side" [opened]="toggleSideNav.checked" style="width: 150px;">
                        Nav
                    </mat-sidenav>
                    <mat-sidenav-content>
                        Side
                    </mat-sidenav-content>
                </mat-sidenav-container>
                <!-- events methods: (opened), (closed) -->
                <!-- methods: open(), close(), toggle() -->
            </div>
        </mat-expansion-panel>

        <!-- Menu -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Menu</mat-panel-title>
                <mat-panel-description>mat-menu, mat-menu-item</mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <mat-menu #menu="matMenu"> 
                    <button mat-menu-item>Item 1</button>
                    <button mat-menu-item [matMenuTriggerFor]="subMenu">Item 2</button>
                    <button mat-menu-item>Item 3</button>
                </mat-menu>
    
                <mat-menu #subMenu="matMenu">
                    <button mat-menu-item>Sub Item 1</button>
                    <button mat-menu-item>Sub Item 2</button>
                    <button mat-menu-item>Sub Item 3</button>
                </mat-menu>
    
                <!-- xPosition="before | after" yPosition="above | below" -->
    
                <button mat-stroked-button [matMenuTriggerFor]="menu">Menu w/ Sub Menu</button>
    
                <mat-menu #lazyMenu="matMenu">
                    <ng-template matMenuContent let-name="name" let-order="order">
                        <button mat-menu-item>Settings {{ order }}</button>
                        <button mat-menu-item>Log Out {{ name }}</button>
                    </ng-template>
                </mat-menu>
    
                <button mat-stroked-button [matMenuTriggerFor]="lazyMenu" [matMenuTriggerData]="{ name: 'Second', order: 2 }">Second Menu - Template</button>
                <button mat-stroked-button [matMenuTriggerFor]="lazyMenu" [matMenuTriggerData]="{ name: 'Third', order: 3 }">Third Menu - Template</button>
            </div>
        </mat-expansion-panel>

        <!-- List -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>List</mat-panel-title>
                <mat-panel-description>mat-list, mat-list-item, mat-divider</mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <mat-list dense>
                    <mat-divider></mat-divider>
                    <mat-list-item>
                        <mat-icon matListIcon>home</mat-icon>
                        <h3 matLine>Heading</h3>
                        <p matLine>Item 1</p>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                    <mat-list-item>Item 2</mat-list-item>
                    <mat-divider></mat-divider>
                    <mat-list-item>Item 3</mat-list-item>
                    <mat-divider></mat-divider>
                </mat-list>
    
                <mat-nav-list>
                    <a mat-list-item href="http://google.com">Google</a>
                    <a mat-list-item href="http://amazon.com">Amazon</a>
                </mat-nav-list>
            </div>
        </mat-expansion-panel>

        <!-- Grid List -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Grid List</mat-panel-title>
                <mat-panel-description>mat-grid-list, mat-grid-tile</mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <mat-grid-list cols="2" style="width: 200px; height: 200px;" rowHeight="2: 1" gutterSize="5">
                    <mat-grid-tile colspan="2">Tile 1</mat-grid-tile>
                    <mat-grid-tile rowspan="2">Tile 2</mat-grid-tile>
                    <mat-grid-tile>Tile 3</mat-grid-tile>
                    <mat-grid-tile>Tile 4</mat-grid-tile>
                </mat-grid-list>
    
                <!-- rowHeight="fit" w/ specific height -->
            </div>
        </mat-expansion-panel>

        <!-- Expansion Panel -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Expansion Panel</mat-panel-title>
                <mat-panel-description>mat-expansion-panel, mat-accordion, mat-expansion-panel-header, mat-panel-title, mat-panel-description, mat-action-row</mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <mat-accordion> <!-- multi="true" hideToggle="true" -->
                    <mat-expansion-panel #expansion>
                        <mat-expansion-panel-header>
                            <mat-panel-title>Header</mat-panel-title>
                            <mat-panel-description>Description here</mat-panel-description>
                        </mat-expansion-panel-header>
                        <p>
                            This is the content.
                        </p>
                        <mat-action-row>
                            <button mat-button (click)="expansion.close()">Close</button>
                        </mat-action-row>
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>Header 2</mat-panel-title>
                            <mat-panel-description>Description 2</mat-panel-description>
                        </mat-expansion-panel-header>
                        <p>
                            This is the content second.
                        </p>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </mat-expansion-panel>

        <!-- Card -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Card</mat-panel-title>
                <mat-panel-description>mat-card, mat-card-title, mat-card-subtitle, mat-card-content, mat-card-actions</mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <mat-card>Basic card</mat-card>
                <mat-card>
                    <mat-card-title>Card Title</mat-card-title>
                    <mat-card-subtitle>Card Subtitle</mat-card-subtitle>
                    <mat-card-content>Card Content</mat-card-content>
                    <mat-card-actions align="end">
                        <button mat-raised-button>Action</button>
                    </mat-card-actions>
                </mat-card>
            </div>
        </mat-expansion-panel>

        <!-- Tab -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Tab</mat-panel-title>
                <mat-panel-description>mat-tab-group, mat-tab</mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <mat-tab-group #tabs (selectedTabChange)="log('selectedTabChange')">
                    <mat-tab label="Tab 1">Content 1</mat-tab>
                    <mat-tab label="Tab 2">Content 2</mat-tab>
                    <mat-tab label="Tab 3">Content 3</mat-tab>
                </mat-tab-group>
                <!-- selectedIndex: {{ tabs.selectedIndex }} -->
            </div>
        </mat-expansion-panel>

        <!-- Stepper -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Stepper</mat-panel-title>
                <mat-panel-description>mat-horizontal-stepper, mat-vertical-stepper, mat-step</mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <mat-horizontal-stepper>
                    <mat-step label="Item 1">
                        Content 1
                        <button mat-button matStepperNext>Next</button>
                    </mat-step>
                    <mat-step label="Item 2">
                        <button mat-button matStepperPrevious>Previous</button>
                        Content 2
                        <button mat-button matStepperNext>Next</button>
                    </mat-step>
                    <mat-step label="Item 3">
                        <button mat-button matStepperPrevious>Previous</button>
                        Content 3
                    </mat-step>
                </mat-horizontal-stepper>
        
                <!-- To force in order, use props: linear, completed, optional -->
        
                <mat-vertical-stepper>
                    <mat-step label="Item 1">
                        Content 1
                    </mat-step>
                    <mat-step label="Item 2">
                        Content 2
                    </mat-step>
                    <mat-step label="Item 3">
                        Content 3
                    </mat-step>
                </mat-vertical-stepper>
            </div>
        </mat-expansion-panel>

        <!-- Input (TextBox) -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Input (TextBox)</mat-panel-title>
                <mat-panel-description>mat-form-field, mat-label, mat-hint, matInput</mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <mat-form-field appearance="legacy"> <!-- floatLabel hideRequiredMarker -->
                    <mat-label>Name</mat-label>
                    <input matInput required [(ngModel)]="name" />
                    <mat-hint align="end">Min length 5</mat-hint>
                </mat-form-field>
        
                <mat-form-field appearance="fill" color="warn">
                    <mat-label>Fill</mat-label>
                    <input matInput />
                </mat-form-field>
        
                <mat-form-field appearance="outline" color="accent">
                    <mat-label>Outline</mat-label>
                    <input matInput />
                </mat-form-field>
            </div>
        </mat-expansion-panel>

        <!-- Select (DropDown) -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Select (DropDown)</mat-panel-title>
                <mat-panel-description>mat-select, mat-option, mat-optgroup</mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <mat-form-field>
                    <mat-label>Options</mat-label>
                    <mat-select> <!-- [(value)] multiple -->
                        <mat-select-trigger>
                            <mat-icon>home</mat-icon> Test
                        </mat-select-trigger>
                        <mat-option>Unselect</mat-option>
                        <mat-option value="option1"><mat-icon>home</mat-icon> Option 1</mat-option>
                        <mat-option value="option2">Option 2</mat-option>
                        <mat-option value="option3">Option 3</mat-option>
                        <mat-optgroup label="Products">
                            <mat-option *ngFor="let product of products" [value]="product">{{ product }}</mat-option>
                        </mat-optgroup>
                    </mat-select>
                </mat-form-field>  
            </div>
        </mat-expansion-panel>

        <!-- Dialog -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Dialog</mat-panel-title>
                <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <button mat-button (click)="openDialog()">Open Dialog</button>
            </div>
        </mat-expansion-panel>

        <!-- Autocomplete -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Autocomplete</mat-panel-title>
                <mat-panel-description>mat-autocomplete</mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <form class="example-form">
                    <mat-form-field appearance="fill">
                        <mat-label>Product</mat-label>
                        <input type="text"
                            placeholder="Pick one"
                            aria-label="Product"
                            matInput
                            [formControl]="myControl"
                            [matAutocomplete]="auto"
                            [(ngModel)]="selected">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <mat-option *ngFor="let product of filteredOptions | async" [value]="product">
                            {{product}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </form>
            </div>
        </mat-expansion-panel>

        <!-- Chips -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Chips</mat-panel-title>
                <mat-panel-description>mat-chip-list, mat-chip</mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <mat-form-field appearance="fill" style="width: 100%;">
                    <mat-label>Selected Products</mat-label>
                    <mat-chip-list #chipList aria-label="Product selection">
                      <mat-chip
                        *ngFor="let product of selectedProducts"
                        (removed)="removeProduct(product)">
                        {{product}}
                        <button matChipRemove>
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </mat-chip>
                      <input
                        placeholder="New product..."
                        #productInput
                        [formControl]="chipCtrl"
                        [matAutocomplete]="autoChip"
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="addProduct($event)">
                    </mat-chip-list>
                    <mat-autocomplete #autoChip="matAutocomplete" (optionSelected)="selectedProduct($event)">
                      <mat-option *ngFor="let product of filteredOptions2 | async" [value]="product">
                        {{product}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
            </div>
        </mat-expansion-panel>

        <!-- Date Picker -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Date Picker</mat-panel-title>
                <mat-panel-description>mat-datepicker, mat-datepicker-toggle</mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <mat-form-field appearance="fill">
                    <mat-label>Choose a date</mat-label>
                    <input matInput [matDatepicker]="picker">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="example-form-field" appearance="fill">
                    <mat-label>Date Range</mat-label>
                    <mat-date-range-input
                      [formGroup]="range"
                      [rangePicker]="campaignOnePicker"
                      [comparisonStart]="range.value.start"
                      [comparisonEnd]="range.value.end">
                      <input matStartDate placeholder="Start date" formControlName="start">
                      <input matEndDate placeholder="End date" formControlName="end">
                    </mat-date-range-input>
                    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
                    <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
                </mat-form-field>
                <span style="font-size: 10px;">
                    <mat-form-field appearance="fill">
                        <mat-label>Short Box Date</mat-label>
                        <input matInput [matDatepicker]="shortPicker">
                        <mat-datepicker-toggle matSuffix [for]="shortPicker"></mat-datepicker-toggle>
                        <mat-datepicker #shortPicker></mat-datepicker>
                    </mat-form-field>
                </span>
            </div>
        </mat-expansion-panel>

        <!-- Snackbar -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Snackbar</mat-panel-title>
                <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <button mat-button (click)="showSnackbar()">Show Snackbar</button>
            </div>
        </mat-expansion-panel>

        <!-- Tooltip -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Tooltip</mat-panel-title>
                <mat-panel-description>matTooltip</mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <button mat-button matTooltip="This is a tooltip">Hover over me</button>
                <button mat-button matTooltip="This is a tooltip" matTooltipPosition="after">position</button>
            </div>
        </mat-expansion-panel>
    </mat-tab>

    <!-- DevExpress -->
    <mat-tab label="DevExpress">
        
        <!-- HTML Editor -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>HTML Editor</mat-panel-title>
                <mat-panel-description>dx-html-editor</mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <dx-html-editor height="725px" [value]="html">
                    <dxo-toolbar multiline="true">
                      <dxi-item name="undo"></dxi-item>
                      <dxi-item name="redo"></dxi-item>
                      <dxi-item name="separator"></dxi-item>
                      <dxi-item name="size" [acceptedValues]="['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']"></dxi-item>
                      <dxi-item name="font" [acceptedValues]="['Arial','Courier New','Georgia','Impact','Lucida Console','Tahoma','Times New Roman','Verdana']"></dxi-item>
                      <dxi-item name="separator"></dxi-item>
                      <dxi-item name="bold"></dxi-item>
                      <dxi-item name="italic"></dxi-item>
                      <dxi-item name="strike"></dxi-item>
                      <dxi-item name="underline"></dxi-item>
                      <dxi-item name="separator"></dxi-item>
                      <dxi-item name="alignLeft"></dxi-item>
                      <dxi-item name="alignCenter"></dxi-item>
                      <dxi-item name="alignRight"></dxi-item>
                      <dxi-item name="alignJustify"></dxi-item>
                      <dxi-item name="separator"></dxi-item>
                      <dxi-item name="orderedList"></dxi-item>
                      <dxi-item name="bulletList"></dxi-item>
                      <dxi-item name="separator"></dxi-item>
                      <dxi-item name="header" [acceptedValues]="[false, 1, 2, 3, 4, 5]"></dxi-item>
                      <dxi-item name="separator"></dxi-item>
                      <dxi-item name="color"></dxi-item>
                      <dxi-item name="background"></dxi-item>
                      <dxi-item name="separator"></dxi-item>
                      <dxi-item name="link"></dxi-item>
                      <dxi-item name="image"></dxi-item>
                      <dxi-item name="separator"></dxi-item>
                      <dxi-item name="clear"></dxi-item>
                      <dxi-item name="codeBlock"></dxi-item>
                      <dxi-item name="blockquote"></dxi-item>
                      <dxi-item name="separator"></dxi-item>
                      <dxi-item name="insertTable"></dxi-item>
                      <dxi-item name="deleteTable"></dxi-item>
                      <dxi-item name="insertRowAbove"></dxi-item>
                      <dxi-item name="insertRowBelow"></dxi-item>
                      <dxi-item name="deleteRow"></dxi-item>
                      <dxi-item name="insertColumnLeft"></dxi-item>
                      <dxi-item name="insertColumnRight"></dxi-item>
                      <dxi-item name="deleteColumn"></dxi-item>
                    </dxo-toolbar>
                    <dxo-media-resizing [enabled]="true"> </dxo-media-resizing>
                    <!-- <dxo-image-upload [tabs]="currentTab" fileUploadMode="base64">
                    </dxo-image-upload> -->
                  </dx-html-editor>
            </div>
        </mat-expansion-panel>
        
        <!-- File Uploader -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>File Uploader</mat-panel-title>
                <mat-panel-description>dx-file-uploader</mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <dx-file-uploader
                    #fileUploader
                    uploadUrl="https://js.devexpress.com/Demos/NetCore/FileUploader/Upload"
                    [multiple]="true"
                    accept="*"
                    [(value)]="files"
                    uploadMode="instantly"
              ></dx-file-uploader>
              <button mat-button (click)="upload()">Upload</button>
            </div>
        </mat-expansion-panel>
        
        <!-- Popup -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Popup</mat-panel-title>
                <mat-panel-description>dx-popup</mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <button mat-button (click)="popup = !popup;">Show Popup</button>
                <dx-popup
                    [width]="660"
                    [height]="540"
                    [showTitle]="true"
                    title="Test"
                    [dragEnabled]="false"
                    [hideOnOutsideClick]="true"
                    [(visible)]="popup">
                <div *dxTemplate="let data of 'content'">
                    This is test content
                </div>
              </dx-popup>
            </div>
        </mat-expansion-panel>

        <!-- Gallery -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Gallery</mat-panel-title>
                <mat-panel-description>dx-gallery</mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <dx-gallery
                    [dataSource]="gallery"
                    [height]="300">
                </dx-gallery>
            </div>
        </mat-expansion-panel>

        <!-- Gantt Chart -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Gantt Chart</mat-panel-title>
                <mat-panel-description>dx-gantt</mat-panel-description>
            </mat-expansion-panel-header>
            <div>
            </div>
        </mat-expansion-panel>

    </mat-tab>

    <!-- GeoChart -->
    <mat-tab label="GeoChart">
        <geo-chart [Data]="worldData" [Options]="worldOptions"></geo-chart>
        <geo-chart [Data]="stateData" [Options]="stateOptions"></geo-chart>
    </mat-tab>

    <!-- HRT Table -->
    <mat-tab label="HRT Table">
        <hrt-tab [hasTabs]="true" [title]="'Dashboard'" [tabs]="tabsData"></hrt-tab>
        <!-- <div class="mat-elevation-z8" style="padding-top: 20px;">
            <hrt-table [tableId]="'assetTable'" [hasFilter]="true"><h1>Hello World</h1></hrt-table>
        </div> -->
    </mat-tab>

    <!-- Mat Table -->
    <!-- <mat-tab label="Mat Table">
        <div class="mat-elevation-z8" style="padding-top: 20px;">
            <hrt-mat-table></hrt-mat-table>
        </div>
    </mat-tab> -->

    <!-- Notifications -->
    <mat-tab label="Notifications">
      <button (click)="sendNotification()">Test Notification</button>
    </mat-tab>

</mat-tab-group>
