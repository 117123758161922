<img src="../assets/product-icons/Information.svg" style="margin-top: -7px;">
<h2 style="display: inline-block; margin-left: 10px; margin-right: 10px; vertical-align: center;">IntelliSOC Retrofit</h2>
<img src="../assets/icons/Back.svg" *ngIf="mode != viewMode && mode != editMode" (click)="back()" alt="Back" class="IconButton">
<img src="../assets/product-icons/History.svg" alt="History" class="IconButton" *ngIf="mode == viewMode" (click)="showHistory()">
<img src="../assets/product-icons/Edit.svg" alt="Edit" class="IconButton" *ngIf="mode == viewMode" (click)="showEdit()">

<div *ngIf="mode == viewMode" class="ScrollableMode">
    <div style="margin: 15px;" [innerHTML]="infoHtml">
    </div>
</div>
<div *ngIf="mode == editMode">
    <dx-html-editor height="570px" [(value)]="editInfoHtml">
        <dxo-toolbar multiline="true">
            <dxi-item name="undo"></dxi-item>
            <dxi-item name="redo"></dxi-item>
            <dxi-item name="separator"></dxi-item>
            <dxi-item name="size" [acceptedValues]="['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']"></dxi-item>
            <dxi-item name="font" [acceptedValues]="['Arial','Courier New','Georgia','Impact','Lucida Console','Tahoma','Times New Roman','Verdana']"></dxi-item>
            <dxi-item name="separator"></dxi-item>
            <dxi-item name="bold"></dxi-item>
            <dxi-item name="italic"></dxi-item>
            <dxi-item name="strike"></dxi-item>
            <dxi-item name="underline"></dxi-item>
            <dxi-item name="separator"></dxi-item>
            <dxi-item name="alignLeft"></dxi-item>
            <dxi-item name="alignCenter"></dxi-item>
            <dxi-item name="alignRight"></dxi-item>
            <dxi-item name="alignJustify"></dxi-item>
            <dxi-item name="separator"></dxi-item>
            <dxi-item name="orderedList"></dxi-item>
            <dxi-item name="bulletList"></dxi-item>
            <dxi-item name="separator"></dxi-item>
            <dxi-item name="header" [acceptedValues]="[false, 1, 2, 3, 4, 5]"></dxi-item>
            <dxi-item name="separator"></dxi-item>
            <dxi-item name="color"></dxi-item>
            <dxi-item name="background"></dxi-item>
            <dxi-item name="separator"></dxi-item>
            <dxi-item name="link"></dxi-item>
            <dxi-item name="image"></dxi-item>
            <dxi-item name="separator"></dxi-item>
            <dxi-item name="clear"></dxi-item>
            <dxi-item name="codeBlock"></dxi-item>
            <dxi-item name="blockquote"></dxi-item>
            <dxi-item name="separator"></dxi-item>
            <dxi-item name="insertTable"></dxi-item>
            <dxi-item name="deleteTable"></dxi-item>
            <dxi-item name="insertRowAbove"></dxi-item>
            <dxi-item name="insertRowBelow"></dxi-item>
            <dxi-item name="deleteRow"></dxi-item>
            <dxi-item name="insertColumnLeft"></dxi-item>
            <dxi-item name="insertColumnRight"></dxi-item>
            <dxi-item name="deleteColumn"></dxi-item>
        </dxo-toolbar>
        <dxo-media-resizing [enabled]="true"> </dxo-media-resizing>
        <!-- <dxo-image-upload [tabs]="currentTab" fileUploadMode="base64">
        </dxo-image-upload> -->
    </dx-html-editor>

    <button mat-raised-button color="primary" (click)="back()" class="Button">Cancel</button>
    <button mat-raised-button color="primary" (click)="update()" class="Button">Save</button>
</div>
<div *ngIf="mode == historyMode" class="ScrollableMode">
    <table mat-table [dataSource]="historyItems" multiTemplateDataRows class="mat-elevation-z8">
        <ng-container matColumnDef="Id">
            <th mat-header-cell *matHeaderCellDef>Version #</th>
            <td mat-cell *matCellDef="let historyItem">{{historyItem.VersionNumber + (historyItem.VersionActive == true ? " (Active)" : "")}}</td>
        </ng-container>
        <ng-container matColumnDef="ModifiedDate">
            <th mat-header-cell *matHeaderCellDef>Modified Date</th>
            <td mat-cell *matCellDef="let historyItem">{{formatDate(historyItem.VersionDate)}}</td>
        </ng-container>
        <ng-container matColumnDef="UserName">
            <th mat-header-cell *matHeaderCellDef>User Name</th>
            <td mat-cell *matCellDef="let historyItem">{{historyItem.VersionUserName}}</td>
        </ng-container>
        <ng-container matColumnDef="Comments">
            <th mat-header-cell *matHeaderCellDef>Comment</th>
            <td mat-cell *matCellDef="let historyItem">{{historyItem.VersionComment}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let historyItem; columns: columnsToDisplay;" (click)="historyItemClicked(historyItem)" class="TableRowClickable">
        </tr>
    </table>
    
</div>
<div *ngIf="mode == historyItemMode" class="ScrollableMode">
    <hr>
    <table>
        <tr>
            <td><b>Version #:</b></td>
            <td>{{ selectedHistoryItem?.VersionNumber + (selectedHistoryItem?.VerisonActive == true ? " (Active)" : "") }}</td>
        </tr>
        <tr>
            <td><b>Modified Date:</b></td>
            <td>{{ formatDate(selectedHistoryItem?.VersionDate) }}</td>
        </tr>
        <tr>
            <td><b>User Name:</b></td>
            <td>{{ selectedHistoryItem?.VersionUserName }}</td>
        </tr>
        <tr>
            <td><b>Comment:</b></td>
            <td>{{ selectedHistoryItem?.VersionComment }}</td>
        </tr>
    </table>
    <hr>
    <div style="margin: 15px;" [innerHTML]="historyInfoHtml">
    </div>
    <hr>
    
    <button mat-raised-button color="primary" (click)="back()" class="Button">Cancel</button>
    <button mat-raised-button color="primary" (click)="revert()" class="Button"
            *ngIf="selectedHistoryItem?.VersionActive == false">Change to this Version</button>
    
</div>