export enum Role {
    Guest = 0,
    Customer = 1000,
    PreferredCustomer = 2000,
    ExternalSales = 3000,
    InternalSales = 4000,
    InternalSupport = 5000,
    InternalAdmin = 6000,
    DevAdmin = 7000
};

export const Roles = [
    { Id: Role.Guest, Name: "Guest" },
    { Id: Role.Customer, Name: "Customer" },
    { Id: Role.PreferredCustomer, Name: "Prefered Customer" },
    { Id: Role.ExternalSales, Name: "External Sales" },
    { Id: Role.InternalSales, Name: "Internal Sales" },
    { Id: Role.InternalSupport, Name: "Internal Support" },
    { Id: Role.InternalAdmin, Name: "Internal Admin" },
    { Id: Role.DevAdmin, Name: "Dev Admin" }
];