import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs';
import { Asset } from 'src/app/shared/data/data.generated';
import {
  GetCustomTypeIcon,
  GetCustomTypeIconWidth,
  GetCustomTypeText,
} from 'src/app/shared/data/product-types';
import { ApiService } from 'src/app/shared/services/api.service';
import { Table } from 'src/app/shared/services/api/table';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { WebsocketService } from 'src/app/shared/services/websocket.service';

@Component({
  selector: 'gea-asset-table',
  templateUrl: './asset-table.component.html',
  styleUrls: ['./asset-table.component.scss'],
})
export class AssetTableComponent {
  @Input() assetTable: Table<Asset> = new Table<Asset>(this.loader);
  @Input() showMotorStatus?: boolean = false;
  deviceData: any;
  annunStatuses: any = {};
  socketInterval: any;

  public getCustomTypeText = GetCustomTypeText;
  public getCustomTypeIcon = GetCustomTypeIcon;
  public getCustomTypeIconWidth = GetCustomTypeIconWidth;

  public assetColumns: string[] = [
    'Icon',
    'AssetTypeId',
    'GeaReferenceName',
    'Manufacturer',
    'Model',
    'AlternativeModel',
    'SerialNumber',
    'SalesOrderNumber',
    'Annunciation',
  ];
  public shutdownStatusMapping: any = {
    0: 'Normal',
    1: 'Warning',
    2: 'Shutdown',
  };
  isSocketOpen: boolean = false;
  motorStatusText: string;

  constructor(
    private router: Router,
    private websocketService: WebsocketService,
    private api: ApiService,
    private loader: LoaderService
  ) {}

  ngOnInit() {
    this.showMotorStatus ? this.assetColumns.push('MotorStatus') : null;
    this.pingWebSocket();
    this.assetTable.inputSubject.pipe(debounceTime(500)).subscribe((value) => {
      // this.searchText.emit(value);
      this.assetTable.SearchUpdated();
    });
  }

  pingWebSocket() {
    const bucketList: string = this.websocketService.connectedBuckets.join(',');
    const messageStr = JSON.stringify({
      deviceList: bucketList,
    });
    this.socketInterval = setInterval(() => {
      this.websocketService.send(messageStr);
      this.isSocketOpen = true;
    }, 5000);
    this.websocketService.liveData.subscribe((dataReceived) => {
      if (dataReceived && Object.keys(dataReceived).length > 0) {
        this.deviceData = dataReceived;
        this.extractAnnunStatuses();
      }
    });
  }

  extractAnnunStatuses() {
    Object.keys(this.deviceData).forEach((key) => {
      const data = this.deviceData[key];
      const obj = {
        annun_status: data['compressor_Annunciation_Status'],
        motor_status: data['motor_Running'],
      };
      this.annunStatuses[key] = obj;
    });
  }

  getAnnunStatusValue(connectionId: string) {
    if (connectionId) {
      return this.annunStatuses[connectionId]
        ? this.annunStatuses[connectionId].annun_status
        : null;
    } else {
      return null;
    }
  }

  getAnnunImage(annun_status: number) {
    if(annun_status != null){
      switch (annun_status) {
        case 0:
          return '../../../assets/icons/Check_Circle.svg';
        case 1:
          return '../../../assets/icons/Warning.svg';
        case 2:
          return '../../../assets/icons/Error.svg';
        default:
          return null;
      }

    } else {
      return '../../../assets/icons/NotAvailable.png';
    }
    
  }

  getAnnunText(annun_status: number) {
    if(annun_status != null){
      return this.shutdownStatusMapping[annun_status];
    } else {
      return 'No Data';
    }

    
  }

  getMotorStatus(key: string) {
    if (!this.deviceData) return;
    if (this.websocketService.connectedAssets.includes(key)) {
      if(this.annunStatuses[key]){
        if (this.annunStatuses[key]?.motor_status) {
          this.motorStatusText = 'Running';
          return '../assets/icons/Check_Circle.svg';
        } else if (!this.annunStatuses[key]?.motor_status) {
          this.motorStatusText = 'Stopped';
          return '../assets/product-icons/Error.svg';
        }
      } else {
        this.motorStatusText = 'No Data';
        return '../assets/icons/NotAvailable.png';
      }
    }
    return null;
  }

  openAsset(id: number) {
    this.router.navigate(['/asset-details', { id: id }]);
  }

  openRealTimeForAsset(id: number, externalId: string) {
    if (this.getAnnunStatusValue(externalId) != null) {
      this.router.navigate(['/asset-details', { id: id, realTime: true }]);
    } else {
      this.openAsset(id);
    }
  }


  searchChange(searchText: string) {
    if (searchText.trim() !== this.assetTable.inputSubject.getValue() || searchText === '') {
      this.assetTable.inputSubject.next(searchText);
    }
  }

  exportTable() {
    console.log(this.assetTable.View);
    const listOptions = this.assetTable.View;
    listOptions.Page = 0;
    listOptions.HasPaging = false;
    this.api.Assets.ExportAssets(
      listOptions,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      'Asset'
    );
  }

  ngOnDestroy() {
    clearInterval(this.socketInterval);
  }
}
