export enum UserNotificationType {
  AllFeedback = 0,
  CompanyFeedback = 1,
  ProductFeedback = 2,
  CustomerFeedback = 3,
  IotFeedback = 4,
};

export const UserNotificationTypes = [
  { Id: UserNotificationType.AllFeedback, Name: "All Feedback" },
  { Id: UserNotificationType.CompanyFeedback, Name: "Company Feedback" },
  { Id: UserNotificationType.ProductFeedback, Name: "Product Feedback" },
  { Id: UserNotificationType.CustomerFeedback, Name: "Customer Feedback" },
  { Id: UserNotificationType.IotFeedback, Name: "Iot Feedback" },
];