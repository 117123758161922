import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
  selector: 'app-description-popup',
  templateUrl: './description-popup.component.html',
  styleUrls: ['./description-popup.component.scss']
})
export class DescriptionPopupComponent {

  renderedContent: SafeHtml;
  title: string;
  iconType: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer
  ) {
    this.renderedContent = this.sanitizer.bypassSecurityTrustHtml(data.content);
    this.title =data.title;
    this.iconType= data.iconType;
  }

  getIcon(type: number) {
    if (type === 0) return 'insert_comment';
    if (type === 1) return 'library_add';
    if (type === 2) return 'bug_report';
    return '';
  }
  
  getIconStyle(type: number) {
    if (type === 0) return 'color: rgb(0, 0, 150);';
    if (type === 1) return 'color: rgb(0, 150, 0);';
    if (type === 2) return 'color: rgb(200, 0, 0);';
    return '';
  }
}

