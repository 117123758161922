export enum ServiceType {
    Unknown = 0,
    Maintenance = 1,
    Vibration = 2,
    Inspection = 3,
    Service = 4,
    Rebuild = 5,
    OilAnalysis = 6,
};

export const ServiceTypes = [
    { Id: ServiceType.Unknown, Name: "Unknown" },
    { Id: ServiceType.Maintenance, Name: "Maintenance" },
    { Id: ServiceType.Vibration, Name: "Vibration" },
    { Id: ServiceType.Inspection, Name: "Inspection" },
    { Id: ServiceType.Service, Name: "Service" },
    { Id: ServiceType.Rebuild, Name: "Rebuild" },
    { Id: ServiceType.OilAnalysis, Name: "Oil Analysis" },
];