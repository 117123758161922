import { Observable } from 'rxjs';
import { ApiService } from "../api.service";
import { EventHubStream } from "../../data/data.generated";

export class IOT {
    constructor(private _api: ApiService) {
    }

    public GetDeviceInformation(pk: string): Observable<EventHubStream> {
        var params: any = {
            "DeviceId": pk
        };
        
        return this._api.Get<EventHubStream>("GetDeviceInformation", params);
    }

    // public GetDeviceHubConnection(pk: string): signalR.HubConnection {
    //     return this._api.GetHubConnection();
    // }

    public GetFluxSocketURL(hubName: string): Observable<string> {
        return this._api.Get<string>("GetFluxSocketURL", { "HubName": hubName });
    }
}