import { Component, OnInit } from '@angular/core';
import { ImagesService } from 'src/app/shared/services/images.service';

@Component({
    selector: 'asset-service-record',
    templateUrl: './asset-service-record.component.html',
    styleUrls: ['./asset-service-record.component.scss']
})
export class AssetServiceRecordComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
