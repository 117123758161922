import { Component, OnInit } from '@angular/core';
import { Asset } from 'src/app/shared/data/data.generated';

@Component({
  selector: 'app-intellisoc-general',
  templateUrl: './intellisoc-general.component.html',
  styleUrls: ['./intellisoc-general.component.scss']
})
export class IntellisocGeneralComponent implements OnInit {

    constructor() { }
    
    public withControlPanel = IntelliSocType.WithControlPanel;

    public selectedType: IntelliSocType = IntelliSocType.WithControlPanel;
    public availableTypes: { value: IntelliSocType, text: string }[] = [
        { value: IntelliSocType.WithControlPanel, text: "IntelliSOC Retrofit with existing Control Panel" },
        { value: IntelliSocType.ValveStationOnly, text: "IntelliSOC Retrofit Valve Station only" }
    ];

    public selectedControlPanel: ControlPanelType = ControlPanelType.GForce;
    public availableControlPanels: { value: ControlPanelType, text: string }[] = [
        { value: ControlPanelType.GForce, text: "GForce" },
        { value: ControlPanelType.GeaOmni, text: "GEA Omni" }
    ];

    public selectedMountingKit: boolean = true;
    public availableMountingKits: { value: boolean, text: string }[] = [
        { value: true, text: "Included" },
    ];

    public showAssetFinder: boolean = false;
    public selectedCompressor: Asset | undefined = undefined;
    public selectedCompressorDescription: string = "Not Selected";

    ngOnInit(): void {
    }
    
    selectCompressor() {
        this.showAssetFinder = true;
    }

    compressorSelected(asset: Asset | undefined) {
        if (asset) {
            this.selectedCompressor = asset;
            this.selectedCompressorDescription = asset.Manufacturer + " - " + asset.Model + " - " + asset.SerialNumber + " - " + asset.SalesOrderNumber;
        }
        this.showAssetFinder = false;
    }

}

export enum IntelliSocType {
    WithControlPanel = 1,
    ValveStationOnly = 2
}

export enum ControlPanelType {
    GeaOmni = 1,
    GForce = 2
}