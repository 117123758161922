import {
  ColumnRendererComponent,
  DateRendererComponent,
} from '@gea/digital-ui-lib';

const assetTable: TableDef[] = [
  // { displayName: '', key: 'icon'},
  {
    displayName: '',
    key: 'ProductTypeId',
    sortable: false,
  },
  {
    displayName: 'Type',
    key: 'ProductTypeIdText',
    hasFilter: true,
    sortable: false,
  },
  {
    displayName: 'Reference Name',
    key: 'GeaReferenceName',
  },
  {
    displayName: 'Manufacturer',
    key: 'Manufacturer',
    hasFilter: true,
  },
  { displayName: 'Model', key: 'Model', hasFilter: true },
  { displayName: 'Alt Model', key: 'AlternativeModel', hasFilter: true },
  {
    displayName: 'Serial Number',
    key: 'PurchaseOrderNumber',
  },
  { displayName: 'Project Number', key: 'SalesOrderNumber' },
  { displayName: 'Annunciation', key: 'OperatingStatus', sortable: false },
  { displayName: 'Motor Status', key: 'MotorStatus', sortable: false },
];

const siteTable: TableDef[] = [
  {
    displayName: 'Name',
    key: 'Name',
  },
  {
    displayName: 'Address',
    key: 'Address',
  },
  {
    displayName: 'City',
    key: 'City',
  },
  {
    displayName: 'State',
    key: 'State',
    hasFilter: true,
  },
  {
    displayName: 'Zip',
    key: 'Zip',
  },
  {
    displayName: 'Account Numbers',
    key: 'AxAccountNumbers',
  },
  {
    displayName: 'Project Numbers',
    key: 'ProjectNumbers',
  },
];

const serviceTable: TableDef[] = [
  {
    displayName: '',
    key: 'ProductTypeId',
    hasFilter: true,
    sortable: false,
  },
  {
    displayName: 'Location',
    key: 'Location',
    hasFilter: true,
  },
  {
    displayName: 'Manufacturer',
    key: 'Manufacturer',
    hasFilter: true,
  },
  { displayName: 'Model', key: 'Model', hasFilter: true },
  { displayName: 'Alt Model', key: 'AlternativeModel' },
  {
    displayName: 'Date',
    key: 'Date',
    renderer: {
      component: DateRendererComponent as ColumnRendererComponent<unknown>,
      config: {
        format: 'shortDate',
        locale: 'en-US',
      },
    },
  },
  { displayName: 'Type', key: 'ServiceTypeText' },
  {
    displayName: 'Condition',
    key: 'ConditionId',
    sortable: false,
    hasFilter: true,
  },
  { displayName: 'Report', key: 'ReportLocation', sortable: false },
  { displayName: 'Notes', key: 'VisitNotes', sortable: false },
];

const assetServiceTable: TableDef[] = [
  {
    displayName: 'Date',
    key: 'Date',
    hasFilter: true,
    renderer: {
      component: DateRendererComponent as ColumnRendererComponent<unknown>,
      config: {
        format: 'shortDate',
        locale: 'en-US',
      },
    },
  },
  { displayName: 'Type', key: 'TypeIdText' },
  { displayName: 'Condition', key: 'ConditionId', hasFilter: true },
  { displayName: 'C1', key: 'C1Id' },
  { displayName: 'C2', key: 'C2Id' },
  { displayName: 'C3', key: 'C3Id' },
  { displayName: 'C4', key: 'C4Id' },
  { displayName: 'M1', key: 'M1Id' },
  { displayName: 'M2', key: 'M2Id' },
  { displayName: 'Technician', key: 'Technician' },
  { displayName: 'Notes', key: 'showNotes' },
  { displayName: 'Report', key: 'ReportLocation' },
  { displayName: 'Actions', key: 'actions'}
];

export const tableDefinitions = {
  assetTableDef: assetTable,
  siteTableDef: siteTable,
  serviceTableDef: serviceTable,
  assetServiceTableDef: assetServiceTable
};

export interface TableDef {
  displayName: string;
  key: string;
  hasFilter?: boolean;
  sortable?: boolean;
  renderer?: any;
}
