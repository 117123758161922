import { ChangeDetectorRef, Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Meta } from '@angular/platform-browser';
import { CompressorMetadata, FieldType, Metadata, SIUnits, USUnits } from 'src/app/shared/data/compressor-metadata';
import { Asset, FluxHubStream } from 'src/app/shared/data/data.generated';
import { ConversionService } from 'src/app/shared/services/conversion.service';
import { WebsocketService } from 'src/app/shared/services/websocket.service';
import { ChartContainerComponent } from '../chart-container/chart-container.component';

@Component({
  selector: 'asset-real-time-data',
  templateUrl: './asset-real-time-data.component.html',
  styleUrls: ['./asset-real-time-data.component.scss']
})
export class AssetRealTimeDataComponent implements OnInit {

  constructor(
    private conversionService: ConversionService,
    public websocketService: WebsocketService,
    public dialog: MatDialog) { }

  public data: FluxHubStream;
  public fetchingData: boolean = true;
  public operatingStatusCards: Metadata[] = [];
  public energyCards: Metadata[] = [];
  public temperatureCards: Metadata[] = [];
  public pressureCards: Metadata[] = [];
  public miscellaneousCards: Metadata[] = [];

  public motorStatusText: string;
  isUSUnits = true;

  @Input() assetData: Asset | undefined;

  @Input() set setData(realTimeData: FluxHubStream) {
    this.data = realTimeData;
    this.updateRealTimeData();
  };

  @Input() set isFetchingData(isFetched: boolean) {
    this.fetchingData = isFetched;
  }

  @Input() isConnectedAsset: boolean = false;

  public shutdownStatus: string = "";
  public time: string = "";
  public showShutDownIcon: boolean = false;
  public shutdownStatusMapping: any = {
      0: 'Normal',
      1: 'Warning',
      2: 'Shutdown'
  }

  ngOnInit(): void {
    this.updateRealTimeData();
  }

  updateRealTimeData() {

    this.operatingStatusCards = CompressorMetadata.operating_status_properties.filter(prop => this.evaluateCondition(prop.displayConditions));
    this.energyCards = CompressorMetadata.energy_properties;
    this.temperatureCards = CompressorMetadata.temperature_properties;
    this.pressureCards = CompressorMetadata.pressureProperties;
    this.miscellaneousCards = CompressorMetadata.miscProperties;

    if (!this.data) return;
    // console.log("Data received in Real-time", this.data);
    this.time = new Date(this.data['sourceTimestamp']).toLocaleString();
    this.shutdownStatus = this.shutdownStatusMapping[this.data['compressor_Annunciation_Status']];
    this.data = this.formatNumbers(this.data);
    // this.changeDetectorRef.detectChanges();
    }

    formatNumbers(data: any) {
      if (!data) return;
      Object.keys(this.data).forEach(key => {
        if (data[key] && typeof this.data[key] === 'number') {
          data[key] = (this.data[key] as number).toLocaleString('en-US', { maximumFractionDigits: 1});
        }
      })
      return data;
    }

    formatNumber(value: number) {
      if (value && typeof value === 'number') {
        let formattedVal = value.toLocaleString('en-US', { maximumFractionDigits: 1});
        return formattedVal;
      }
      return value;
    }

    getShutdownImage() {
      if (this.shutdownStatus == 'Shutdown') return "../assets/product-icons/Error.svg" ;
      if (this.shutdownStatus == 'Warning') return "../assets/icons/Warning.svg" ;
      return "../assets/icons/Check_Circle.svg";
    }
    
    getValue(key: string, card: Metadata) {
      if (!this.data) return;

      if (this.isUSUnits) {
        if (card.fieldType == FieldType.Temperature) {
          const fromUnit = 'C';
          const toUnit = 'F';
          const value = this.conversionService.convertTemperature(this.data[key], fromUnit, toUnit);
          return this.formatNumber(value);
        }
        if (card.fieldType == FieldType.Pressure) {
          const fromUnit = 'barA';
          const toUnit = 'Psia';
          const value = this.conversionService.convertPressure(this.data[key], fromUnit, toUnit);
          return this.formatNumber(value);
        }
      }

      return this.data[key];
    }

    getUnit(card: Metadata) {
      switch(card.fieldType) {
        case FieldType.Temperature:
          return (this.isUSUnits) ? USUnits.temperature: SIUnits.temperature;
        case FieldType.Pressure:
          return (this.isUSUnits) ? USUnits.pressure: SIUnits.pressure;
        default:
          return card.unit;
      }
    }

    getMotorStatus(key: string) {
      if (!this.data) return;
      if (this.data[key]) {
        this.motorStatusText = 'Running';
        return "../assets/icons/Check_Circle.svg";
      } else {
        this.motorStatusText = 'Stopped';
        return "../assets/product-icons/Error.svg";
      }
    }

    assignCardType(unit: string | undefined) {
      return (!unit) ? 'card-type-2': 'card-type-1';
    }

    evaluateCondition(displayConditions: any[] | undefined) {
      if (!this.data) return true;
      // if no display condition exists, show the element.
      if (!displayConditions || displayConditions.length == 0) {
        return true;
      } else {
        return displayConditions.every(condition => {
          switch(condition.op) {
            case '==':
              return this.data[condition.key] === condition.value;
            case '!=':
              return this.data[condition.key] != condition.value;
            case '>':
              return this.data[condition.key] > condition.value;
            default: 
              return false;

            // add >, <, in the future.
          }
        });
      }
    }

    openChartModal(card: Metadata) {

      if (!this.data) return;

      const dialogRef = this.dialog.open(ChartContainerComponent, {
        width: '80vw',
        height: '600px',
        data: {
          deviceId: this.data['pk'],
          field: card.key,
          fieldName: card.displayName,
          assetReferenceName: this.assetData?.GeaReferenceName,
          fieldType: card.fieldType,
          fieldUnit: this.getUnit(card),
          isIPSystem: this.isUSUnits
      },
      });
  
      dialogRef.afterClosed().subscribe((result) => {
      //   console.log(`Dialog result: ${result}`);
      });
      // if (card.key == 'capacity') {

      // }
    
    }
 }
