<div class="title-div">
    <div class="bookmark-title">
      <span class="heading-left">Bookmarks</span>
    </div>
  </div>
  <br />
  
  <table class="TableHeader">
    <tr>
      <td>
        <mat-form-field class="TableHeaderSearch">
          <mat-label style="color: var(--geaui-vibrant-blue)"
            >Search Bookmarks</mat-label
          >
          <input
            type="text"
            matInput
            [(ngModel)]="bookmarkTable.View.Search"
            (ngModelChange)="searchChange($event)"
          />
          <mat-icon style="color: var(--geaui-vibrant-blue)" matSuffix
            >search</mat-icon
          >
        </mat-form-field>
      </td>
    </tr>
  </table>




<table
    mat-table
    [dataSource]="bookmarkTable.Result.Data"
    matSort
    matSortDisableClear
    [matSortActive]="bookmarkTable.View.SortColumn"
    (matSortChange)="bookmarkTable.RefreshData($event)"
>
  <ng-container matColumnDef="BookmarkType">
    <th mat-header-cell *matHeaderCellDef>Location</th>
    <td mat-cell *matCellDef="let element" (click)="onClick(element.URL)">{{ element.BookmarkType }}
    </td>
  </ng-container>

  <ng-container matColumnDef="BookmarkName">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element" (click)="onClick(element.URL)">{{ element.BookmarkName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="URL">
    <th mat-header-cell *matHeaderCellDef>URL</th>
    <td mat-cell *matCellDef="let element" (click)="onClick(element.URL)">{{ element.URL }}
    </td>
  </ng-container>

  <ng-container matColumnDef="Actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let ele">
      <div class="action-buttons">
        <span (click)="editBookmark(ele)">
          <mat-icon matSuffix class="ActionIcon" title="Edit"><geaui-icon-v2
            file="16px_edit"
            [colorProp]="'stroke'"
          ></geaui-icon-v2></mat-icon>
        </span>
        <span (click)="deleteBookmark(ele)">
          <mat-icon matSuffix class="ActionIcon" title="Delete"><geaui-icon-v2
            file="16px_remove"
            [colorProp]="'stroke'"
          ></geaui-icon-v2></mat-icon>
        </span>
      </div>
      
    </td>
  </ng-container>

  
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns;"
    style="cursor: pointer"
  ></tr>
</table>

<mat-paginator
  [length]="bookmarkTable.Result.Count"
  [pageSizeOptions]="bookmarkTable.View.PageSizeOptions"
  [pageSize]="bookmarkTable.View.PageSize"
  [pageIndex]="bookmarkTable.View.Page"
  (page)="bookmarkTable.RefreshData($event)"
>
  >
</mat-paginator>