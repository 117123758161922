import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'derived-pricing-york',
  templateUrl: './derived-pricing-york.component.html',
  styleUrls: ['./derived-pricing-york.component.scss']
})
export class DerivedPricingYorkComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
