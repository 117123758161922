import { Component, OnInit } from '@angular/core';

import { Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import {
  NavBarMode,
  NavigationItem,
  OrganizationService,
  RouterSidePanel,
  SetEnvironment,
  SidePanel,
  UserService,
  UserState,
} from '@gea/digital-ui-lib';

import { environment } from '../environments';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, combineLatest} from 'rxjs';
import { Location } from '@angular/common';
import { ApiService } from './shared/services/api.service';
import { WebsocketService } from './shared/services/websocket.service';
import { UserApplicationFeature } from './shared/data/data.generated';
import { ApplicationFeature } from './shared/data/application-feature';
import { Role } from './shared/data/roles';
import { LoaderService } from './shared/services/loader.service';
import { sidebarNavigation } from './sidebar-navigation';
import { TableView } from './shared/services/api/table';
import { ConfigService } from './shared/services/config.service';
import { SmartLinkService } from './shared/services/smart-link.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'HRT Connect';
  tokenReady = false;
  userReady = false;
  noAuth = true;
  year = new Date().getFullYear();
  navbarMode: NavBarMode = NavBarMode.NONE;
  items: any[] = [];
  canShowDemos: boolean = false;
  arrowIcon = 'arrow-down';
  showBackButton = true;
  initialRoute = '';
  showLoadingIcon = false;
  loadingMessage: string = 'Loading HRT Connect...';
  navItems: RouterSidePanel[] = [];
  botItems: SidePanel[] = [];
  testBool: boolean = true;
  listOptions: TableView = new TableView();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private location: Location,
    private translate: TranslateService,
    private userService: UserService,
    private orgService: OrganizationService,
    private websocketService: WebsocketService,
    private api: ApiService,
    private loader: LoaderService,
    private config: ConfigService,
    private smartLinkService: SmartLinkService,
  ) {
    this.api.StoreRedirectRoute();
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.noAuth = this.useNoAuth((event as NavigationEnd).url);
        // Updating metadata on current route
        let currentRoute = this.route.root;
        while (currentRoute.firstChild) {
          currentRoute = currentRoute.firstChild;
        }
        currentRoute.data.subscribe((data) => {
          const title = data['title'];
          const description = data['description'];
          if (title) {
            this.smartLinkService.updateMetaData(title);
          }
        });

      });
    this.loader.loadingMessage.subscribe((message: string) => {
      this.loadingMessage = message;
    });
    this.loader.isLoaderVisible.subscribe((isVisible) => {
      this.showLoadingIcon = isVisible;
    });
  }

  ngOnInit() {
    this.items = [];
    if (environment.name) {
      this.store.dispatch(new SetEnvironment(environment.name));
    }

    combineLatest([
      this.store.select(UserState.user),
      this.store.select(UserState.token),
    ]).subscribe(([user, token]) => {
      let userReceived = false;
      let tokenReceived = false;
      if (user.userId && user.userId != '') {
        userReceived = true;
        if (user.userId !== sessionStorage.getItem('GeaIdUserId')) {
          this.clearUserFromStorage();
        }
        if (
          sessionStorage.getItem('GeaIdUserId') &&
          sessionStorage.getItem('AppFeatures')
        ) {
          this.loadUserFromStorage();
        } else {
          this.saveUserToStorage(user);
        }
      }
      if (token) {
        tokenReceived = true;
      }
      this.api.AuthToken = token;
      this.api.IsLoggedIn = token != undefined && token != '';
      this.tokenReady = token !== '';
      if (userReceived && tokenReceived) {
        this.connectWebSocket();
        this.getConfigData();
        this.loader.resetLoadingMessage();
      }
    });

    this.translate.use('en-US');
  }

  saveUserToStorage(user: any) {
    this.userService.getUser(user.userId).subscribe((userData) => {
      sessionStorage.setItem('GeaIdUserId', user.userId ?? '');
      sessionStorage.setItem('FullName', user.userName);
      sessionStorage.setItem('Email', userData.email);
      this.api.GeaIdUserId = sessionStorage.getItem('GeaIdUserId') ?? '';
      this.api.FullName = sessionStorage.getItem('FullName') ?? '';
      this.api.Email = sessionStorage.getItem('Email') ?? '';
      this.api.Users.GetUserApplicationFeatures(0, userData.email).subscribe(
        (x) => {
          sessionStorage.setItem(
            'UserId',
            (x.Features.length > 0 ? x.Features[0].UserId : 0).toString()
          );
          sessionStorage.setItem('RoleId', x.RoleId.toString());
          sessionStorage.setItem('AppFeatures', JSON.stringify(x.Features));
          this.loadUserFromStorage();
          this.api.CheckLastGuardStillValid();
          // For scenario where user data isn't available in session storage.
          this.connectWebSocket();
          this.getConfigData();
          this.api.Bookmark.GetBookmarks(this.listOptions).subscribe((x) => {
            const userBookmarks = x.Data.map((bm) => ({
              ForeignKey: bm.ForeignKey,
              BookmarkType: bm.BookmarkType,
            }));
            sessionStorage.setItem(
              'UserBookmarks',
              JSON.stringify(userBookmarks)
            );
          });
          this.userService.getUser(user.userId).subscribe((geaIdUserData) => {
            combineLatest(
              (geaIdUserData.memberships ?? []).map((y) =>
                this.orgService.getOrgaDetails(y.organizationId)
              )
            ).subscribe((y) => {
              let customerNumbers = y
                .filter((z) => (z.customerNumber ?? '').length > 0)
                .map((z) => z.customerNumber)
                .sort()
                .join(',');
              if (customerNumbers != x.CustomerNumbers) {
                this.api.Users.UpdateUserCustomerNumbers(
                  userData.email,
                  customerNumbers
                ).subscribe((newAppFeatures) => {
                  sessionStorage.setItem(
                    'AppFeatures',
                    JSON.stringify(newAppFeatures.Features)
                  );
                  this.loadUserFromStorage();
                  this.api.CheckLastGuardStillValid();
                });
              }
            });
          });
        }
      );
    });
  }

  loadUserFromStorage() {
    this.api.GeaIdUserId = sessionStorage.getItem('GeaIdUserId') ?? '';
    this.api.FullName = sessionStorage.getItem('FullName') ?? '';
    this.api.Email = sessionStorage.getItem('Email') ?? '';
    this.api.UserId = parseInt(sessionStorage.getItem('UserId') ?? '0');
    this.api.Role = <Role>parseInt(sessionStorage.getItem('RoleId') ?? '0');
    this.api.AppFeatures = <UserApplicationFeature[]>(
      JSON.parse(sessionStorage.getItem('AppFeatures') ?? '[]')
    );

    this.items = sidebarNavigation.filter(
      (y) =>
        y.isInMenu &&
        (y.feature == ApplicationFeature.Home ||
          this.api.CanViewFeature(y.feature))
    );
    this.canShowDemos = this.api.CanViewFeature(ApplicationFeature.Assets);
    this.userReady = true;
    this.api.CheckRedirectRoute();
  }

  clearUserFromStorage() {
    sessionStorage.removeItem('GeaIdUserId');
    sessionStorage.removeItem('FullName');
    sessionStorage.removeItem('Email');
    sessionStorage.removeItem('UserId');
    sessionStorage.removeItem('AppFeatures');
  }

  getConfigData() {
    let configPages = 'Homepage';
    this.loader.showLoadingIcon();
    this.api.Config.GetConfigDetails(configPages).subscribe({
      next: (data: any) => {
        this.config.updateConfig(data);
      },
      error: (err) => {
        console.log('Error: ', err);
      },
      complete: () => {
        this.loader.hideLoadingIcon();
      },
    });
  }

  connectWebSocket() {
    if (!this.websocketService.isSocketConnected) {
      if (
        this.api.FullName &&
        this.api.Email &&
        this.api.GeaIdUserId &&
        this.api.AuthToken
      )
        this.websocketService.getSocketUrl('FluxHub').subscribe((url) => {
          this.websocketService.socketUrl = url;
          this.websocketService.connect(url);
        });
    }
  }

  useNoAuth(url: string) {
    return url === '/home';
  }

  navigate(item: any) {
    this.router.navigate([item.path]);
  }

  goBack() {
    if (window.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigate(['/home']);
    }
  }

  openDataprivacy() {
    window.open('https://www.gea.com/en/legal/data-protection-notice/');
  }

  openTermsAndConditions() {
    window.open(
      '../../assets/documents/GEA Portal_Terms of Use_February 2024.pdf',
      '_blank'
    );
  }

  openImprint() {
    window.open('../../assets/documents/Imprint.pdf', '_blank');
  }

  setContentWidth() {
    if (document.querySelector('.slim-bar')) {
      // exists.
      return '100vw - 60px';
    } else {
      return '100vw - 250px';
    }
  }
}
