import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyTooltipModule as MatToolTipModule } from '@angular/material/legacy-tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'src/app/material.module';
import { Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'bookmark-popup',
  templateUrl: './hrt-bookmark.component.html',
  styleUrls: ['./hrt-bookmark.component.scss']
})
export class HrtBookmarkComponent {
  constructor( 
    public dialogRef: MatDialogRef<HrtBookmarkComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    // console.log(this.data.url)
  }

  closePopup(){
    this.dialogRef.close(false)
  }


}

@NgModule({
  imports: [
    RouterModule,
    MaterialModule,
    FormsModule,
    BrowserModule,
    MatToolTipModule,
  ],
  exports: [HrtBookmarkComponent],
  declarations: [HrtBookmarkComponent],
})
export class HrtBookmarkModule {}
