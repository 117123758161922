<div class="mat-elevation-z8">
  <div class="table-header">
    <div class="table-header-text">
      <geaui-icon-v2
        class="geaui-hrt-icon"
        file="24px_office"
        [color]="'black'"
        [colorProp]="'stroke'"
      ></geaui-icon-v2>
      <span>Companies</span>
    </div>
    <mat-form-field appearance="standard" class="TableHeaderSearchBox">
      <mat-label>Search Companies</mat-label>
      <input
        type="text"
        matInput
        [(ngModel)]="table.View.Search"
        (ngModelChange)="table.SearchUpdated()"
      />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
  <table
    mat-table
    [dataSource]="table.Result.Data"
    matSort
    matSortDisableClear
    [matSortActive]="table.View.SortColumn"
    (matSortChange)="table.RefreshData($event)"
  >
    <ng-container matColumnDef="Name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let row">{{ row.Name }}</td>
    </ng-container>
    <ng-container matColumnDef="Actions">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="TableActionsColumn"
      >
        Actions
      </th>
      <td mat-cell *matCellDef="let row"></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    [length]="table.Result.Count"
    [pageSizeOptions]="table.View.PageSizeOptions"
    [pageSize]="table.View.PageSize"
    [pageIndex]="table.View.Page"
    (page)="table.RefreshData($event)"
  >
  </mat-paginator>
</div>
