<table>
    <tr>
        <td style="width: 1%;">
            <mat-icon matSuffix class="TableHeaderIcon">person</mat-icon>
        </td>
        <td>
            <span class="TableHeaderText">User Details</span>
            <mat-checkbox [(ngModel)]="user.IsTemplate">Is Template</mat-checkbox>
        </td>
    </tr>
</table>
<table class="MarginLeft">
    <tr>
        <td>
            <mat-form-field appearance="legacy" class="data-control-long">
                <mat-label>Name</mat-label>
                <input matInput [(ngModel)]="user.Name" />
            </mat-form-field>
        </td>
        <td *ngIf="!user.IsTemplate">
            Portal Registration: {{user.GeaIdRegistration}}
        </td>
    </tr>
    <tr>
        <td>
            <mat-form-field *ngIf="!user.IsTemplate" appearance="legacy" class="data-control-long">
                <mat-label>Company</mat-label>
                <input matInput [(ngModel)]="user.Company" />
            </mat-form-field>
        </td>
        <td *ngIf="!user.IsTemplate">
            Portal Memberships: {{user.GeaIdMemberships}}
        </td>
    </tr>
    <tr>
        <td>
            <mat-form-field *ngIf="!user.IsTemplate" appearance="legacy" class="data-control-long">
                <mat-label>Address</mat-label>
                <input matInput [(ngModel)]="user.Address" />
            </mat-form-field>
        </td>
        <td *ngIf="!user.IsTemplate">
            Portal Last Modified: {{user.GeaIdLastModified}}
        </td>
    </tr>
    <tr>
        <td>
            <mat-form-field *ngIf="!user.IsTemplate" appearance="legacy" class="data-control-short">
                <mat-label>City</mat-label>
                <input matInput [(ngModel)]="user.City" />
            </mat-form-field>
            <mat-form-field *ngIf="!user.IsTemplate" appearance="legacy" class="data-control-short">
                <mat-label>State</mat-label>
                <input matInput [(ngModel)]="user.State" />
            </mat-form-field>
            <mat-form-field *ngIf="!user.IsTemplate" appearance="legacy" class="data-control-short">
                <mat-label>Zip</mat-label>
                <input matInput [(ngModel)]="user.Zip" />
            </mat-form-field>
        </td>
        <td *ngIf="!user.IsTemplate">
            HRT Last Modified: {{user.LastModified}}
        </td>
    </tr>
    <tr>
        <td>
            <mat-form-field *ngIf="!user.IsTemplate" appearance="legacy" class="data-control-long">
                <mat-label>Country</mat-label>
                <input matInput [(ngModel)]="user.Country" />
            </mat-form-field>
        </td>
        <td *ngIf="!user.IsTemplate">
            HRT Modified By: {{user.LastModifiedByUserId}}
        </td>
    </tr>
    <tr>
        <td>
            <mat-form-field *ngIf="!user.IsTemplate" appearance="legacy" class="data-control-long">
                <mat-label>Email</mat-label>
                <input matInput [(ngModel)]="user.Email" />
            </mat-form-field>
        </td>
        <td *ngIf="!user.IsTemplate">
            HRT Last Login: {{user.LastLogin}}
        </td>
    </tr>
    <tr>
        <td>
            <mat-form-field *ngIf="!user.IsTemplate" appearance="legacy" class="data-control-long">
                <mat-label>Phone</mat-label>
                <input matInput [(ngModel)]="user.Phone" />
            </mat-form-field>
        </td>
        <td>
        </td>
    </tr>
      <tr>
        <td>
            <mat-form-field appearance="legacy" class="data-control-long">
              <mat-label>Order Status Filters</mat-label>
              <input matInput [(ngModel)]="user.OrderStatusFilters" />
            </mat-form-field>
        </td>
        <td>
            
        </td>
    </tr>
    <tr>
        <td>
            <mat-form-field appearance="legacy" class="data-control-long">
                <mat-label>Site Filter</mat-label>
                <input matInput [(ngModel)]="user.SiteFilter" />
            </mat-form-field>
        </td>
        <td>
            
        </td>
    </tr>
    <tr>
      <td>
        <mat-form-field appearance="legacy" class="data-control-long">
          <mat-label>Customer Account Numbers</mat-label>
          <input matInput [(ngModel)]="user.GeaIdCustomerNumbers" />
        </mat-form-field>
      </td>
      <td>
    
      </td>
    </tr>
</table>
<table>
    <tr>
        <td style="width: 1%;">
            <mat-icon matSuffix class="TableHeaderIcon">key</mat-icon>
        </td>
        <td>
            <span class="TableHeaderText">User Permissions</span>
        </td>
    </tr>
</table>
<div class="MarginAll">
    <mat-form-field class="data-control-long">
        <mat-label>Role</mat-label>
        <mat-select [(value)]="user.RoleId" (valueChange)="setDefaultPermissions()">
            <mat-option [value]="0">Guest</mat-option>
            <mat-option [value]="1000">Customer</mat-option>
            <mat-option [value]="2000">Prefered Customer</mat-option>
            <mat-option [value]="3000">External Sales</mat-option>
            <mat-option [value]="4000">Internal Sales</mat-option>
            <mat-option [value]="5000">Internal Support</mat-option>
            <mat-option [value]="6000">Internal Admin</mat-option>
            <mat-option [value]="7000">Dev Admin</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<table class="MarginLeft">
    <tr>
        <td class="label-column"><b>App Feature</b></td>
        <td class="checkbox-column"><b>View</b></td>
        <td class="checkbox-column"><b>Edit</b></td>
        <td></td>
    </tr>
    <tr *ngFor="let permission of permissions">
        <td class="label-column">{{permission.Name}}</td>
        <td class="checkbox-column"><mat-checkbox [(ngModel)]="permission.CanView" (ngModelChange)="viewChanged(permission)" [disabled]="permission.Name == 'Home'"></mat-checkbox></td>
        <td class="checkbox-column"><mat-checkbox *ngIf="FeatureHasEdit(permission.Id)" [(ngModel)]="permission.CanEdit" (ngModelChange)="editChanged(permission)"></mat-checkbox></td>
        <td></td>
    </tr>
</table>
<br>

<table>
  <tr>
    <td style="width: 1%;">
      <mat-icon matSuffix class="TableHeaderIcon">notifications</mat-icon>
    </td>
    <td>
      <span class="TableHeaderText">Notifications</span>
      <button (click)="addNotification()">Add</button>
    </td>
  </tr>
</table>
<table class="MarginLeft">
  <tr>
    <td class="checkbox-column"><b>Type</b></td>
    <td class="checkbox-column"><b>Resource</b></td>
    <td></td>
  </tr>
  <tr *ngFor="let notification of notifications">
    <td>
      <mat-form-field class="data-control-long">
        <mat-label>Role</mat-label>
        <mat-select [(value)]="notification.TypeId">
          <mat-optgroup>
            <mat-option *ngFor="let type of userNotificationTypes" [value]="type.Id">{{ type.Name }}</mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
    </td>
    <td>
      <mat-form-field class="data-control-long" *ngIf="notification.TypeId == 1">
        <mat-label>Company</mat-label>
        <mat-select [(value)]="notification.ForeignKey">
          <mat-optgroup>
            <mat-option *ngFor="let company of companies" [value]="company.Id">{{ company.Name }}</mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="data-control-long" *ngIf="notification.TypeId == 2">
        <mat-label>Product</mat-label>
        <mat-select [(value)]="notification.ForeignKey">
          <mat-optgroup>
            <mat-option *ngFor="let application of applications" [value]="application.Id">{{ application.Name }}</mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="notification.TypeId == 4">
        <mat-label>Site ID</mat-label>
        <input matInput [(ngModel)]="notification.ForeignKey" />
      </mat-form-field>
    </td>
    <td><button (click)="deleteNotification(notification)">Delete</button></td>
  </tr>
</table>

<br>
<div class="MarginLeft">
    <button
      mat-raised-button
      *ngIf="!isSaving"
      class="geaui-button-primary AddButton"
      (click)="save()"
    >
      Save
    </button>
    <button
        mat-raised-button
        *ngIf="!isSaving"
        (click)="cancel()"
        class="Button geaui-button-secondary"
        >
        Cancel
    </button>
    <div *ngIf="isSaving">
        Saving...
        <mat-spinner diameter="20" style="display: inline-block; vertical-align: center;"></mat-spinner>
    </div>
</div>