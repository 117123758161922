import { Component, OnInit } from '@angular/core';
import { Item } from 'src/app/shared/data/data.generated';
import { ApiService } from 'src/app/shared/services/api.service';
import { Table, TableView } from 'src/app/shared/services/api/table';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { BehaviorSubject, Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-parts-list',
  templateUrl: './parts-list.component.html',
  styleUrls: ['./parts-list.component.scss']
})
export class PartsListComponent implements OnInit {

  constructor(private readonly api: ApiService, private loader: LoaderService) { }

  public displayedColumns: string[] = ['Name', 'Number', 'Description', 'Category', 'Warehouse'];
  public table: Table<Item> = new Table<Item>(this.loader);

  ngOnInit(): void {
    this.table.GetApi = (listOptions: TableView) => this.api.Projects.GetItems(listOptions);
    this.table.RefreshData();
    this.table.inputSubject.pipe(debounceTime(500)).subscribe((value) => {
      this.table.SearchUpdated()
    });
  }

  searchChange(searchText: string) {
    if (searchText.trim() !== this.table.inputSubject.getValue()) {
      this.table.inputSubject.next(searchText);
    }
  }

}
