// Source:
// https://stackblitz.com/edit/overlay-progress-spinner?file=app%2Fprogress-spinner%2Fprogress-spinner.component.ts,app%2Fprogress-spinner%2Fprogress-spinner.component.html

import { Component, Input, OnInit, ViewChild, TemplateRef, ViewContainerRef, DoCheck } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';
import { AppOverlayConfig, OverlayService } from '../../services/overlay.service';
import { ThemePalette } from '@angular/material/core';
import { LegacyProgressSpinnerMode as ProgressSpinnerMode } from '@angular/material/legacy-progress-spinner';

@Component({
    selector: 'progress-spinner',
    templateUrl: './progress-spinner.component.html'
})
export class ProgressSpinnerComponent {
    @Input() color?: ThemePalette;
    @Input() diameter?: number = 100;
    @Input() mode: ProgressSpinnerMode = "indeterminate";
    @Input() strokeWidth?: number;
    @Input() value?: number;
    @Input() backdropEnabled = true;
    @Input() positionGloballyCenter = true;
    @Input() displayProgressSpinner: boolean = false;

    @ViewChild('progressSpinnerRef')
    private progressSpinnerRef: TemplateRef<any> | undefined = undefined;
    private progressSpinnerOverlayConfig: AppOverlayConfig | undefined = undefined;
    private overlayRef: OverlayRef | undefined = undefined;
    constructor(private vcRef: ViewContainerRef, private overlayService: OverlayService) { }
    ngOnInit() {
        // Config for Overlay Service
        this.progressSpinnerOverlayConfig = {
            hasBackdrop: this.backdropEnabled
        };
        if (this.positionGloballyCenter) {
            this.progressSpinnerOverlayConfig['positionStrategy'] = this.overlayService.positionGloballyCenter();
        }
        // Create Overlay for progress spinner
        this.overlayRef = this.overlayService.createOverlay(this.progressSpinnerOverlayConfig);
    }
    ngDoCheck() {
        // Based on status of displayProgressSpinner attach/detach overlay to progress spinner template
        if (this.overlayRef && this.progressSpinnerRef && this.displayProgressSpinner && !this?.overlayRef?.hasAttached()) {
            this.overlayService.attachTemplatePortal(this.overlayRef, this.progressSpinnerRef, this.vcRef);
        } else if (!this.displayProgressSpinner && this?.overlayRef?.hasAttached()) {
            this?.overlayRef?.detach();
        }
    }
}