import { Component, OnInit } from '@angular/core';
import { TabData } from 'src/app/shared/components/hrt-tab/hrt-tab.component';
import { SiteAllComeponent } from './site-all/site-all.component';
import { SiteIotConnectedComeponent } from './site-iot-connected/site-iot-connected.component';
import { Router } from '@angular/router';
import { Site } from 'src/app/shared/data/data.generated';
import { ApiService } from 'src/app/shared/services/api.service';
import { Table, TableView } from 'src/app/shared/services/api/table';
import { environment } from 'src/environments';
import { LoaderService } from 'src/app/shared/services/loader.service';

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss']
})
export class SitesComponent {

  siteTabs: TabData[] = [
    {
      tabName: 'All',
      tabComponent: SiteAllComeponent,
      isTabVisible : true,
    },
    {
      tabName: 'IoT Connected',
      tabComponent: SiteIotConnectedComeponent,
      isTabVisible : true,
    },
  ];

  public isEnvironment : string | null = environment.name;

  
  constructor(private readonly api: ApiService, private router: Router, private loader: LoaderService) { }

  public displayedColumns: string[] = ['Name', 'Address', 'City', 'State', 'Zip', 'AccountNumbers', 'ProjectNumbers'];
  public table: Table<Site> = new Table<Site>(this.loader);

  ngOnInit(): void {
    this.table.GetApi = (listOptions: TableView) => this.api.Sites.GetSites(listOptions);
    this.table.RefreshData();
  }

  openDetails(id: number) {
    this.router.navigate(['/site-details', { id: id }]);
  }

}