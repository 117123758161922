import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  // private hubConnection: signalR.HubConnection;
  // liveDeviceData = signal({});
  private _socketUrl: string;
  liveData: BehaviorSubject<any> = new BehaviorSubject({});
  chartData: BehaviorSubject<any> = new BehaviorSubject({});
  connectedAssets = ['7565', '7566', '7567', '7568', '7380', '7526', '6158', '6319', '6928', '11386'];
  connectedBuckets = ["23000052-Vezet", "2210004731-Erding", "2210004729-Stadtwerke Lemgo", "23003311-Tyson Dakota"];
  annunStatuses: any = {};
  private messageSubject = new Subject<string>();
  private messageObservable: Observable<any>;
  private socket: WebSocket;
  isSocketConnected: boolean = false;
  shutdownStatusMapping: any = {
    0: 'Normal',
    1: 'Warning',
    2: 'Shutdown',
  };
  annun_count: any = {
    Normal: 0,
    Warning: 0,
    Shutdown: 0,
  };

  constructor(private api: ApiService) {}

  closeConnection() {
    this.messageSubject.complete();
  }

  get socketUrl() {
    return this._socketUrl;
  }

  set socketUrl(url: string) {
    this._socketUrl = url;
  }

  getSocketUrl(hubName: string) {
    return this.api.IOT.GetFluxSocketURL(hubName);
  }

  connect(url: string): void {
    try {
      this.socket = new WebSocket(url);
    } catch (e: any) {
      // unable to establish connection with old url.
      this.getSocketUrl('FluxHub').subscribe((url) => {
        this.socketUrl = url;
        this.connect(url);
      });
    } finally {
      this.socket.onopen = (event) => {
        console.log('WebSocket is connected.', event);
        this.isSocketConnected = true;
      };

      this.socket.onmessage = (event) => {
        let chartData = false;
        // console.log('WebSocket message received:', event);
        // Code for 1-1 communication.
        const socketData = JSON.parse(event.data);
        if (!socketData) return;
        for (const key in socketData) {
          if ('datapoints' in socketData[key]) {
            chartData = true;
            break;
          }
        }
        if (chartData) {
          this.chartData.next(socketData);
        } else {
          const mergedData = {};
          Object.keys(socketData).forEach(bucketKey => {
            const bucket = socketData[bucketKey];
            Object.assign(mergedData, bucket)
          });
          this.extractAnnunStatuses(mergedData);
          this.liveData.next(mergedData);
        }
      };

      this.socket.onerror = (event) => {
        console.error('WebSocket error:', event);
      };

      this.socket.onclose = (event) => {
        console.log('WebSocket is closed now.', event);
        this.isSocketConnected = false;
        this.reconnect();
      };
    }
  }

  private reconnect() {
    if (!this.isSocketConnected) {
      console.log('Reconnecting Websocket...');
      setTimeout(() => {
        this.connect(this._socketUrl);
      }, 5000);
    }
  }

  public getMessages(): Observable<any> {
    return this.messageObservable;
  }

  public send(data: any): void {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      // console.log("Message sent:", data);
      // data is being sent as a string.
      this.socket.send(data);
    } else {
      console.error('Unable to send message, WebSocket is not open.');
    }
  }

  public disconnect(): void {
    if (this.socket) {
      this.socket.close();
    }
  }

  extractAnnunStatuses(deviceData: any) {
    // Reset all counts.
    Object.keys(this.annun_count).forEach((key) => {
      this.annun_count[key] = 0;
    });
    Object.keys(deviceData).forEach((key) => {
      const data = deviceData[key];
      const obj = {
        annun_status: data['compressor_Annunciation_Status'],
        motor_status: data['motor_Running'],
      };
      this.annunStatuses[key] = obj;
      // Get the count for each status.
      const annun_status_text =
        this.shutdownStatusMapping[data['compressor_Annunciation_Status']];
      this.annun_count[annun_status_text] =
        this.annun_count[annun_status_text] + 1;
    });
  }
}
