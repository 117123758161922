export class ProductSetup {

    public Title: string = "";
    public Icon: string = "";
    public Image: string = "";

    public CanCalculate: boolean = false;

    public Tabs: ProductTab[] = [];
}

export class ProductTab {
    public constructor(text: string, component: string) {
        this.Text = text;
        this.Component = component;
    }
    public Text: string = "";
    public Component: string = "";
}