<div class="document-table-container" *ngIf="items.length > 0; else noItems">
  <table mat-table [dataSource]="items" class="MarginTop">
    <ng-container matColumnDef="label">
      <td
        mat-cell
        *matCellDef="let element"
        class="TableLabelColumn documentation-col"
      >
        <img
          *ngIf="getFileType(element.FileName) == 'pdf'; else dwgFile"
          src="../assets/icons/file_pdf.svg"
          class="file-icon"
        />
        <ng-template #dwgFile>
          <!-- <i class="fa-regular fa-file file-type" ></i> -->
          <img src="../assets/icons/file_dwg.svg" class="file-icon" />
        </ng-template>
        <span>{{ element.Name }}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="download">
      <td mat-cell *matCellDef="let element">
        <a
          target="_blank"
          [matTooltip]="element.FileName"
          [matTooltipPosition]="'right'"
          style="cursor: pointer"
          (click)="downloadAssetDrawingsAndDoc(element)"
        >
          <mat-icon>download</mat-icon>
        </a>
      </td>
    </ng-container>
    <tr mat-row *matRowDef="let row; columns: labelValueColumns"></tr>
  </table>
</div>
<ng-template #noItems>
  <div style="margin: 20px">
    <p>None Available</p>
  </div>
</ng-template>
