import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductType } from 'src/app/shared/data/product-types';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
    selector: 'app-add-products',
    templateUrl: './add-products.component.html',
    styleUrls: ['./add-products.component.scss']
})
export class AddProductsComponent implements OnInit {

    constructor(private api: ApiService, private router: Router, private route: ActivatedRoute) { }

    public groups: any = undefined;
  
    ngOnInit(): void {
        this.route.params.subscribe((p: any) => {
            this.groups = p['isTools'] == "true" ? this.tools : this.productCompanies;
        });
    }

    public productCompanies = [
        {
            name: "GEA YORK",
            products: [
                {
                    title: 'Screw Package',
                    imageSrc: '../assets/product-icons/screwpackage_vibrantBlue.png'
                },
                {
                    title: 'Bare Screw (G-Series)',
                    imageSrc: '../assets/product-icons/screw_vibrantBlue.svg'
                },
                //{
                //    title: 'Bare Screw (Two-Stage)',
                //    imageSrc: '../assets/product-icons/screw_vibrantBlue.svg'
                //},
                {
                    title: 'Bare Screw (Non G-Series)',
                    imageSrc: '../assets/product-icons/screw_vibrantBlue.svg'
                },
                {
                    title: 'Piston Package',
                    imageSrc: '../assets/product-icons/pistonpackage_vibrantBlue.png'
                },

                {
                    title: 'Bare Piston',
                    imageSrc: '../assets/product-icons/piston_vibrantBlue.svg'
                },
                {
                    title: 'BluAstrum Chiller',
                    imageSrc: '../assets/product-icons/screwchiller_vibrantBlue.png'
                },
                //{
                //    title: 'BluAir Chiller',
                //    imageSrc: '../assets/product-icons/screwchiller_vibrantBlue.png'
                //},
                //{
                //    title: 'BluGenium',
                //    imageSrc: '../assets/product-icons/pistonchiller_vibrantBlue.png'
                //},
                //{
                //    title: 'FX Screw Chiller',
                //    imageSrc: '../assets/product-icons/screwchiller_vibrantBlue.png'
                //},
                //{
                //    title: 'FX Screw Duo Chiller',
                //    imageSrc: '../assets/product-icons/screwchiller_vibrantBlue.png'
                //},
                //{
                //    title: 'FX Piston Chiller',
                //    imageSrc: '../assets/product-icons/pistonchiller_vibrantBlue.png'
                //},
                //{
                //    title: 'FX Piston Duo Chiller',
                //    imageSrc: '../assets/product-icons/pistonchiller_vibrantBlue.png'
                //},
                {
                    title: 'Screw Heatpump RedAstrum',
                    imageSrc: '../assets/product-icons/screwheatpump_vibrantBlue.png'
                },
                {
                    title: 'Piston Heatpump RedGenium',
                    imageSrc: '../assets/product-icons/pistonheatpump_vibrantBlue.png'
                },
                {
                    title: 'Chiller Heatpump Blu-Red Fusion',
                    imageSrc: '../assets/product-icons/chiller_vibrantBlue.svg'
                },
                {
                    title: 'GEA Omni Control Panel',
                    imageSrc: '../assets/product-icons/Omni_vibrantBlue.svg'
                },
                {
                    title: 'GEA Omni System / Remote Panel',
                    imageSrc: '../assets/product-icons/Omni_vibrantBlue.svg'
                },
                {
                    title: 'Shell and Tube Heat Exchanger',
                    imageSrc: '../assets/product-icons/HX_vibrantBlue.svg'
                },
                {
                    title: 'Motor',
                    imageSrc: '../assets/product-icons/Motor_vibrantBlue.svg'
                },
                {
                    title: 'Starter',
                    imageSrc: '../assets/product-icons/starter_vibrantBlue.svg'
                },
                {
                    title: 'Miscellaneous',
                    imageSrc: '../assets/product-icons/dollar_vibrantBlue.svg'
                },
                {
                    title: 'Controls Miscellaneous',
                    imageSrc: '../assets/product-icons/dollar_vibrantBlue.svg'
                },
                {
                    title: 'Extended Warrenty',
                    imageSrc: '../assets/product-icons/dollar_vibrantBlue.svg'
                },
                {
                    title: 'Spare Parts',
                    imageSrc: '../assets/product-icons/dollar_vibrantBlue.svg'
                }
            ]
        },
        {
            name: "GEA YORK (SERVICE SALES)",
            products: [
                {
                    title: 'Bare Screw (G-Series)',
                    imageSrc: '../assets/product-icons/screw_vibrantBlue.svg'
                },
                //{
                //    title: 'Bare Screw (Two Stage)',
                //    imageSrc: '../assets/product-icons/screw_vibrantBlue.svg'
                //},
                {
                    title: 'Bare Screw (Non G-Series)',
                    imageSrc: '../assets/product-icons/screw_vibrantBlue.svg'
                },
                {
                    title: 'Bare Piston',
                    imageSrc: '../assets/product-icons/piston_vibrantBlue.svg'
                },
                //{
                //    title: 'Service Sales',
                //    imageSrc: '../assets/product-icons/FieldService_vibrantBlue.svg'
                //},
                {
                    title: 'Oil Reclamation Package',
                    imageSrc: '../assets/product-icons/oilReclaim_vibrantBlue.svg'
                },
                {
                    title: 'IntelliSOC Retrofit',
                    imageSrc: '../assets/product-icons/IntelliSOC_vibrantBlue.svg',
                    productType: ProductType.IntelliSocRetro
                },
                {
                    title: 'Maintenance Inspection',
                    imageSrc: '../assets/product-icons/MaintInspection_vibrantBlue.svg'
                },
                {
                    title: 'Predictive Labor Maintenance',
                    imageSrc: '../assets/product-icons/PredLabor_vibrantBlue.svg'
                },
                {
                    title: 'VTrac Retrofit',
                    imageSrc: '../assets/product-icons/VTrac_vibrantBlue.svg'
                },
                {
                    title: 'Field Service Proposal',
                    imageSrc: '../assets/product-icons/FieldService_vibrantBlue.svg'
                },
                {
                    title: 'Miscellaneous',
                    imageSrc: '../assets/product-icons/dollar_vibrantBlue.svg'
                },
                {
                    title: 'Controls Miscellaneous',
                    imageSrc: '../assets/product-icons/dollar_vibrantBlue.svg'
                },
                {
                    title: 'Shell and Tube Heat Exchanger',
                    imageSrc: '../assets/product-icons/HX_vibrantBlue.svg'
                },
                {
                    title: 'Spare Parts',
                    imageSrc: '../assets/product-icons/dollar_vibrantBlue.svg'
                },
                {
                    title: 'Motor',
                    imageSrc: '../assets/product-icons/Motor_vibrantBlue.svg'
                },
                {
                    title: 'Starter',
                    imageSrc: '../assets/product-icons/starter_vibrantBlue.svg'
                }

            ]
        }
    ];

    public tools = [
        {
            name: "Tools",
            products: [
                {
                    title: 'Oil Separator',
                    imageSrc: '../../../assets/icons/oilSeparatorAlt_vibrantBlue.svg',
                    productType: ProductType.OilSeparator
                },
                {
                    title: 'Heat Exchanger (Blu)',
                    imageSrc: '../assets/product-icons/chiller_vibrantBlue.svg',
                }
            ]
        }
    ]

    addProduct(product: any) {
        if (product.productType == ProductType.IntelliSocRetro ||
            product.productType == ProductType.OilSeparator) {
            this.router.navigate(['/product-editor', { productType: product.productType  }]);   
        }
        else {
            alert("TODO: Load " + product.title);    
        }
    }
}