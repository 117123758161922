import { Component, Input, OnInit } from '@angular/core';
import { ProductInfo } from 'src/app/shared/data/data.generated';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
    selector: 'product-info',
    templateUrl: './product-info.component.html',
    styleUrls: ['./product-info.component.scss']
})
export class ProductInfoComponent implements OnInit {

    @Input() public ProductId: number = -1;
    @Input() public CompanyId: number = 0;

    public mode: number = 0;

    public viewMode: number = 0;
    public editMode: number = 1;
    public historyMode: number = 2;
    public historyItemMode: number = 3;

    public infoHtml: string = "";
    public editInfoHtml: string = "";
    public historyInfoHtml: string = "";

    public selectedHistoryItem: any = undefined;
    public lastRevNumber: number = -1;
    public historyItems: ProductInfo[] = [];
    public columnsToDisplay: string[] = ['Id', 'ModifiedDate', 'UserName', 'Comments'];

    constructor(private readonly api: ApiService) { }

    ngOnInit(): void {
        this.loadInfo();
    }

    loadInfo() {
        this.api.Products.GetProductInfo(this.CompanyId, this.ProductId)
            .subscribe(x => {
                this.infoHtml = x.InfoHtml;
                this.mode = this.viewMode;
            });
    }

    showEdit() {
        this.editInfoHtml = this.infoHtml;
        this.mode = this.editMode;
    }

    showHistory() {
        this.mode = this.historyMode;
        this.api.Products.GetProductInfoHistory(this.CompanyId, this.ProductId)
            .subscribe(x => {
                this.historyItems = x;
                if (x.length > 0) this.lastRevNumber = x[0].Id
            });
    }

    historyItemClicked(historyItem: any) {
        this.api.Products.GetProductInfoHistoryHtml(historyItem.Id)
            .subscribe(x => {
                this.historyInfoHtml = x.InfoHtml;
                this.selectedHistoryItem = historyItem;
                this.mode = this.historyItemMode;
            });
    }

    back() {
        if (this.mode == this.historyItemMode){
            this.mode = this.historyMode;
        }
        else{
            this.mode = this.viewMode;
        }
    }

    update() {
        let comment = prompt("Enter comment for this new version:");
        if (comment){
            this.api.Products.UpdateProductInfo(this.ProductId, this.editInfoHtml, comment)
                .subscribe(x => {
                    this.loadInfo();
                });
        }
    }

    revert() {
        let comment = prompt("Enter comment for this version:");
        if (comment) {
            this.api.Products.UpdateProductInfo(this.ProductId, this.historyInfoHtml, comment)
                .subscribe(x => {
                    this.loadInfo();
                });
        }
    }

    formatDate(dateStr: string) {
        return new Date(dateStr).toLocaleDateString('en-US', { timeZone: 'UTC' });
    }

}
