<div class="mat-elevation-z8" style="margin: 20px;">
<table mat-table [dataSource]="table.Result.Data" matSort matSortDisableClear [matSortActive]="table.View.SortColumn" (matSortChange)="table.RefreshData($event)">
    <ng-container matColumnDef="Time">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
        <td mat-cell *matCellDef="let row" style="width: 150px">{{formatDate(row.Time)}}</td>
    </ng-container>
    <ng-container matColumnDef="Description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let row">{{row.Description}}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator [length]="table.Result.Count" [pageSizeOptions]="table.View.PageSizeOptions" [pageSize]="table.View.PageSize" [pageIndex]="table.View.Page" (page)="table.RefreshData($event)">
</mat-paginator>