import { ActivatedRouteSnapshot, CanActivate, CanActivateFn, RouterStateSnapshot, mapToCanActivate } from "@angular/router";
import { ApiService } from "./api.service";
import { inject } from "@angular/core";
import { ApplicationFeature } from "../data/application-feature";
import { sidebarNavigation } from "src/app/sidebar-navigation";

export const UserAppFeatureGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const api = inject(ApiService);

  api.LoadCachedFeatures();
  let path = "/" + route.pathFromRoot[1].url[0].path;
  if (!sidebarNavigation.find(x => x.route == path)) return true;
  let routeInfo = sidebarNavigation.filter(x => x.route == path)[0];
  api.LastFeatureGuard = routeInfo?.feature ?? ApplicationFeature.Home;
  return api.CanViewFeature(routeInfo?.feature);
};