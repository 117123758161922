import { Component, Inject, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'src/app/material.module';

@Component({
  selector: 'hrt-dialog-popup',
  templateUrl: './hrt-dialog.component.html',
  styleUrls: ['./hrt-dialog.component.scss']
})


export class HrtDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<HrtDialogComponent>, 
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
    }

    closePopup(){
    this.dialogRef.close(false)
    }
    
}

@NgModule({
    imports: [
      RouterModule,
      MaterialModule,
      FormsModule,
      BrowserModule,
    ],
    exports: [HrtDialogComponent],
    declarations: [HrtDialogComponent],
  })
  export class HrtDeletePopupModule {}